import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import styles from './EmptyTableRow.module.css'

const EmptyTableRow = ({ colSpan, message }) => {
    return (
        <Table.Row className={styles.row}>
            <Table.Cell colSpan={colSpan}>{message}</Table.Cell>
        </Table.Row>
    )
}

EmptyTableRow.propTypes = {
    colSpan: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
}

export default EmptyTableRow
