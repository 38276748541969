import Moment from 'moment'

export function enumToSelectList(enumObject) {
    return Object.keys(enumObject).map(enumKey => {
        const parsedKey = parseInt(enumKey)
        return { key: parsedKey, text: enumObject[enumKey], value: parsedKey }
    })
}

export function numberArrayToSelectList(numberArray, customNames) {
    if(!customNames) customNames = []
    return numberArray.map(number => {
        var name = customNames[number];
        return { key: number, text: name || number, value: number }
    })
}

export function frameToMoments(frame) {
    return [hoursAndMinutesToMonent(frame.startHour, frame.startMinute), hoursAndMinutesToMonent(frame.stopHour, frame.stopMinute)]
}

export function hoursAndMinutesToMonent(hours, minutes) {
    return Moment()
        .startOf('day')
        .add(hours, 'hours')
        .add(minutes, 'minutes')
}
