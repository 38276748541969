import React, { useState, useCallback, useContext } from 'react'
import Prolitec from '../Prolitec'
import { Form, Header } from 'semantic-ui-react'
import { Link } from '@reach/router'
import Button from '../../components/buttons/Button'
import { AylaContext } from '../../stores/aylaStore'
import styles from './ResetPasswordPage.module.css'
import { useForm } from '@radbse/hooks'
import { navigate } from '@reach/router'
import { useDocumentTitle } from '@radbse/hooks'

const ResetPasswordPage = () => {
    useDocumentTitle('ScenXus | FORGOT PASSWORD')
    const [sent, setSent] = useState(false)
    const [loading, setLoading] = useState(false)
    const { actions } = useContext(AylaContext)

    const fieldMappings = [{ name: 'email', label: 'Email', errorField: 'email' }]

    const [fields, form] = useForm({
        fields: fieldMappings,
        submit: async ({ email }) => {
            try {
                setLoading(true)
                await actions.sendPasswordReset(email)
                setLoading(false)
                setSent(true)
            } catch (error) {
                setLoading(false)
                setSent(true)
            }
        },
    })

    const handleSubmit = useCallback(form.submit, [fields.email])

    return (
        <Prolitec>
            {sent && (
                <Form error className={`dark`}>
                    <Header>Reset Password</Header>
                    <div>Password reset token sent to {fields.email.value}.</div>
                    <Form.Field>
                        <Button fluid primary onClick={() => navigate('/password/set')}>
                            Set Password
                        </Button>
                    </Form.Field>
                </Form>
            )}

            {!sent && (
                <React.Fragment>
                    <Form error onSubmit={handleSubmit} className={`dark`}>
                        <Header>Reset Password</Header>
                        <div>Enter the email associated with your account to receive a password reset token.</div>
                        <Form.Input {...fields.email} />
                        <Form.Field>
                            <Button fluid primary loading={loading}>
                                Send reset token
                            </Button>
                        </Form.Field>
                        <Form.Field>
                            <div>
                                or
                                <Link className={styles.link} to="/signin">
                                    Sign in
                                </Link>
                            </div>
                        </Form.Field>
                    </Form>
                </React.Fragment>
            )}
        </Prolitec>
    )
}

export default ResetPasswordPage
