import React from 'react'
import { Message } from 'semantic-ui-react'
import styles from './PasswordRules.module.scss'

const PasswordRules = () => {

  const list = [
    'be between 8 and 128 characters long',
    'have at least one number or special character',
    'have at least one uppercase letter',
    'have at least one lowercase letter',
  ]

  return (
    <Message header='Your password must...' list={list} className={styles['password-rules']} />
  )
}

export default PasswordRules
