import React, { useContext, useEffect, useCallback, useState, useMemo } from 'react'
import styles from './EditDeviceSchedulePage.module.scss'
import { Header, Table } from 'semantic-ui-react'
import { AylaContext } from '../../stores/aylaStore'
import LoadingDimmer from '../../components/LoadingDimmer'
import Schedule from '../../components/devices/Schedule'
import TableHeaderImageCell from '../../components/TableHeaderImageCell'
import Moment from 'moment'
import FrameModal from '../../components/devices/FrameModal'
import ButtonGroup from '../../components/buttons/ButtonGroup'
import { navigate } from '@reach/router'
import { frameToMoments } from '../../utilities/converters'
import { doFramesOverlap } from '../../utilities/timeHelpers'
import { useDocumentTitle, useAlert } from '@radbse/hooks'

const EditDeviceSchedulePage = ({ dsn }) => {
    useDocumentTitle('ScenXus | EDIT DEVICE SCHEDULE')
    const { actions, state } = useContext(AylaContext)
    const { ready } = state

    const [, setAlert] = useAlert()

    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [schedule, setSchedule] = useState([])
    const [frame, setFrame] = useState(null)

    const frameOpen = useMemo(() => frame != null, [frame])

    useEffect(() => {
        if (ready) {
            const load = async () => {
                setLoading(true)
                const schedule = (await actions.loadBaseDevice(dsn)).scenxus.schedule
                setSchedule(schedule || [])
                setLoading(false)
            }

            load()
        }
    }, [ready, dsn]) // eslint-disable-line react-hooks/exhaustive-deps

    const onFrameSave = useCallback(
        ({ active, intensity, startHour, startMinute, stopHour, stopMinute, dayOfTheWeek }) => {
            frame.dayOfTheWeek = dayOfTheWeek
            frame.active = active
            frame.intensity = intensity
            frame.startHour = startHour
            frame.startMinute = startMinute
            frame.stopHour = stopHour
            frame.stopMinute = stopMinute

            setSchedule([...schedule])
            setFrame(null)
        },
        [frame, schedule]
    )

    const onSave = useCallback(async () => {
        setSaving(true)
        await actions.updateDeviceSchedule(dsn, schedule)
        setSaving(false)
        setAlert({ content: `Successfully updated device schedule`, color: 'green' })
        navigate(`/devices/${dsn}`)
    }, [actions, dsn, schedule, setAlert])

    const onValidate = useCallback(
        currentFrame => {
            if (!currentFrame.active) return null

            const overlappingFrames = []
            const currentFrameTimes = [currentFrame.dayOfTheWeek, Moment(currentFrame.startTime, 'LT'), Moment(currentFrame.endTime, 'LT')]

            for (const checkFrame of schedule.filter(f => f.number !== frame.number && f.active)) {
                const checkFrameTimes = [checkFrame.dayOfTheWeek, ...frameToMoments(checkFrame)]

                if (doFramesOverlap(currentFrameTimes, checkFrameTimes)) {
                    overlappingFrames.push(checkFrame.number)
                }
            }

            return [...new Set(overlappingFrames)]
        },
        [frame, schedule]
    )

    const onDisableFrames = useCallback(
        framesToDisable => {
            for (const toDisable of framesToDisable) {
                schedule[toDisable - 1].active = false
            }

            setSchedule([...schedule])
        },
        [schedule]
    )

    return (
        <div className={styles.secondaryPageContainer}>
            <div className={styles.pageHeader}>
                <Header className={styles.pageHeaderTitle}>Edit Schedule</Header>
                <div className={styles.pageHeaderButtons}>
                    <ButtonGroup
                        buttons={[
                            { iconUrl: '/assets/ic-cancel.svg', onClick: () => navigate(`/devices/${dsn}`), title: 'Cancel' },
                            { iconUrl: '/assets/ic-checkmark.svg', onClick: onSave, title: 'Save Schedule', primary: true, loading: saving },
                        ]}
                        horizontal
                    />
                </div>
            </div>

            <LoadingDimmer ready={ready} loading={loading} loadingText={`Loading Device - ${dsn}`} />
            {frame && <FrameModal open={frameOpen} onClose={() => setFrame(null)} onSave={onFrameSave} frame={frame} onValidate={onValidate} onDisableFrames={onDisableFrames} />}
            {ready && schedule && (
                <div>
                    <Schedule title="Overview" schedule={schedule} />
                    <Table compact size="small" unstackable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell collapsing />
                                <TableHeaderImageCell img="/assets/ic-calendar.svg" title="Days" horizontal />
                                <TableHeaderImageCell img="/assets/ic-start-time.svg" title="Start Time" horizontal />
                                <TableHeaderImageCell img="/assets/ic-end-time.svg" title="End Time" horizontal />
                                <TableHeaderImageCell img="/assets/ic-intensity.svg" title="Intensity" horizontal />
                                <TableHeaderImageCell img="/assets/ic-edit-gray.svg" title="Edit" horizontal collapsing />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {schedule.map((frame, i) => {
                                const startTime = Moment()
                                    .startOf('day')
                                    .add(frame.startHour, 'hours')
                                    .add(frame.startMinute, 'minutes')

                                const endTime = Moment()
                                    .startOf('day')
                                    .add(frame.stopHour, 'hours')
                                    .add(frame.stopMinute, 'minutes')

                                let day = ''
                                switch (frame.dayOfTheWeek) {
                                    case 0:
                                        day = 'All Days'
                                        break
                                    case 1:
                                        day = 'Monday'
                                        break
                                    case 2:
                                        day = 'Tuesday'
                                        break
                                    case 3:
                                        day = 'Wednesday'
                                        break
                                    case 4:
                                        day = 'Thursday'
                                        break
                                    case 5:
                                        day = 'Friday'
                                        break
                                    case 6:
                                        day = 'Saturday'
                                        break
                                    case 7:
                                        day = 'Sunday'
                                        break
                                    case 8:
                                        day = 'Weekend Days'
                                        break
                                    case 9:
                                        day = 'Weekdays'
                                        break
                                    default:
                                        day = Moment()
                                            .day(frame.dayOfTheWeek)
                                            .format('dddd')
                                        break
                                }

                                return (
                                    <Table.Row className={frame.active ? styles.active : null} key={i}>
                                        <Table.Cell collapsing className={frame.active ? styles.activeNumberCell : styles.numberCell}>
                                            {i + 1}
                                        </Table.Cell>
                                        <Table.Cell>{day}</Table.Cell>
                                        <Table.Cell>{startTime.format('LT')}</Table.Cell>
                                        <Table.Cell>{endTime.format('LT')}</Table.Cell>
                                        <Table.Cell className={styles.row}>
                                            
                                                <div className={styles[`intensity-${Math.ceil(frame.intensity / 10)}-${frame.active ? 'on' : 'off'}`]} />
                                                <div>{frame.intensity}</div>
                                            
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            <div className={styles.editIcon} onClick={() => setFrame(frame)} />
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>
                    </Table>
                </div>
            )}
        </div>
    )
}

export default EditDeviceSchedulePage
