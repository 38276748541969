import React from 'react'
import styles from './Prolitec.module.scss'

const Prolitec = ({ children, description }) => {
    return <div className={styles.container}>
        <div className={styles.logo}></div>
        {description && <div className={styles.description}>{description}</div>}
        {children}
    </div>
}

export default Prolitec
