import React, { useContext } from 'react'
import { navigate } from '@reach/router'
import { Menu, Segment, Container, Button } from 'semantic-ui-react'
import { SessionContext } from '@radbse/auth-identity'
import RouterLinkImageItem from './RouterLinkImageItem'
import styles from './Navbar.module.scss'
import RouterLinkItem from './RouterLinkItem'

const Navbar = ({ children }) => {
    const session = useContext(SessionContext)
    return (
        <Segment
            inverted
            textAlign="center"
            style={{
                padding: 0,
                backgroundColor: '#363636',
            }}
            vertical
        >
            <Menu className={styles.topMenu} inverted pointing secondary>
                <RouterLinkItem position="right" className={styles.link} size="small" destination="/about">
                    {_ => {
                        return <div>About</div>
                    }}
                </RouterLinkItem>
                <RouterLinkItem className={styles.link} size="small" destination="/account">
                    {_ => {
                        return <div>Account</div>
                    }}
                </RouterLinkItem>
                <RouterLinkItem className={styles.link} size="small" destination="/help">
                    {_ => {
                        return <div>Help</div>
                    }}
                </RouterLinkItem>
                {session.authenticated && (
                    <RouterLinkItem className={styles.link} size="small" destination="/import" roles={['OEM::Admin']}>
                        {_ => {
                            return <div>Export / Import</div>
                        }}
                    </RouterLinkItem>
                )}
            </Menu>
            <Menu className={styles.menu} inverted pointing secondary size="large">
                <Container className={styles.container}>
                    <div className={styles.logo}></div>
                    {session.authenticated && (
                        <RouterLinkImageItem className={styles.item} destination="/devices" activeImg="/assets/ic-devices-on.svg" inactiveImg="/assets/ic-devices-off.svg">
                            Devices
                        </RouterLinkImageItem>
                    )}
                    {session.authenticated && (
                        <RouterLinkImageItem
                            className={styles.item}
                            destination="/users"
                            activeImg="/assets/ic-users-on.svg"
                            inactiveImg="/assets/ic-users-off.svg"
                            roles={['OEM::Admin', 'OEM::Observer', 'OEM::ProlitecCommercial::CspAdmin', 'OEM::ProlitecCommercial::CspObserver']}
                        >
                            Users
                        </RouterLinkImageItem>
                    )}
                    {session.authenticated && (
                        <RouterLinkImageItem className={styles.item} destination="/csps" activeImg="/assets/ic-csps-on.svg" inactiveImg="/assets/ic-csps-off.svg" roles={['OEM::Admin', 'OEM::Observer']}>
                            CSPs
                        </RouterLinkImageItem>
                    )}

                    {!session.authenticated && (
                        <Menu.Item className={styles.item} position="right">
                            <Button as="a" inverted onClick={() => navigate('/signin')}>
                                Sign in
                            </Button>
                            <Button as="a" inverted onClick={() => navigate('/signup')} style={{ marginLeft: '0.5em' }}>
                                Sign Up
                            </Button>
                        </Menu.Item>
                    )}
                </Container>
            </Menu>
            {children}
        </Segment>
    )
}

export default Navbar
