import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import styles from './TableHeaderImageCell.module.css'

const TableHeaderImageCell = ({ img, title, horizontal, collapsing, onClick, sorted }) => {
    const innerClass = `${styles.container} ${horizontal ? styles.horizontal : null} ${sorted ? styles[sorted] : null}`

    return (
        <Table.HeaderCell collapsing={collapsing} onClick={onClick}>
            <div className={innerClass}>
                <div>
                    <div className={styles.image} style={{ backgroundImage: `url(${img})` }}></div>
                    <div>{title}</div>
                </div>
            </div>
        </Table.HeaderCell>
    )
}

TableHeaderImageCell.propTypes = {
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default TableHeaderImageCell
