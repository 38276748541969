import React from 'react'
import PropTypes from 'prop-types'
import { List, Header, Divider } from 'semantic-ui-react'
import styles from './PropertyList.module.css'

const PropertyList = ({ title, subtitle, children }) => {
    return (
        <div>
            <Header as="h4" className={styles.title}>
                {title}
            </Header>
            {!subtitle && <Divider className={styles.divider} />}
            <div className={styles.subtitle}>{subtitle}</div>
            <List>{children}</List>
        </div>
    )
}

PropertyList.propTypes = {
    title: PropTypes.string.isRequired,
}

export default PropertyList
