import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react'
import { Header, Form } from 'semantic-ui-react'
import styles from './CopyDevicePage.module.scss'
import { AylaContext } from '../../stores/aylaStore'
import LoadingDimmer from '../../components/LoadingDimmer'
import PagedEntity from '../PagedEntity'
import SelectionDevices from '../../components/devices/SelectionDevices'
import PropertyListGroup from '../../components/devices/PropertyListGroup'
import PropertyList from '../../components/devices/PropertyList'
import { useForm, useAlert } from '@radbse/hooks'
import Button from '../../components/buttons/Button'
import DeviceDescription from '../../components/devices/DeviceDescription'
import { navigate } from '@reach/router'
import { useDocumentTitle } from '@radbse/hooks'
import { useScenXusApi } from '../../stores/scenxusStore'
import { useStateProvinceOptions, useCountryOptions } from '../../hooks/device'

const CopyDevicePage = ({ dsn, location }) => {
    useDocumentTitle('ScenXus | COPY DEVICE')
    const { actions, state } = useContext(AylaContext)
    const { ready, devices } = state
    const [device, setDevice] = useState(null)
    const [loading, setLoading] = useState(false)
    const [copyLoading, setCopyLoading] = useState(false)
    const [selectedDevices, setSelectedDevices] = useState([])
    const [selectedCountry, setSelectedCountry] = useState('')

    const [scenxusActions, useApi] = useScenXusApi()
    const [, updateDevice] = useApi(scenxusActions.updateDevice)
    const [notifications, setNotifications] = useState([])

    const [, setAlert] = useAlert()

    const stateProvinceOptions = useStateProvinceOptions(selectedCountry, true)
    const countryOptions = useCountryOptions(true)

    const [fields, form] = useForm({
        fields: [
            { name: 'copyName', label: (<label><b>Name</b> (Service Area, Customer Name, Customer ID, Store Number)</label>), type: 'boolean' },
            { name: 'copyId', label: (<label><b>ID</b> (Fragrance)</label>), type: 'boolean' },
            { name: 'copySchedules', label: (<label><b>Schedules</b></label>), type: 'boolean' },
            { name: 'copyNotifications', label: (<label><b>Notifications</b> (Method, Recipient, Which Events)</label>), type: 'boolean' },
            { name: 'copySettings', label: (<label><b>Settings</b> (Mode, Off-Cycle, Intensity, Fan Speed)</label>), type: 'boolean' },
            { name: 'copyLocation', label: (<label><b>Location Information</b> (Latitude, Longitude, Time Zone, City, State/Province, Country)</label>), type: 'boolean' },
        ],
        submit: fields => {},
    })

    const getNotifcations = useCallback(async (device) => {
        const properties = device.getProperties()
        const fragranceRemainingProperty = properties.find(p => p.name === 'fragrance_remaining')
        const allTriggerApps = []
        if (fragranceRemainingProperty) {
            const triggers = await fragranceRemainingProperty.fetchTriggers()
            triggers.forEach(trigger => {
                if (trigger.triggerApps) {
                    trigger.triggerApps.forEach(triggerApp => {
                        allTriggerApps.push(triggerApp)
                    })
                }
            })
        }

        return allTriggerApps.map(triggerApp => {
            return {
                id: triggerApp.key,
                type: 'lowProductLevel',
                method: triggerApp.name,
                recipient: triggerApp.param1 + (triggerApp.param2 ? ` ${triggerApp.param2}` : ''),
            }
        })
    }, [])

    useEffect(() => {
        if (ready) {
            async function load() {
                setLoading(true)
                const device = await actions.loadDevice(dsn)
                setNotifications(await getNotifcations(device))
                setDevice(device)
                setLoading(false)
            }

            load()
        }
    }, [ready, dsn, getNotifcations]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadDevices = useCallback(
        async (page, pageSize, _filters) => {
            let notDeviceFilters = { ..._filters, excludeDsn: dsn}
            actions.loadDevices(page, pageSize, null, null, notDeviceFilters)
        },
        [actions, dsn]
    )

    const onChange = useCallback(
        (field, value) => {
            form.setValue(field, value)
        },
        [form]
    )

    const onSelectionChange = useCallback(
        (device, selected) => {
            if (selected) {
                selectedDevices.push(device)
            } else {
                const unselectedDevice = selectedDevices.find(d => d.key === device.key)
                const indexOfDsn = selectedDevices.indexOf(unselectedDevice)
                selectedDevices.splice(indexOfDsn, 1)
            }

            setSelectedDevices([...selectedDevices])
        },
        [selectedDevices]
    )

    const onFilterChanged = useCallback(
        (field, oldValue, newValue) => {
            if (field === 'country') {
                setSelectedCountry(newValue)
            }
        },
        []
    )

    const _devices = useMemo(() => {
        console.log(devices)
        return (devices.items || []).map(device => {
            const selectedDevice = selectedDevices.find(d => d.key === device.key)
            device.selected = selectedDevices.includes(selectedDevice)
            return device
        })
    }, [devices, selectedDevices])

    const allowApplyDetails = useMemo(() => selectedDevices.length > 0 && (fields.copyName.value === true || fields.copyId.value === true || fields.copySchedules.value === true || fields.copyNotifications.value === true || fields.copySettings.value === true || fields.copyLocation.value === true), [
        fields.copyName.value,
        fields.copyId.value,
        fields.copyLocation.value,
        fields.copyNotifications.value,
        fields.copySchedules.value,
        fields.copySettings.value,
        selectedDevices.length,
    ])

    const onApplyDetails = useCallback(async () => {
        setCopyLoading(true)
        
        await actions.copyDetails(
            dsn,
            selectedDevices.map(sd => sd.dsn),
            fields.copyName.value,
            fields.copyId.value,
            fields.copySettings.value,
            fields.copySchedules.value,
            fields.copyNotifications.value,
            fields.copyLocation.value,
            notifications
        )

        for (const selectedDevice of selectedDevices) {
            await updateDevice(selectedDevice.dsn)
        }

        setCopyLoading(false)
        setAlert({ content: 'Successfully copied device', color: 'green' })
        navigate(`/devices/${dsn}`)
    }, [actions, dsn, selectedDevices, fields.copyName.value, fields.copyId.value, fields.copySettings.value, fields.copySchedules.value, fields.copyNotifications.value, fields.copyLocation.value, notifications, setAlert, updateDevice])

    const filters = useMemo(() => {
        return [
            { field: 'csp', name: 'CSP' },
            { field: 'dsn', name: 'DSN' },
            { field: 'network', name: 'Network' },
            { field: 'servicearea', name: 'Service Area' },
            { field: 'model', name: 'Model' },
            { field: 'serialnumber', name: 'Serial Number' },
            {
                field: 'connectionstatus',
                name: 'Connection Status',
                options: [
                    { key: 0, value: null, text: '' },
                    { key: 1, value: 'Offline', text: 'Offline' },
                    { key: 2, value: 'Online', text: 'Online' },
                ],
            },
            { field: 'customer', name: 'Customer' },
            { field: 'city', name: 'City' },
            { field: 'country', name: 'Country', options: countryOptions },
            { field: 'stateprovince', name: 'State/Province', options: stateProvinceOptions },
        ]
    }, [countryOptions, stateProvinceOptions])

    return (
        <div className={styles.secondaryPageContainer}>
            <Header>Copy Device Details</Header>
            <LoadingDimmer ready={ready} loading={loading} loadingText={`Loading Device - ${dsn}`} />

            {ready && device && device.scenxus && (
                <React.Fragment>
                    <div>
                        Copying Device Details from{' '}
                        <b>
                            <DeviceDescription device={device} />
                        </b>
                    </div>

                    <Form className={styles.details}>
                        <PropertyListGroup className={styles.choose}>
                            <PropertyList title="1. Copy" subtitle="Choose details to copy">
                                <Form.Checkbox {...fields.copyName} className="copyOption" checked={fields.copyName.value === true} onChange={(_, { checked }) => onChange('copyName', checked)} />
                                <Form.Checkbox {...fields.copyId} className="copyOption" checked={fields.copyId.value === true} onChange={(_, { checked }) => onChange('copyId', checked)} />
                                <Form.Checkbox {...fields.copySchedules} className="copyOption" checked={fields.copySchedules.value === true} onChange={(_, { checked }) => onChange('copySchedules', checked)} />
                                <Form.Checkbox {...fields.copyNotifications} className="copyOption" checked={fields.copyNotifications.value === true} onChange={(_, { checked }) => onChange('copyNotifications', checked)} />
                                <Form.Checkbox {...fields.copySettings} className="copyOption" checked={fields.copySettings.value === true} onChange={(_, { checked }) => onChange('copySettings', checked)} />
                                <Form.Checkbox {...fields.copyLocation} className="copyOption" checked={fields.copyLocation.value === true} onChange={(_, { checked }) => onChange('copyLocation', checked)} />
                            </PropertyList>
                        </PropertyListGroup>
                        <PropertyListGroup className={styles.list}>
                            <PropertyList title="2. Devices" subtitle="Select Devices from the table below to apply copied details to.">
                                <ol>
                                    {selectedDevices.map((device, i) => {
                                        return (
                                            <li key={i} className={styles.deviceDescription}>
                                                <DeviceDescription device={device} />
                                                <div className={styles.deleteIcon} onClick={() => onSelectionChange(device, false)}></div>
                                            </li>
                                        )
                                    })}
                                </ol>
                            </PropertyList>
                        </PropertyListGroup>
                        <PropertyListGroup className={styles.apply}>
                            <PropertyList title="3. Apply" subtitle="Save Device Details to selected Devices.">
                                <Button iconUrl={'/assets/ic-checkmark.svg'} primary title="Apply Details" className={styles.applyButton} disabled={!allowApplyDetails || copyLoading} onClick={onApplyDetails} loading={copyLoading} />
                            </PropertyList>
                        </PropertyListGroup>
                    </Form>
                </React.Fragment>
            )}
            {
                <PagedEntity
                    {...devices}
                    items={_devices}
                    listComponent={SelectionDevices}
                    ready={ready}
                    name="Devices"
                    onLoad={loadDevices}
                    inline
                    selection
                    onSelectionChange={onSelectionChange}
                    filters={filters}
                    actions={[]}
                    location={location}
                    onFilterChanged={onFilterChanged}
                />
            }
        </div>
    )
}

CopyDevicePage.propTypes = {}

export default CopyDevicePage
