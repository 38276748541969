import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import Csp from './Csp'
import SortableTable from '../SortableTable'

const Csps = ({ items, order, orderBy, onOrderChange }) => {
    return (
        <SortableTable order={order} orderBy={orderBy || 'name'} onOrderChange={onOrderChange}>
            {(sorted, onColumnClick) => (
                <React.Fragment>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={sorted('name')} onClick={onColumnClick('name')}>
                                Name
                            </Table.HeaderCell>
                            <Table.HeaderCell sorted={sorted('devicecount')} onClick={onColumnClick('devicecount')} textAlign="right">
                                Devices
                            </Table.HeaderCell>
                            <Table.HeaderCell sorted={sorted('usercount')} onClick={onColumnClick('usercount')} textAlign="right">
                                Users
                            </Table.HeaderCell>
                            <Table.HeaderCell sorted={sorted('isfree')} onClick={onColumnClick('isfree')} textAlign="right">
                                Edit
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {items.map((csp, i) => {
                            return <Csp key={i} csp={csp} />
                        })}
                    </Table.Body>
                </React.Fragment>
            )}
        </SortableTable>
    )
}

Csps.propTypes = {
    items: PropTypes.array.isRequired,
}

export default Csps
