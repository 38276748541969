import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { Pagination, Dropdown, Form } from 'semantic-ui-react'
import styles from './PagedEntity.module.scss'
import Button from '../components/buttons/Button'
import ButtonGroup from '../components/buttons/ButtonGroup'
import LoadingDimmer from '../components/LoadingDimmer'
import ScenXusModal from '../components/ScenXusModal'
import { navigate } from '@reach/router'
import { useForm, useAlert } from '@radbse/hooks'
import PapaParse from 'papaparse'
import FileSaver from 'file-saver'
import { renderToString } from 'react-dom/server'
import PrintableReport from '../components/PrintableReport'

const PagedEntity = ({ ready, items, total, loading, onLoad, name, listComponent: ListComponent, onItemClick, actions, filters, inline, onSelectionChange, location, generateReport, onFilterChanged }) => {
    const pageSizes = [5, 25, 50]
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(5)
    const [order, setOrder] = useState(null)
    const [orderBy, setOrderBy] = useState(null)
    const [filterValues, setFilterValues] = useState({})
    const [reportModalOpen, setReportModalOpen] = useState(false)
    const [reportLoading, setReportLoading] = useState(false)
    const [, setAlert] = useAlert()

    const start = useMemo(() => (page - 1) * pageSize, [page, pageSize])
    const end = useMemo(() => start + pageSize, [pageSize, start])
    const totalPages = useMemo(() => Math.ceil(total / pageSize), [pageSize, total])
    const needsPaging = useMemo(() => totalPages > 1, [totalPages])
    const [searchOpen, setSearchOpen] = useState(false)
    const needsPageDropdown = useMemo(() => total > pageSizes[0], [pageSizes, total])
    const reportable = useMemo(() => !!generateReport, [generateReport])

    const pageSizeOptions = useMemo(() => {
        return pageSizes.map(pgSz => {
            return {
                key: pgSz,
                text: `${pgSz} Per Page`,
                value: pgSz,
            }
        })
    }, [pageSizes])

    const setPersistedFilters = (storage, filters, page, pageSize, order, orderBy, pathname) => {
        const collection = { filters, page, pageSize, order, orderBy, }
        storage.setItem(`previousFilters${pathname}`, JSON.stringify(collection))
        return collection
    }

    const removePersistedFilters = (storage, pathname) => {
        storage.removeItem(`previousFilters${pathname}`)
    }

    const getPersistedFilters = (storage, pathname) => {
        return JSON.parse(storage.getItem(`previousFilters${pathname}`)) || {}
    }

    const executedFilters = useMemo(() => {
        const searchParams = new URLSearchParams(location.search)
        let hasSearchParams = false
        let _filters = {}

        for (const [ key, value, ] of searchParams.entries()) {
            hasSearchParams = true
            if ((filters || []).find(f => f.field === key)) {
                _filters[key] = searchParams.get(key)
            }

            if (key === 'page' && value) {
                _filters['page'] = value
            }

            if (key === 'page_size' && value) {
                _filters['page_size'] = value
            }

            if (key === 'order' && value) {
                _filters['order'] = value
            }

            if (key === 'order_by' && value) {
                _filters['order_by'] = value
            }
        }

        if (!hasSearchParams) {
            const { 
                filters: storedFilters, 
                page: storedPage, 
                pageSize: storedPageSize, 
                order: storedOrder, 
                orderBy: storedOrderBy, 
            } = getPersistedFilters(sessionStorage, location.pathname)

            if (storedFilters) {
                _filters = { ...storedFilters, }
            }

            if (storedPage) {
                _filters['page'] = storedPage
            }

            if (storedPageSize) {
                _filters['page_size'] = storedPageSize
            }

            if (storedOrder) {
                _filters['order'] = storedOrder
            }

            if (storedOrderBy) {
                _filters['order_by'] = storedOrderBy
            }
        }

        for (const [ key, value, ] of Object.entries(_filters)) {
            if (key === 'page' && value) {
                setPage(parseInt(value))
            }

            if (key === 'page_size' && value) {
                setPageSize(parseInt(value))
            }

            if (key === 'order' && value) {
                setOrder(value)
            }

            if (key === 'order_by' && value) {
                setOrderBy(value)
            }
        }

        return _filters
    }, [filters, location.search, location.pathname])

    useEffect(() => {
        const _page = parseInt(executedFilters['page'] || 1)
        const _pageSize = parseInt(executedFilters['page_size'] || 5)
        const _order = executedFilters['order']
        const _orderBy = executedFilters['order_by']

        if (ready && !searchOpen) {
            onLoad(_page, _pageSize, executedFilters, _order, _orderBy)
        }
    }, [ready, executedFilters]) // eslint-disable-line react-hooks/exhaustive-deps

    const onSearch = useCallback(() => {
        setFilterValues({ ...executedFilters })
        setSearchOpen(true)
    }, [executedFilters])

    const onSearchExecute = useCallback(
        (_page, _pageSize, _order, _orderBy) => {
            setPersistedFilters(sessionStorage, filterValues, _page, _pageSize, _order, _orderBy, location.pathname)

            const searchParams = new URLSearchParams('')
            Object.keys(filterValues).forEach(key => {
                searchParams.set(key, filterValues[key])
            })

            searchParams.set('page', _page)
            searchParams.set('page_size', _pageSize)
            if (_order) searchParams.set('order', _order)
            if (_orderBy) searchParams.set('order_by', _orderBy)

            navigate(`${location.origin}${location.pathname}?${searchParams.toString()}`)
            setSearchOpen(false)
        },
        [filterValues, location.origin, location.pathname]
    )

    const onFilterChange = useCallback(
        (field, value) => {
            setPage(1)
            const oldValue = filterValues[field]

            if (value === '') {
                delete filterValues[field]
                setFilterValues({ ...filterValues })
                setPersistedFilters(sessionStorage, filterValues, page, pageSize, order, orderBy, location.pathname)
                return
            }

            setFilterValues({ ...filterValues, [field]: value })
            setPersistedFilters(sessionStorage, filterValues, page, pageSize, order, orderBy, location.pathname)

            if (onFilterChanged) {
                onFilterChanged(field, oldValue, value)
            }
        },
        [filterValues, page, pageSize, order, orderBy, location.pathname, onFilterChanged]
    )

    const onFilterRemove = useCallback(
        field => {
            delete filterValues[field]
            setFilterValues({ ...filterValues })
            setPersistedFilters(sessionStorage, filterValues, 1, pageSize, order, orderBy, location.pathname)
            onSearchExecute(1, pageSize, order, orderBy)
        },
        [filterValues, onSearchExecute, order, orderBy, pageSize, location.pathname]
    )

    const onFilterRemoveAll = useCallback(
        () => {
            for (const key of Object.keys(filterValues)) {
                delete filterValues[key]
            }
            setFilterValues({})
            removePersistedFilters(sessionStorage, location.pathname)
            onSearchExecute(1, pageSize, order, orderBy)
        }, 
        [filterValues, onSearchExecute, order, orderBy, pageSize, location.pathname]
    )

    const onPageChange = useCallback(
        (_, { activePage }) => {
            onSearchExecute(activePage, pageSize, order, orderBy)
        },
        [onSearchExecute, order, orderBy, pageSize]
    )

    const onPageSizeChange = useCallback(
        (e, { value }) => {
            onSearchExecute(1, value, order, orderBy)
        },
        [onSearchExecute, order, orderBy]
    )

    const onOrderChange = useCallback(
        (field, direction) => {
            onSearchExecute(page, pageSize, direction, field)
        },
        [onSearchExecute, page, pageSize]
    )

    const onReportGenerate = useCallback(
        async (type, filters) => {
            const searchParams = new URLSearchParams()

            Object.keys(filters).forEach(filter => {
                searchParams.set(filter, filters[filter])
            })

            try {
                const result = await generateReport({ filters: searchParams.toString() })
                if (type === 'csv') {
                    var csv = PapaParse.unparse(result.response.items)
                    const blob = new Blob(["\uFEFF" + csv], { type: 'text/csv;charset=utf-8' })
                    FileSaver.saveAs(blob, 'report.csv')
                } else {
                    const html = renderToString(<PrintableReport report={result.response} />)
                    const blob = new Blob([html], { type: 'text/html' })
                    FileSaver.saveAs(blob, 'report.html')
                }
            } catch (error) {
                console.log(error)
                setAlert({ content: 'Failed to generate report', error: true, icon: 'exclamation circle' })
            }
        },
        [generateReport, setAlert]
    )

    const [reportFields, reportForm] = useForm({
        fields: [{ name: 'type', label: 'type', value: 'printable' }],
        submit: ({ type }) => {
            setReportLoading(true)
            onReportGenerate(type, executedFilters)
            setReportLoading(false)
            setReportModalOpen(false)
        },
    })

    const _actions = useMemo(() => {
        return reportable ? [...actions, { iconUrl: '/assets/ic-report.svg', title: 'Report', onClick: () => setReportModalOpen(true) }] : [...actions]
    }, [actions, reportable])

    const needsClearFilters = useMemo(() => {
        const nonFilterKeys = [ 'page', 'page_size', 'order', 'order_by', ]

        for (const [ key, value, ] of Object.entries(executedFilters || {})) {
            if (!nonFilterKeys.includes(key)) {
                return true
            }
        }
        
        return false
    }, [executedFilters])

    return (
        <div className={styles.container}>
            <ScenXusModal
                open={reportModalOpen}
                onClose={() => setReportModalOpen(false)}
                title="Generate Report"
                buttons={[
                    { iconUrl: '/assets/ic-cancel.svg', title: 'Cancel', onClick: () => setReportModalOpen(false) },
                    { iconUrl: '/assets/ic-checkmark.svg', onClick: reportForm.submit, title: 'Generate', primary: true, loading: reportLoading },
                ]}
            >
                <Form>
                    <Form.Radio label="Printable" onChange={() => reportForm.setValue('type', 'printable')} checked={reportFields.type.value === 'printable'} />
                    <Form.Radio label="CSV" onChange={() => reportForm.setValue('type', 'csv')} checked={reportFields.type.value === 'csv'} />
                </Form>
            </ScenXusModal>

            <LoadingDimmer ready={ready} loading={loading} loadingText={`Loading ${name}`} inline={inline} />

            {ready && !loading && (
                <div>
                    <div className={styles.pageHeader}>
                        <div className={styles.itemControls}>
                            {filters && (
                                <ScenXusModal
                                    open={searchOpen}
                                    size="tiny"
                                    trigger={
                                        <Button onClick={onSearch} iconUrl="/assets/ic-search.svg">
                                            Search
                                        </Button>
                                    }
                                    buttons={[
                                        { iconUrl: '/assets/ic-cancel.svg', onClick: () => setSearchOpen(false), title: 'Cancel' },
                                        { iconUrl: '/assets/ic-search-white.svg', onClick: () => onSearchExecute(1, pageSize), title: 'Search', primary: true },
                                    ]}
                                >
                                    <Form className={styles.filterForm}>
                                        {filters.map((filter, i) => {
                                            if (filter.options) {
                                                return (
                                                    <Form.Dropdown
                                                        key={i}
                                                        className={styles.filterFormField}
                                                        label={filter.name}
                                                        options={filter.options}
                                                        onChange={(_, { value }) => onFilterChange(filter.field, value)}
                                                        value={filterValues[filter.field] || ''}
                                                        scrolling
                                                        search
                                                    />
                                                )
                                            }

                                            return <Form.Input key={i} className={styles.filterFormField} label={filter.name} onChange={(_, { value }) => onFilterChange(filter.field, value)} value={filterValues[filter.field] || ''} />
                                        })}
                                    </Form>
                                </ScenXusModal>
                            )}
                            {needsClearFilters && <Button onClick={onFilterRemoveAll} iconUrl="/assets/ic-cancel.svg">Clear Filters</Button>}
                            {needsPageDropdown && <Dropdown options={pageSizeOptions} value={pageSize} onChange={onPageSizeChange} />}
                            {needsPaging && totalPages > 0 && <Pagination activePage={page} totalPages={totalPages} onPageChange={onPageChange} />}
                        </div>
                        {actions && (
                            <div className={styles.pageHeaderButtons}>
                                <ButtonGroup buttons={_actions} horizontal />
                            </div>
                        )}
                    </div>
                    <div className={styles.executedFilters}>
                        {Object.keys(executedFilters).map((executedFilter, i) => {
                            const filter = (filters || []).find(filter => filter.field === executedFilter)

                            if (!filter) return null

                            const { name } = filter

                            return (
                                <div key={i} className={styles.executedFilter}>
                                    <div className={styles.close} onClick={() => onFilterRemove(executedFilter)} />
                                    <div>{name}</div>
                                    <div className={styles.executedFilterValue}>{executedFilters[executedFilter]}</div>
                                </div>
                            )
                        })}
                    </div>
                    <div>
                        <ListComponent items={items} onClick={onItemClick} onSelectionChange={onSelectionChange} order={order} orderBy={orderBy} onOrderChange={onOrderChange} />
                    </div>
                    {total > 0 && (
                        <div className={styles.page}>
                            {name} {start + 1} - {Math.min(end, total)} of {total}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default PagedEntity
