import React, { useMemo, useContext, useEffect, useCallback, useState } from 'react'
import ButtonGroup from '../buttons/ButtonGroup'
import { Form } from 'semantic-ui-react'
import { SessionContext } from '@radbse/auth-identity'
import styles from './UserForm.module.scss'
import { useForm } from '@radbse/hooks'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import { useStateProvinceOptions, useCountryOptions } from '../../hooks/device'
import PasswordRules from './PasswordRules'

const UserForm = ({ edit, onSubmit, onLoad, submitButton, csps, saving, disabled }) => {
    const [selectedRole, setSelectedRole] = useState(null)
    const [selectedCountry, setSelectedCountry] = useState('')
    const session = useContext(SessionContext)
    const isAdmin = useMemo(() => session.user.role === 'OEM::Admin', [session.user.role])
    const isCSPAdmin = useMemo(() => session.user.role.includes('CspAdmin'), [session.user.role])
    const isCSPSelected = useMemo(() => selectedRole !== null && selectedRole !== 'OEM::Admin' && selectedRole !== 'OEM::Observer', [selectedRole])

    const cspOptions = useMemo(
        () =>
            csps.map(csp => {
                return { key: csp.name, value: csp.name, text: csp.name }
            }),
        [csps]
    )

    const roleOptions = useMemo(() => {
        const roles = []
        if (isAdmin) {
            roles.push({ key: 'OEM::Admin', value: 'OEM::Admin', text: 'OEM Admin' })
            roles.push({ key: 'OEM::Observer', value: 'OEM::Observer', text: 'OEM Observer' })
        }

        if (isCSPAdmin || isAdmin) {
            roles.push({ key: 'OEM::ProlitecCommercial::CspAdmin', value: 'OEM::ProlitecCommercial::CspAdmin', text: 'CSP Admin' })
            roles.push({ key: 'OEM::ProlitecCommercial::CspObserver', value: 'OEM::ProlitecCommercial::CspObserver', text: 'CSP Observer' })
        }
        roles.push({ key: 'OEM::ProlitecCommercial::CspTech', value: 'OEM::ProlitecCommercial::CspTech', text: 'CSP Technician' })
        return roles
    }, [isAdmin, isCSPAdmin])

    const stateProvinceOptions = useStateProvinceOptions(selectedCountry, true)
    const countryOptions = useCountryOptions(false)

    const _fields = useMemo(() => {
        const allFields = [
            { name: 'email', label: 'Email', errorField: 'email' },
            { name: 'firstname', label: 'First Name' },
            { name: 'lastname', label: 'Last Name' },
            { name: 'company', label: 'Company', optional: true },
            { name: 'businessRole', label: 'Business Role', optional: true },
            { name: 'phone', label: 'Phone Number', optional: true },
            { name: 'city', label: 'City' },
            { name: 'country', label: 'Country', type: 'select', options: countryOptions },
            { name: 'state', label: 'State/Province', type: 'select', optional: true },
        ]

        if (!edit) {
            allFields.push({ name: 'password', label: 'Password', errorField: 'password' })
            allFields.push({ name: 'confirmPassword', label: 'Confirm Password' })
        }

        if (isAdmin) {
            allFields.push({ name: 'csp', label: 'CSP Name', type: 'select', optional: !isCSPSelected })
        }

        if (isAdmin || isCSPAdmin) {
            allFields.push({ name: 'role', label: 'Role', type: 'select' })
        }

        return allFields
    }, [edit, isAdmin, isCSPAdmin, isCSPSelected, countryOptions])

    const [fields, form] = useForm({
        fields: _fields,
        validate: ({ password, confirmPassword }) => {
            if (password !== confirmPassword) {
                return {
                    confirmPassword: 'Does not match',
                }
            }
        },
        submit: fields => {
            if (onSubmit) onSubmit(fields)
        },
    })

    useEffect(() => {
        setSelectedRole(fields.role?.value)
    }, [fields.role])

    useEffect(() => {
        if (onLoad) {
            onLoad(user => {
                form.setValues(user)
                setSelectedCountry(user.country ?? '')
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onRoleChange = useCallback(
        (_, { value }) => {
            form.setValue('role', value)

            if (value === 'OEM::Admin' || value === 'OEM::Observer') {
                form.setValue('csp', '')
            }
        },
        [form]
    )

    return (
        <Form disabled={disabled}>
            <div className={styles.row}>
                <Form.Group>
                    <Form.Input {...fields.email} width={4} required disabled={edit || disabled} />

                    {(isAdmin || isCSPAdmin) && <Form.Dropdown {...fields.role} width={4} options={roleOptions} onChange={onRoleChange} required disabled={disabled} scrolling search />}
                    {isAdmin && <Form.Dropdown {...fields.csp} width={4} options={cspOptions} onChange={(_, { value }) => form.setValue('csp', value)} required={isCSPSelected} disabled={!isCSPSelected || disabled} scrolling search />}
                </Form.Group>
            </div>
            <div className={styles.row}>
                <Form.Group>
                    <Form.Input {...fields.firstname} width={4} required disabled={disabled} />
                    <Form.Input {...fields.lastname} width={4} required disabled={disabled} />
                </Form.Group>
                <Form.Group>
                    <Form.Input {...fields.company} width={4} disabled={disabled} />
                    <Form.Input {...fields.businessRole} width={4} disabled={disabled} />
                    <Form.Input {...fields.phone} width={4} disabled={disabled} />
                </Form.Group>
                <Form.Group>
                    <Form.Input {...fields.city} width={4} required disabled={disabled} />
                    <Form.Dropdown {...fields.state} width={4} disabled={disabled} onChange={(_, { value }) => form.setValue('state', value)} scrolling search options={stateProvinceOptions} />
                    <Form.Dropdown {...fields.country} width={4} required disabled={disabled} onChange={(_, { value }) => {
                        form.setValue('country', value)
                        setSelectedCountry(value)
                    }} scrolling search />
                </Form.Group>
            </div>
            {!edit && !disabled && (
                <div className={styles.row}>
                    <Form.Group>
                        <Form.Input {...fields.password} width={4} required type="password" />
                        <Form.Input {...fields.confirmPassword} width={4} required type="password" />
                    </Form.Group>
                    <Form.Group>
                        <PasswordRules />
                    </Form.Group>
                </div>
            )}
            <div className={styles.buttonRow}>
                <ButtonGroup
                    horizontal
                    buttons={[
                        { iconUrl: '/assets/ic-cancel.svg', title: 'Cancel', onClick: () => navigate('/users'), disabled: saving },
                        { ...submitButton, primary: true, onClick: form.submit, loading: saving, disabled: disabled },
                    ]}
                />
            </div>
        </Form>
    )
}

UserForm.propTypes = {
    csps: PropTypes.array.isRequired,
}

export default UserForm
