import React from 'react'
import PropTypes from 'prop-types'
import Day from './Day'
import styles from './Calendar.module.css'
import Times from './Times'
import { useWindowSize } from '../../../hooks/window'

const Calendar = ({ week }) => {
    useWindowSize()
    return (
        <div className={styles.container}>
            {week.map((day, i) => (
                <Day key={i} {...day} />
            ))}

            <Times />
        </div>
    )
}

Calendar.propTypes = {
    week: PropTypes.array.isRequired
}

export default Calendar
