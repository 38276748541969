import AylaNetworks from 'js-aylasdk'

export async function fetchScenxusUserData(user) {
    const adminRole = user.roles.find(r => r.name === 'OEM::Admin' || r.name === 'OEM::Observer')

    if (adminRole) {
        user.role = adminRole.name
        user.roleName = adminRole.name.replace('::', ' ')
    } else if (user.roleTags.length > 0) {
        user.roleTags.sort((a, b) => {
            var textA = a.key.toUpperCase()
            var textB = b.key.toUpperCase()
            return textA < textB ? -1 : textA > textB ? 1 : 0
        })

        const topRole = user.roleTags[0]
        user.role = topRole.role
        user.roleName = topRole.role
            .replace('OEM::', '')
            .replace('ProlitecCommercial::', '')
            .replace('Csp', 'CSP ')
            .replace('CSP', 'CSP ')
            .replace('Tech', 'Technician')
        user.csp = topRole.value
    }

    return user
}

export async function updateUserRole(user, newRole, newCsp) {
    if (user.roleTags.length > 1 || newRole === "OEM::Admin" || newRole === "OEM::Observer") {
        console.log('cleaning up old roles')
        user.roleTags
            .filter(rt => rt.role !== user.role)
            .forEach(async rt => {
                await AylaNetworks.shared().roleManager.revokeRole(user.email, rt.role)
            })
    }

    if (user.role !== newRole) {
        if (user.role) {
            await AylaNetworks.shared().roleManager.revokeRole(user.email, user.role)
        }

        await AylaNetworks.shared().roleManager.assignRole(user.email, newRole)

        if (newCsp) {
            await AylaNetworks.shared().roleManager.assignLabel(user.email, { roleName: newRole, description: 'ScenXus Role', label: { key: 'partner_id', value: newCsp } })
        }
    } else {
        if (newCsp) {
            await AylaNetworks.shared().roleManager.assignLabel(user.email, { roleName: user.role, description: 'ScenXus Role', label: { key: 'partner_id', value: newCsp } })
        }
    }
}

export const getUserComposite = (company, businessRole) => {
    const composite = {
        company: company || '',
        businessRole: businessRole || '',
    }

    return JSON.stringify(composite)
}
