import React, { useContext, useEffect, useMemo, useReducer, useCallback } from 'react'
import { SessionContext, useAuthApi } from '@radbse/auth-identity'
import AylaNetworks, { AylaSystemSettings, TokenAuthProvider, ServiceLocation } from 'js-aylasdk'
import { fetchScenxusData, updateDeviceMetaData, updateDeviceSchedule, updateDeviceSettings, updateDeviceNotification, updateDeviceLocationMetaData, updateDeviceCsp, addMetaData } from '../utilities/deviceHelpers'

import { fetchScenxusUserData, getUserComposite, updateUserRole } from '../utilities/userHelpers'
import moment from 'moment'
import { navigate } from '@reach/router'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

export const reducer = (state, action) => {
    switch (action.type) {
        case 'READY':
            console.log('ready')
            return { ...state, ready: true }
        case 'INIT':
            return { ...state, init: true }
        case 'DEVICES__LOADING':
            return { ...state, devices: { loading: true, items: [], error: null } }
        case 'DEVICES__LOADED':
            return { ...state, devices: { loading: false, total: action.response.total, page: action.response.currentPageNumber, pageSize: action.pageSize, items: action.response.devices } }
        case 'DEVICES__ERROR':
            return { ...state, devices: { loading: false, items: [], error: action.error } }
        case 'USERS__LOADING':
            return { ...state, users: { loading: true, items: [] } }
        case 'USERS__LOADED':
            return { ...state, users: { loading: false, total: action.response.total, page: action.response.currentPageNumber, pageSize: action.pageSize, items: action.response.users } }
        case 'USERS__ERROR':
            return { ...state, devices: { loading: false, items: [], error: action.error } }
        default:
            return state
    }
}

export const initialState = {
    ready: false,
    init: false,
    devices: {
        loading: false,
        total: 0,
        page: 1,
        pageSize: 5,
        items: [],
        error: null,
    },
    users: {
        loading: false,
        total: 0,
        page: 1,
        pageSize: 25,
        items: [],
        error: null,
    },
}

export const AylaContext = React.createContext(initialState)

export const AylaProvider = ({ children }) => {
    const session = useContext(SessionContext)
    const [authActions, useApi] = useAuthApi()

    const [, update] = useApi(authActions.api.updateJwt)
    const [state, dispatch] = useReducer(reducer, initialState)

    const updateToken = useCallback(
        async authorization => {
            if (session.token_expires < moment()) {
                console.log('Auth token expired, updating...')
                try {
                    await update(authorization)
                } catch {
                    navigate('/signout')
                }
            }

            const auth = window.localStorage.getItem('auth')
            const token = JSON.parse(auth).token
            return { _jwt: token }
        },
        [session, update]
    )

    const sessionManagerListener = useMemo(() => {
        return {
            sessionClosed: error => {
                console.log(error)
            },
            authorizationRefreshed: authorization => {
                console.log('updating jwt')
                console.log('new access token: ' + authorization.accessToken)

                update(authorization).catch(() => navigate('/signout'))
            },
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update])

    const deviceManagerListener = useMemo(() => {
        return {
            deviceManagerInitComplete: deviceFailures => {
                console.log('device manager init complete')
            },
            deviceManagerInitFailure: (error, failureState) => {
                console.log('device manager init failure')
            },
            deviceListChanged: change => {
                console.log('device manager list changed')
            },
            deviceManagerError: error => {
                console.log('device manager error')
            },
            deviceManagerStateChanged: (oldState, newState) => {
                console.log(`device manager state changed: ${oldState} -> ${newState} `)
            },
        }
    }, [])

    useEffect(() => {
        console.log('initializing')
        const aylaSettings = new AylaSystemSettings('--app_id--', '--app_secret--')
        aylaSettings.serviceLocation = ServiceLocation.Local
        aylaSettings.autoFetchDevices = false
        AylaNetworks.initialize(aylaSettings)
        dispatch({ type: 'INIT' })
        console.log('finished initialization')
    }, [])

    useEffect(() => {
        if (session.authenticated) {
            console.log('signing in')
            console.log('access token: ' + session.user.access_token)

            AylaNetworks.shared()
                .loginManager.signIn(new TokenAuthProvider(session.user.access_token, session.user.refresh_token, session.user.expires_in, session.user.role))
                .then(() => {
                    AylaNetworks.shared().sessionManager.additionalHeaders = updateToken
                    AylaNetworks.shared().sessionManager.clearListeners()
                    AylaNetworks.shared().sessionManager.addListener(sessionManagerListener)

                    AylaNetworks.shared().sessionManager.deviceManager.clearListeners()
                    AylaNetworks.shared().sessionManager.deviceManager.addListener(deviceManagerListener)
                    dispatch({ type: 'READY' })
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateToken, sessionManagerListener])

    function addPadding(val) {
        return `${val}`.padStart(2, '0')
    }

    const actions = useMemo(() => {
        return {
            loadBaseDevice: async dsn => {
                console.log(`fetching device: ${dsn}`)
                const device = await AylaNetworks.shared().adminService.fetchDevice(dsn)
                await fetchScenxusData(device, false, false)
                console.log(device)
                return device
            },
            loadDevice: async dsn => {
                console.log(`fetching device: ${dsn}`)

                const device = await AylaNetworks.shared().adminService.fetchDevice(dsn)
                await fetchScenxusData(device, true, true)
                console.log(device)
                return device
            },
            loadDevices: async (page, pageSize, order, orderBy, filters) => {
                filters = { ...filters } || {}
                filters.registered = true
                try {
                    dispatch({ type: 'DEVICES__LOADING' })
                    let response = { devices: [] }
                    console.log(`fetching oem devices: ${page} - ${pageSize}`)

                    if (order === 'ascending') order = 'asc'
                    if (order === 'descending') order = 'desc'

                    response = await AylaNetworks.shared().adminService.fetchDevices(page, pageSize, order, orderBy, filters)

                    for (const device of response.devices) {
                        console.log(device)
                        await fetchScenxusData(device, true)
                        await device.fetchNotifications()
                    }

                    dispatch({ type: 'DEVICES__LOADED', response, pageSize })
                } catch (error) {
                    console.log(error)
                    dispatch({ type: 'DEVICES__ERROR', error })
                }
            },
            loadUsers: async (isOEMAdmin, page, pageSize, filters, order, orderBy) => {
                try {
                    console.log(`fetching users: ${page} - ${pageSize}`)
                    dispatch({ type: 'USERS__LOADING' })

                    if (order === 'ascending') order = 'asc'
                    if (order === 'descending') order = 'desc'

                    const response = await AylaNetworks.shared().adminService.fetchUsers('--oem_id--', !isOEMAdmin, page, pageSize, order, orderBy, filters)

                    console.log(response)

                    for (const user of response.users) {
                        await fetchScenxusUserData(user)
                        console.log(user)
                    }

                    dispatch({ type: 'USERS__LOADED', response, pageSize })
                } catch (error) {
                    console.log(error)
                    dispatch({ type: 'USERS_ERROR', error })
                }
            },
            loadUser: async id => {
                const user = await AylaNetworks.shared().adminService.fetchUser(id)
                console.log(user)
                await fetchScenxusUserData(user)
                return user
            },
            sendPasswordReset: email => {
                return AylaNetworks.shared().loginManager.sendPasswordReset(email, 'test_reset')
            },
            resetPassword: (resetPasswordToken, password, passwordConfirmation) => {
                return AylaNetworks.shared().loginManager.resetPassword(resetPasswordToken, password, passwordConfirmation)
            },
            addDevice: async (dsn, csp, serialNumber, cartridge, fragrance, customer, timeZone, serviceArea, longitude, latitude, city, state, country, customerId, storeNumber) => {
                const device = await AylaNetworks.shared().adminService.fetchDevice(dsn)
                await fetchScenxusData(device, true)
                await updateDeviceCsp(device, csp)
                await updateDeviceMetaData(device, serialNumber, cartridge, fragrance, customer, timeZone, serviceArea, latitude, longitude, city, state, country, customerId, storeNumber)
                await device.updateTimeZone(timeZone)
            },
            updateDevice: async (dsn, serialNumber, cartridge, fragrance, customer, timeZone, serviceArea, longitude, latitude, city, state, country, csp, customerId, storeNumber) => {
                const device = await AylaNetworks.shared().adminService.fetchDevice(dsn)
                await fetchScenxusData(device, true)

                if (csp && csp !== device.scenxus.csp) {
                    await updateDeviceCsp(device, csp)
                }

                if (timeZone && timeZone !== device.scenxus.timeZone) {
                    await device.updateTimeZone(timeZone)
                }

                await updateDeviceMetaData(device, serialNumber, cartridge, fragrance, customer, timeZone, serviceArea, latitude, longitude, city, state, country, customerId, storeNumber)
            },
            updateDeviceCsp: async (dsn, csp) => {
                const device = await AylaNetworks.shared().adminService.fetchDevice(dsn)
                await fetchScenxusData(device, true)
                await updateDeviceCsp(device, csp)
            },
            updateDeviceSettings: async (dsn, mode, cycle, intensity, fan) => {
                const device = await AylaNetworks.shared().adminService.fetchDevice(dsn)
                await updateDeviceSettings(device, mode, cycle, intensity, fan)
            },
            updateDeviceSchedule: async (dsn, schedule) => {
                const device = await AylaNetworks.shared().adminService.fetchDevice(dsn)
                let scheduleBlobString = schedule.map(
                    frame =>
                        `30,${addPadding(frame.stopMinute)},${addPadding(frame.stopHour)},${addPadding(frame.intensity)},${addPadding(frame.startMinute)},${addPadding(frame.startHour)},${addPadding(frame.dayOfTheWeek)},${
                            frame.active ? '01' : '00'
                        }`
                )
                scheduleBlobString = scheduleBlobString.join(',')
                await updateDeviceSchedule(device, scheduleBlobString)
            },
            removeDeviceNotification: async (dsn, notification) => {
                await actions.updateDeviceNotification(dsn, notification.method, notification.recipient, false, false)
            },
            updateDeviceNotification: async (dsn, type, recipient, connected, disconnected) => {
                const device = await AylaNetworks.shared().adminService.fetchDevice(dsn)
                await device.fetchNotifications()
                await updateDeviceNotification(device, type, recipient, connected, disconnected)
            },
            copyDetails: async (sourceDsn, destinationDsns, copyName, copyId, copySettings, copySchedules, copyNotifications, copyLocation, notifications) => {
                let sourceDevice = null
                try {
                    sourceDevice = await AylaNetworks.shared().adminService.fetchDevice(sourceDsn)
                    await fetchScenxusData(sourceDevice, true, true)
                    console.log('source device', sourceDevice)
                } catch (error) {
                    console.log('failed to pull source data')
                    throw error
                }

                if (!sourceDevice) return

                for (const destinationDsn of destinationDsns) {
                    try {
                        const destinationDevice = await AylaNetworks.shared().adminService.fetchDevice(destinationDsn)
                        await fetchScenxusData(destinationDevice, true, true)
                        console.log('destination device', destinationDevice)

                        if (copyName) {
                            console.log('copying name', sourceDevice.scenxus)
                            await destinationDevice.updateProductName(sourceDevice.scenxus.serviceArea)
                            await addMetaData(destinationDevice, 'Customer', sourceDevice.scenxus.customer)
                            await addMetaData(destinationDevice, 'CustomerId', sourceDevice.scenxus.customerId)
                            await addMetaData(destinationDevice, 'StoreNumber', sourceDevice.scenxus.storeNumber)
                        }

                        if (copyId) {
                            console.log('copying id', sourceDevice.scenxus)
                            await addMetaData(destinationDevice, 'Fragrance', sourceDevice.scenxus.fragrance)
                        }

                        if (copySchedules) {
                            console.log('copying scehdule', sourceDevice.scenxus.scheduleBlob)
                            await destinationDevice.updateProperty('schedule_blob_strin', sourceDevice.scenxus.scheduleBlob)
                        }

                        if (copySettings) {
                            console.log('copying settings', sourceDevice.scenxus)
                            await updateDeviceSettings(destinationDevice, sourceDevice.scenxus.mode, sourceDevice.scenxus.cycle, sourceDevice.scenxus.intensity, sourceDevice.scenxus.fan)
                        }

                        if (copyNotifications) {
                            console.log('copying notifications', sourceDevice.scenxus.notifications)

                            for (const sourceNotification of sourceDevice.scenxus.notifications) {
                                await updateDeviceNotification(destinationDevice, sourceNotification.method, sourceNotification.recipient, !!sourceNotification.connected, !!sourceNotification.disconnected)
                            }

                            for (const notification of notifications) {
                                let param1 = ''
                                let param2 = null

                                if (notification.method === 'sms') {
                                    notification.recipient = formatPhoneNumberIntl(notification.recipient)
                                    notification.recipient = notification.recipient.substring(1, notification.recipient.length)

                                    const parts = notification.recipient.split(' ')

                                    param1 = parts[0].replace('+', '')
                                    param2 = ''
                                    for (let i = 1; i < parts.length; i++) {
                                        param2 += parts[i]
                                    }
                                } else {
                                    param1 = notification.recipient
                                }

                                const properties = destinationDevice.getProperties()
                                const fragranceRemainingProperty = properties.find(p => p.name === 'fragrance_remaining')
                                if (fragranceRemainingProperty) {
                                    fragranceRemainingProperty.createTriggerApp('compare_absolute', '<', '20', notification.method, 0, param1, param2, `${destinationDsn} Product Level is below 20%`, null)
                                }
                            }
                        }

                        if (copyLocation) {
                            console.log('copying location', sourceDevice)
                            await updateDeviceLocationMetaData(destinationDevice, sourceDevice.scenxus.latitude, sourceDevice.scenxus.longitude, sourceDevice.scenxus.city, sourceDevice.scenxus.state, sourceDevice.scenxus.country)
                            await addMetaData(destinationDevice, 'TimeZone', sourceDevice.scenxus.timeZone)
                        }
                    } catch (error) {
                        console.log('failed to pull destination data')
                        throw error
                    }
                }
            },
            inviteUser: async (email, password, firstname, lastname, city, country, csp, role, state, company, businessRole, phone) => {
                const user = await AylaNetworks.shared().adminService.createUser(email, password, firstname, lastname, country, city, state, getUserComposite(company, businessRole), phone, 'test_confirm')
                await updateUserRole(user, role, csp)
                return user
            },
            updateUser: async (email, firstname, lastname, city, country, csp, role, id, state, company, businessRole, phone) => {
                await AylaNetworks.shared().sessionManager.updateUserProfile({
                    email,
                    firstname,
                    lastname,
                    city,
                    country,
                    state,
                    company: getUserComposite(company, businessRole),
                    phone,
                })

                const user = await AylaNetworks.shared().adminService.fetchUser(id)
                await fetchScenxusUserData(user)
                await updateUserRole(user, role, csp)
            },
            removeUser: async id => {
                await AylaNetworks.shared().adminService.deleteUser(id)
            },
            loadUserProfile: async () => {
                return await AylaNetworks.shared().sessionManager.fetchUserProfile()
            },
            updateUserProfile: async (firstname, lastname, city, state, country) => {
                const user = await AylaNetworks.shared().sessionManager.fetchUserProfile()
                user.firstname = firstname
                user.lastname = lastname
                user.city = city
                user.state = state
                user.country = country

                return await AylaNetworks.shared().sessionManager.updateUserProfile(user)
            },
            updateUserPassword: async (currentPassword, newPassword) => {
                return await AylaNetworks.shared().sessionManager.updatePassword(currentPassword, newPassword)
            },
            resendConfirmationEmail: async email => {
                await AylaNetworks.shared().loginManager.resendConfirmationEmail(email, 'test_confirm')
            },
            confirmAccount: async confirmationToken => {
                await AylaNetworks.shared().loginManager.confirmAccount(confirmationToken)
            },
            resetFragranceLevel: async dsn => {
                const device = await AylaNetworks.shared().adminService.fetchDevice(dsn)
                await fetchScenxusData(device, false, false)

                const properties = device.getProperties()
                const cartridgeLifeProperty = properties.find(p => p.name === 'cartridge_life')
                if (cartridgeLifeProperty) {
                    console.log('updating cartridge life')
                    cartridgeLifeProperty.createDatapoint(0)
                    console.log('updated cartridge life')
                }

                const fragranceRemainingProperty = properties.find(p => p.name === 'fragrance_remaining')
                if (fragranceRemainingProperty) {
                    console.log('updating fragrance remaining')
                    fragranceRemainingProperty.createDatapoint(100)
                    console.log('updated fragrance remaining')
                }
            },
        }
    }, [])

    const providerValue = {
        state,
        actions,
        dispatch,
    }

    //const { state, actions, dispatch } = useContext(AylaContext)
    return <AylaContext.Provider value={providerValue}>{children}</AylaContext.Provider>
}
