import React, { useState, useCallback, useContext, useEffect } from 'react'
import Prolitec from '../Prolitec'
import { Form, Header } from 'semantic-ui-react'
import { Link } from '@reach/router'
import Button from '../../components/buttons/Button'
import { AylaContext } from '../../stores/aylaStore'
import styles from './ResetPasswordPage.module.css'
import { useForm } from '@radbse/hooks'
import { navigate } from '@reach/router'
import ProlitecErrors from '../../components/ProlitecErrors'
import { useDocumentTitle } from '@radbse/hooks'

const ConfirmAccountPage = () => {
    useDocumentTitle('ScenXus | CONFIRM ACCOUNT')
    const [error, setError] = useState(null)
    const [sent, setSent] = useState(false)
    const [loading, setLoading] = useState(false)
    const { actions, state } = useContext(AylaContext)
    const { init } = state;
    console.log(init)
    const fieldMappings = [{ name: 'confirmationToken', label: 'Confirmation Token', errorField: 'confirmation_token' }]

    const submit = useCallback(async ({ confirmationToken }) => {
        console.log('submitting', confirmationToken)
        try {
            setError(null)
            setLoading(true)
            await actions.confirmAccount(confirmationToken)
            setLoading(false)
            setSent(true)
        } catch (error) {
            setLoading(false)
            console.log(error)
            if (error.message) {
                setError(JSON.parse(error.message))
            } else {
                setError({
                    errors: {
                        confirmation_token: ['Invalid Token'],
                    },
                })
            }
        }
    }, [actions])

    const [fields, form] = useForm({
        fields: fieldMappings,
        submit: submit,
    })

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get("token");
        
        if(token && state.init) {
            console.log(token, state.init)
            const load = async () => {
                console.log('conf', token)
                form.setValues({confirmationToken: token })
                await submit({confirmationToken: token })
            };
            
            load();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.init, submit])

    return (
        <Prolitec>
            {sent && (
                <Form error className={`dark`}>
                    <Header>Confirm Account</Header>
                    <div>Account Confirmed</div>
                    <Form.Field>
                        <Button fluid primary onClick={() => navigate('/signin')}>
                            Sign in
                        </Button>
                    </Form.Field>
                </Form>
            )}

            {!sent && (
                <React.Fragment>
                    <ProlitecErrors error={error} fieldMappings={fieldMappings} />
                    <Form error onSubmit={form.submit} className={`dark`}>
                        <Header>Confirm Account</Header>
                        <div>Enter the confirmation token from the 'Confirmation Instructions' email.</div>
                        <Form.Input {...fields.confirmationToken} />
                        <Form.Field>
                            <Button fluid primary loading={loading}>
                                Confirm Account
                            </Button>
                        </Form.Field>
                        <Form.Field>
                            <div>
                                or
                                <Link className={styles.link} to="/signin">
                                    Sign in
                                </Link>
                            </div>
                        </Form.Field>
                    </Form>
                </React.Fragment>
            )}
        </Prolitec>
    )
}

export default ConfirmAccountPage
