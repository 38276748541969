import React from 'react'
import { Router } from '@reach/router'
import Provider from './containers/Provider'
import RootPage from './containers/RootPage'

const App = () => {
    return (
        <Provider>
            <Router className="outerRouter">
                <RootPage path="/*" />
            </Router>
        </Provider>
    )
}

export default App