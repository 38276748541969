import React, { useCallback, useState, useMemo, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useForm } from '@radbse/hooks'
import { Form, FormGroup } from 'semantic-ui-react'
import Button from '../buttons/Button'
import ButtonGroup from '../buttons/ButtonGroup'
import styles from './DeviceForm.module.scss'
import { SessionContext } from '@radbse/auth-identity'
import Moment from 'moment'
// eslint-disable-next-line no-unused-vars
import MomentTimezone from 'moment-timezone'
import { useSystemConfigurationOptions, useStateProvinceOptions, useCountryOptions, useFragranceOptions } from '../../hooks/device'
import { AylaContext } from '../../stores/aylaStore'

const DeviceForm = ({ cancelButton, submitButton, onSubmit, onLoad, csps, saving, editMode }) => {
    const [locationError, setLocationError] = useState(null)
    const [locationLoading, setLocationLoading] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState('')
    const [existingDevice, setExistingDevice] = useState(null)
    const session = useContext(SessionContext)
    const { actions } = useContext(AylaContext)

    const isOEM = useMemo(() => session.user.role === 'OEM::Admin' || session.user.role === 'OEM::Observer', [session.user])

    const systemConfigurationOptions = useSystemConfigurationOptions()
    const stateProvinceOptions = useStateProvinceOptions(selectedCountry, true)
    const countryOptions = useCountryOptions(false)
    const fragranceOptions = useFragranceOptions();

    const timeZoneOptions = useMemo(
        () =>
            Moment.tz.names().map(tz => {
                return { key: tz, text: tz, value: tz }
            }),
        []
    )

    const _fields = [
        { name: 'customer', label: 'Customer' },
        { name: 'csp', label: 'CSP Name',  type: 'select' },
        { name: 'serviceArea', label: 'Service Area (Device Name)' },
        { name: 'customerId', label: 'Customer ID', optional: true },
        { name: 'storeNumber', label: 'Store Number', optional: true },
        { name: 'serialNumber', label: 'Serial #' },
        { name: 'dsn', label: 'DSN' },
        { name: 'systemConfiguration', label: 'System Configuration', type: 'select', options: systemConfigurationOptions },
        { name: 'fragrance', label: 'Fragrance', type: 'select' },
        { name: 'latitude', label: 'Latitude', optional: true },
        { name: 'longitude', label: 'Longitude', optional: true },
        { name: 'timeZone', label: 'Time Zone', type: 'select', options: timeZoneOptions },
        { name: 'state', label: 'State/Province', optional: true, type: 'select' },
        { name: 'city', label: 'City' },
        { name: 'country', label: 'Country', type: 'select', options: countryOptions },
    ]

    const [fields, form] = useForm({
        fields: _fields,
        validate: ({ dsn }) => {
            if (!editMode) {
                if (!existingDevice) {
                    return { dsn: 'No device with that DSN was found' }
                }
                
                if (existingDevice.scenxus.customer) {
                    return { dsn: 'A device with this DSN has already been registered' }
                }
            }
        },
        submit: fields => {
            if (onSubmit) onSubmit(fields)
        },
    })

    useEffect(() => {
        if (onLoad) {
            onLoad(device => {
                form.setValues(device)
                setSelectedCountry(device.country ?? '')
            })
        }

        if (!isOEM) {
            form.setValue('csp', session.user.csp)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onDetectLocation = useCallback(() => {
        setLocationError(null)
        setLocationLoading(true)
        navigator.geolocation.getCurrentPosition(
            function(position) {
                setLocationLoading(false)

                fields.latitude.onChange({ target: { value: position.coords.latitude.toFixed(6) } })
                fields.longitude.onChange({ target: { value: position.coords.longitude.toFixed(6) } })
            },
            function(error) {
                setLocationLoading(false)
                let message = 'An unknown error occurred.'
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        message = 'User denied the request for Geolocation.'
                        break
                    case error.POSITION_UNAVAILABLE:
                        message = 'Location information is unavailable.'
                        break
                    case error.TIMEOUT:
                        message = 'The request to get user location timed out.'
                        break
                    default:
                        break
                }
                setLocationError(message)
            }
        )
    }, [fields.latitude, fields.longitude])

    const cspOptions = useMemo(
        () =>
            csps.map(csp => {
                return { key: csp.name, value: csp.name, text: csp.name }
            }),
        [csps]
    )

    const onDsnBlur = useCallback(async () => {
        if (!editMode && fields.dsn?.value) {

            try {
                const device = await actions.loadBaseDevice(fields.dsn.value)
                console.log(device)
                setExistingDevice(device)
            }
            catch {
                setExistingDevice(null)
            }
        }
    }, [editMode, fields.dsn])

    return (
        <Form className={styles.rows}>
            <div className={styles.row}>
                <label>Name</label>
                <Form.Group>
                    <Form.Input {...fields.customer} width={4} required />
                    {isOEM && <Form.Dropdown {...fields.csp} width={4} options={cspOptions} onChange={(_, { value }) => form.setValue('csp', value)} required scrolling search />}
                    <Form.Input {...fields.serviceArea} width={4} required />
                </Form.Group>
                <Form.Group>
                    <Form.Input {...fields.customerId} width={4} />
                    <Form.Input {...fields.storeNumber} width={4} />
                </Form.Group>
            </div>
            <div className={styles.row}>
                <label>Id</label>
                <Form.Group>
                    <Form.Input {...fields.serialNumber} width={4} required />
                    <Form.Input {...fields.dsn} width={4} required disabled={editMode} error={form.errors?.dsn} onBlur={onDsnBlur} />
                </Form.Group>
                <FormGroup>
                    <Form.Dropdown {...fields.systemConfiguration} onChange={(_, { value }) => form.setValue('systemConfiguration', value)} width={4} required scrolling search />
                    <Form.Dropdown {...fields.fragrance} options={fragranceOptions} onChange={(_, { value }) => form.setValue('fragrance', value)} width={4} required scrolling search />
                </FormGroup>
            </div>
            <div className={styles.row}>
                <label>Location</label>
                {'geolocation' in navigator && (
                    <div style={{ marginBottom: '20px' }}>
                        <Button iconUrl="/assets/ic-location.svg" title="Detect Location" onClick={onDetectLocation} loading={locationLoading} />
                        <div className={styles.error}>{locationError}</div>
                    </div>
                )}
                <Form.Group>
                    <Form.Input {...fields.latitude} width={4} />
                    <Form.Input {...fields.longitude} width={4} />
                    <Form.Dropdown {...fields.timeZone} width={4} required onChange={(_, { value }) => form.setValue('timeZone', value)} scrolling search />
                </Form.Group>
                <Form.Group>
                    <Form.Input {...fields.city} width={4} required />
                    <Form.Dropdown {...fields.state} width={4} onChange={(_, { value }) => form.setValue('state', value)} scrolling search options={stateProvinceOptions} />
                    <Form.Dropdown {...fields.country} width={4} required onChange={(_, { value }) => {
                        form.setValue('country', value)
                        setSelectedCountry(value)
                    }} scrolling search />
                </Form.Group>
            </div>
            <div className={styles.buttonRow}>
                <ButtonGroup
                    horizontal
                    buttons={[
                        { ...cancelButton, disabled: saving },
                        { ...submitButton, primary: true, loading: saving, onClick: form.submit },
                    ]}
                />
            </div>
        </Form>
    )
}

DeviceForm.propTypes = {
    cancelButton: PropTypes.object.isRequired,
}

export default DeviceForm
