import React, { useState, useCallback, useContext, useEffect } from 'react'
import { Header, Form, Menu } from 'semantic-ui-react'
import styles from './AccountPage.module.scss'
import { useForm, useAlert } from '@radbse/hooks'
import ButtonGroup from '../components/buttons/ButtonGroup'
import { AylaContext } from '../stores/aylaStore'
import LoadingDimmer from '../components/LoadingDimmer'
import { useAuthApi } from '@radbse/auth-identity'
import { navigate } from '@reach/router'
import { useDocumentTitle } from '@radbse/hooks'
import { useStateProvinceOptions, useCountryOptions } from '../hooks/device'
import PasswordRules from '../components/users/PasswordRules'

const AccountPage = () => {
    useDocumentTitle('ScenXus | ACCOUNT')
    const [, setAlert] = useAlert()
    const { actions, state } = useContext(AylaContext)
    const { ready } = state
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(false)
    const [authActions, useApi] = useAuthApi()
    const [, login] = useApi(authActions.api.login)

    const [activeTab, setActiveTab] = useState(0)
    const [saving, setSaving] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState('')
    
    const stateProvinceOptions = useStateProvinceOptions(selectedCountry, true)
    const countryOptions = useCountryOptions(false)

    const [personalInformationFields, personalInformationForm] = useForm({
        fields: [
            { name: 'firstname', label: 'First Name' },
            { name: 'lastname', label: 'Last Name' },
            { name: 'city', label: 'City' },
            { name: 'state', label: 'State/Province', type: 'select', optional: true },
            { name: 'country', label: 'Country', type: 'select', options: countryOptions },
        ],
        submit: async ({ firstname, lastname, city, state, country }) => {
            try {
                setSaving(true)
                await actions.updateUserProfile(firstname, lastname, city, state, country)
                setAlert({ content: `Successfully updated account`, color: 'green' })
                setSaving(false)
            } catch {
                setAlert({ content: 'Failed to save personal information', error: true, icon: 'exclamation circle' })
                setSaving(false)
            }
        },
    })

    const [passwordFields, passwordForm] = useForm({
        fields: [
            { name: 'currentPassword', label: 'Current Password' },
            { name: 'newPassword', label: 'New Password' },
            { name: 'confirmPassword', label: 'Confirm Password' },
        ],
        validate: ({ newPassword, confirmPassword }) => {
            if (newPassword !== confirmPassword) {
                return {
                    confirmPassword: 'Does not match',
                }
            }
        },
        submit: async ({ currentPassword, newPassword }) => {
            try {
                setSaving(true)
                
                await actions.updateUserPassword(currentPassword, newPassword)
                
                try {
                    await login({ email: user.email, password: newPassword })
                } catch (error) {
                    setAlert({ content: 'Failed to authenticate after password change', error: true, icon: 'exclamation circle' })
                }
                passwordForm.reset()
                setAlert({ content: `Successfully updated password`, color: 'green' })
                setSaving(false)
            } catch {
                setAlert({ content: 'Failed to save new password', error: true, icon: 'exclamation circle' })
                setSaving(false)
            }
        },
    })

    const [timeFormatFields, timeFormatForm] = useForm({
        fields: [{ name: 'type', label: 'Type' }],
        submit: ({ type }) => {},
    })

    const timeFormatChange = useCallback(
        (field, value) => {
            timeFormatForm.setValue(field, value)
        },
        [timeFormatForm]
    )

    useEffect(() => {
        if (ready) {
            const load = async () => {
                try {
                    setLoading(true)
                    
                    const user = await actions.loadUserProfile()
                    setUser(user)
                    console.log(user)
                    personalInformationForm.setValues({
                        firstname: user.firstname || '',
                        lastname: user.lastname || '',
                        city: user.city || '',
                        state: user.state || '',
                        country: user.country || '',
                    })
                    setSelectedCountry(user.country)
                    setLoading(false)
                } catch {
                    setLoading(false)
                    setAlert({ content: 'Failed to load user', error: true, icon: 'exclamation circle' })
                }
            }

            load()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ready])

    const render = useCallback(() => {
        if (user) {
            // eslint-disable-next-line default-case
            switch (activeTab) {
                case 0:
                    return (
                        <React.Fragment>
                            <Form>
                                <Form.Field>{user.email}</Form.Field>
                                <Form.Input {...personalInformationFields.firstname} width={4} required />
                                <Form.Input {...personalInformationFields.lastname} width={4} required />
                                <Form.Input {...personalInformationFields.city} width={4} required />
                                <Form.Dropdown {...personalInformationFields.state} width={4} onChange={(_, { value }) => personalInformationForm.setValue('state', value)} options={stateProvinceOptions} scrolling search />
                                <Form.Dropdown {...personalInformationFields.country} width={4} required onChange={(_, { value }) => {
                                    personalInformationForm.setValue('country', value)
                                    setSelectedCountry(value)
                                }} options={countryOptions} scrolling search />
                            </Form>
                            <div className={styles.buttonRow}>
                                <ButtonGroup
                                    horizontal
                                    buttons={[
                                        { title: 'Cancel', iconUrl: '/assets/ic-cancel.svg', disabled: saving, onClick: () => navigate('/') },
                                        { title: 'Save Changes', iconUrl: '/assets/ic-checkmark.svg', primary: true, loading: saving, onClick: personalInformationForm.submit },
                                    ]}
                                />
                            </div>
                        </React.Fragment>
                    )
                case 1:
                    return (
                        <React.Fragment>
                            <Form>
                                <Form.Input {...passwordFields.currentPassword} required type="password" width={4} />
                                <Form.Input {...passwordFields.newPassword} required type="password" width={4} />
                                <Form.Input {...passwordFields.confirmPassword} required type="password" width={4} />
                                <Form.Group>
                                    <PasswordRules />
                                </Form.Group>
                            </Form>
                            <div className={styles.buttonRow}>
                                <ButtonGroup
                                    horizontal
                                    buttons={[
                                        { title: 'Cancel', iconUrl: '/assets/ic-cancel.svg', disabled: saving, onClick: () => navigate('/') },
                                        { title: 'Save Changes', iconUrl: '/assets/ic-checkmark.svg', primary: true, loading: saving, onClick: passwordForm.submit },
                                    ]}
                                />
                            </div>
                        </React.Fragment>
                    )
                case 2:
                    return (
                        <React.Fragment>
                            <Form>
                                <Form.Radio width={4} name="type" value="12hour" onChange={(_, { value }) => timeFormatChange('type', value)} label="12-Hour Time Format (6:00 pm)" checked={timeFormatFields.type.value === '12hour'} />
                                <Form.Radio width={4} name="type" value="24hour" onChange={(_, { value }) => timeFormatChange('type', value)} label="24-Hour Time Format (18:00 pm)" checked={timeFormatFields.type.value === '24hour'} />
                            </Form>
                            <div className={styles.buttonRow}>
                                <ButtonGroup
                                    horizontal
                                    buttons={[
                                        { title: 'Cancel', iconUrl: '/assets/ic-cancel.svg', disabled: saving, onClick: () => navigate('/') },
                                        { title: 'Save Changes', iconUrl: '/assets/ic-checkmark.svg', primary: true, loading: saving, onClick: timeFormatForm.submit },
                                    ]}
                                />
                            </div>
                        </React.Fragment>
                    )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, passwordFields, personalInformationFields, timeFormatFields, saving, timeFormatChange, activeTab])

    return (
        <div className={styles.secondaryPageContainer}>
            <div className={styles.header}>
                <Header>My Account</Header>

                <div className={styles.right}>
                    <ButtonGroup horizontal buttons={[{ title: 'Sign Out', iconUrl: '/assets/ic-sign-out.svg', onClick: () => navigate('/signout') }]} />
                </div>
            </div>
            <LoadingDimmer ready={ready} loading={loading} loadingText={`Loading User`} />
            <Menu className={styles.menu} pointing secondary size="tiny">
                <Menu.Item className={styles.menuItem} active={activeTab === 0} onClick={() => setActiveTab(0)}>
                    Personal Information
                </Menu.Item>
                <Menu.Item className={styles.menuItem} active={activeTab === 1} onClick={() => setActiveTab(1)}>
                    Set Password
                </Menu.Item>
                {/* <Menu.Item className={styles.menuItem} active={activeTab === 2} onClick={() => setActiveTab(2)}>
                    Time Format
                </Menu.Item> */}
            </Menu>
            {render()}
        </div>
    )
}

export default AccountPage
