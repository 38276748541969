import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Header } from 'semantic-ui-react'
import DeviceForm from '../../components/devices/DeviceForm'
import styles from './EditDevicePage.module.scss'
import { navigate } from '@reach/router'
import { AylaContext } from '../../stores/aylaStore'
import LoadingDimmer from '../../components/LoadingDimmer'
import { useAlert } from '@radbse/hooks'
import { useScenXusApi } from '../../stores/scenxusStore'
import { useDocumentTitle } from '@radbse/hooks'

const EditDevicePage = ({ dsn }) => {
    useDocumentTitle('ScenXus | EDIT DEVICE')
    const { actions, state } = useContext(AylaContext)
    const { ready } = state
    
    const [, setAlert] = useAlert()

    const [device, setDevice] = useState(null)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)

    const [csps, setCsps] = useState(null)
    const [scenxusActions, useApi] = useScenXusApi()
    const [, getAllCsps] = useApi(scenxusActions.getAllCsps)
    const [, updateDevice] = useApi(scenxusActions.updateDevice)

    const onSubmit = useCallback(
        async ({ customer, csp, serviceArea, serialNumber, dsn, systemConfiguration, fragrance, latitude, longitude, timeZone, state, city, country, customerId, storeNumber }) => {
            try {
                setSaving(true)
                await actions.updateDevice(dsn, serialNumber, systemConfiguration, fragrance, customer, timeZone, serviceArea, longitude, latitude, city, state, country, csp, customerId, storeNumber)
                await updateDevice(dsn)
                setSaving(false)
                setAlert({ error: false, content: `Successfully updated device`, color: 'green' })
                navigate(`/devices/${dsn}`)
            } catch (error) {
                setSaving(false)
                setAlert({ error: true, content: `Failed to update device. ${error.message}`, icon: 'exclamation circle' })
            }
        },
        [actions, setAlert, updateDevice]
    )

    useEffect(() => {
        if (ready) {
            const load = async () => {
                setLoading(true)
                const device = await actions.loadDevice(dsn)
                setDevice(device)
                const result = await getAllCsps()
                setCsps(result.response)
                setLoading(false)
            }

            load()
        }
    }, [ready, dsn]) // eslint-disable-line react-hooks/exhaustive-deps

    const onLoad = useCallback(
        setValues => {
            const scenxus = device.scenxus
            setValues({ ...scenxus, ...scenxus.location })
        },
        [device]
    )

    return (
        <div className={styles.secondaryPageContainer}>
            <Header>Edit Device</Header>
            <LoadingDimmer ready={ready} loading={loading} loadingText={`Loading Device - ${dsn}`} />
            {device && csps && (
                <DeviceForm
                    cancelButton={{ iconUrl: '/assets/ic-cancel.svg', title: 'Cancel', onClick: () => navigate(`/devices/${dsn}`) }}
                    submitButton={{ iconUrl: '/assets/ic-checkmark.svg', title: 'Save Changes' }}
                    onSubmit={onSubmit}
                    onLoad={onLoad}
                    csps={csps}
                    saving={saving}
                    editMode
                />
            )}
        </div>
    )
}

export default EditDevicePage
