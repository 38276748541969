import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useForm } from '@radbse/hooks'
import { Form } from 'semantic-ui-react'
import { modes as _modes, cycles as _cycles, fanSpeeds as _fanSpeeds } from '../../utilities/deviceHelpers'
import { enumToSelectList, numberArrayToSelectList } from '../../utilities/converters'
import ScenXusModal from '../ScenXusModal'
import IntensityFormField from './IntensityFormField'

const EditDeviceSettingsModal = ({ open, settings, onClose, onSave, loading }) => {
    const modes = useMemo(() => enumToSelectList(_modes), [])
    const cycles = useMemo(() => enumToSelectList(_cycles), [])
    const fanSpeeds = useMemo(() => numberArrayToSelectList(_fanSpeeds, ["Off"]), [])

    const [fields, form] = useForm({
        fields: [{ name: 'mode', label: 'Mode', type: 'select' }, 
                 { name: 'cycle', label: 'Off-Cycle', type: 'select' }, 
                 { name: 'intensity', label: 'Intensity', type: 'select' }, 
                 { name: 'fan', label: 'Fan Speed', type: 'select' }],
        submit: onSave,
    })

    useEffect(() => {
        form.setValues({ ...settings })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const intensityEnabled = useMemo(() => fields.mode.value !== 2, [fields.mode.value])

    return (
        <ScenXusModal
            open={open}
            onClose={onClose}
            buttons={[{ iconUrl: '/assets/ic-cancel.svg', onClick: onClose, title: 'Cancel' }, { iconUrl: '/assets/ic-checkmark.svg', onClick: form.submit, title: 'Confirm', primary: true, loading }]}
        >
            <Form>
                <Form.Dropdown {...fields.mode} options={modes} onChange={(_, { value }) => form.setValue('mode', value)} scrolling search />
                <Form.Dropdown {...fields.cycle} options={cycles} onChange={(_, { value }) => form.setValue('cycle', value)} scrolling search />
                <Form.Group>
                    <IntensityFormField {...fields.intensity} width={8} disabled={!intensityEnabled} />
                    <Form.Dropdown {...fields.fan} onChange={(_, { value }) => form.setValue('fan', value)} options={fanSpeeds} width={8} scrolling search />
                </Form.Group>
            </Form>
        </ScenXusModal>
    )
}

EditDeviceSettingsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    settings: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
}

export default EditDeviceSettingsModal
