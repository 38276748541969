import React, { useCallback } from 'react'
import { Header } from 'semantic-ui-react'
import styles from './AddCspPage.module.scss'
import CspForm from '../../components/csps/CspForm'
import { navigate } from '@reach/router'
import { useScenXusApi } from '../../stores/scenxusStore'
import { useAlert, useDocumentTitle } from '@radbse/hooks'

const AddCspPage = () => {
    useDocumentTitle('ScenXus | ADD CSP')
    const [actions, useApi] = useScenXusApi()
    const [, addCsp] = useApi(actions.addCsp)
    const [, setAlert] = useAlert()

    const onSubmit = useCallback(
        async ({ name }) => {
            try {
                name = name.trim()
                await addCsp({ name })
                setAlert({ content: 'Successfully added CSP', color: 'green' })
                navigate('/csps')
            } catch {
                setAlert({ content: 'Failed to create CSP', error: true, icon: 'exclamation circle' })
            }
        },
        [addCsp, setAlert]
    )

    return (
        <div className={styles.secondaryPageContainer}>
            <Header>Add CSP</Header>
            <CspForm cancelButton={{ iconUrl: '/assets/ic-cancel.svg', title: 'Cancel', onClick: () => navigate('/csps') }} submitButton={{ iconUrl: '/assets/ic-checkmark.svg', title: 'Add CSP' }} onSubmit={onSubmit} />
        </div>
    )
}

export default AddCspPage
