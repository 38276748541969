import React from 'react'
import PropTypes from 'prop-types'
import { List, Label } from 'semantic-ui-react'

const PropertyListItem = ({ title, children }) => {
    return (
        <List.Item>
            <Label horizontal>{title}</Label>
            {children}
        </List.Item>
    )
}

PropertyListItem.propTypes = {
    title: PropTypes.string.isRequired,
}

export default PropertyListItem
