import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import NumberInput from 'semantic-ui-react-numberinput'
import { Form } from 'semantic-ui-react'

const IntensityFormField = props => {
    const _value = useMemo(() => `${props.value || 1}`, [props.value])
    return (
        <Form.Field width={props.width}>
            <label>{props.label}</label>
            <Form.Input {...props} as={NumberInput} value={_value || '1'} buttonPlacement="leftAndRight" onChange={value => props.onChange({ target: { value } })} minValue={1} maxValue={50} />
        </Form.Field>
    )
}

IntensityFormField.propTypes = {
    label: PropTypes.string.isRequired,
}

export default IntensityFormField
