import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styles from './Intensity.module.scss'
import { useMode } from '../../hooks/device'

const Intensity = ({ device }) => {
    const [, modeOn] = useMode(device)

    const currentRunningSchedule = useMemo(() => (device.scenxus.mode === 4 && device.scenxus.schedule ? device.scenxus.schedule.find(s => s.number === device.scenxus.currentScheduleRunning) : null), [device.scenxus])

    const intensity = useMemo(() => {
        return currentRunningSchedule ? currentRunningSchedule.intensity : device.scenxus.intensity
    }, [currentRunningSchedule, device])

    const intensityDesc = useMemo(() => {
        const additional = currentRunningSchedule ? ` (Schedule ${device.scenxus.currentScheduleRunning})` : ""
        return `${intensity}${additional}`
    }, [currentRunningSchedule, device.scenxus, intensity])

    return (
        <div className={styles.row}>
            {device.scenxus.intensity && intensityDesc && (
                <React.Fragment>
                    <div className={styles[`intensity-${Math.ceil(intensity / 10)}-${modeOn ? 'on' : 'off'}`]} />
                    <div>{intensityDesc}</div>
                </React.Fragment>
            )}
            {device.scenxus.intensity == null && intensityDesc == null && <div>---</div>}
        </div>
    )
}

Intensity.propTypes = {
    device: PropTypes.object,
}

export default Intensity
