import React, { useCallback, useMemo } from 'react'
import { Table } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import styles from './SortableTable.module.scss'

const SortableTable = ({ onOrderChange, order, orderBy, children, clickable }) => {
    const onColumnClick = useCallback(
        column => {
            return () => {
                let direction = order
                if (column === orderBy && direction === 'asc') {
                    direction = 'desc'
                } else {
                    direction = 'asc'
                }

                if (onOrderChange) {
                    onOrderChange(column, direction)
                }
            }
        },
        [onOrderChange, order, orderBy]
    )

    const _order = useMemo(() => (order === 'asc' ? 'ascending' : 'descending'), [order])

    const sorted = useCallback(name => (orderBy === name ? _order : null), [_order, orderBy])

    return (
        <Table className={clickable ? styles.clickable : null} striped sortable>
            {children(sorted, onColumnClick)}
        </Table>
    )
}

SortableTable.propTypes = {
    onOrderChange: PropTypes.func.isRequired,
    order: PropTypes.string,
    orderBy: PropTypes.string,
}

export default SortableTable
