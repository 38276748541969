import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Table } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import styles from './User.module.scss'
import Button from '../buttons/Button'
import { AylaContext } from '../../stores/aylaStore'
import { useAlert } from '@radbse/hooks'
import { SessionContext } from '@radbse/auth-identity'

const User = ({ user, onClick }) => {
    const { actions, state } = useContext(AylaContext)
    const [, setAlert] = useAlert()
    const { user: sessionUser } = useContext(SessionContext)
    const isOEM = useMemo(() => sessionUser.role === 'OEM::Admin' || sessionUser.role === 'OEM::Observer', [sessionUser])
    const [resendLoading, setResendLoading] = useState(false)

    const onResend = useCallback(
        async e => {
            e.stopPropagation()
            e.nativeEvent.stopImmediatePropagation()

            try {
                setResendLoading(true)
                await actions.resendConfirmationEmail(user.email.trim())
                setAlert({ error: true, content: `Successfully resent confirmation email`, color: 'green' })
                setResendLoading(false)
            } catch {
                setResendLoading(false)
                setAlert({ content: 'User already confirmed', error: true, icon: 'exclamation circle' })
            }
        },
        [actions, setAlert, user]
    )

    return (
        <Table.Row onClick={() => (onClick ? onClick(user.id) : null)}>
            <Table.Cell>{user.firstname}</Table.Cell>
            <Table.Cell>{user.lastname}</Table.Cell>
            <Table.Cell>{user.email}</Table.Cell>
            <Table.Cell>{user.company}</Table.Cell>
            <Table.Cell>{user.businessRole}</Table.Cell>
            <Table.Cell>{user.phone}</Table.Cell>
            {isOEM && <Table.Cell>{user.csp}</Table.Cell>}
            <Table.Cell>{user.roleName}</Table.Cell>
            <Table.Cell className={styles.confirmed}>
                {user.confirmed && <div className={styles[`checkmark-${user.confirmed.toString()}`]} />}
                {!user.confirmed && state.ready && <Button title="Resend" onClick={onResend} loading={resendLoading} />}
            </Table.Cell>
        </Table.Row>
    )
}

User.propTypes = {
    user: PropTypes.object.isRequired,
    onClick: PropTypes.func,
}

export default User
