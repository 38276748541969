import React, { useContext } from 'react'
import { SessionContext } from '@radbse/auth-identity'
import { Redirect } from '@reach/router'

const ProtectedRoute = ({ component: Component, roles, ...rest }) => {
    const session = useContext(SessionContext)
    let authorized = false

    if (session.user && roles) {
        if (Array.isArray(roles)) {
            roles.forEach(r => {
                if (session.user.role === r) authorized = true
            })
        } else {
            if (session.user.role === roles) authorized = true
        }
    } 

    return <React.Fragment>{session.authenticated && (!roles || authorized) ? <Component {...rest} /> : <Redirect to="/signin" noThrow />}</React.Fragment>
}

export default ProtectedRoute
