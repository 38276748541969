import React, { useState, useMemo, useCallback, useContext, useEffect } from 'react'
import { Header } from 'semantic-ui-react'
import Button from '../../components/buttons/Button'
import ProlitecErrors from '../../components/ProlitecErrors'
import UserForm from '../../components/users/UserForm'
import styles from './EditUserPage.module.scss'
import { navigate } from '@reach/router'
import { useAlert, useDocumentTitle } from '@radbse/hooks'
import LoadingDimmer from '../../components/LoadingDimmer'
import { AylaContext } from '../../stores/aylaStore'
import TextOnlyButton from '../../components/buttons/TextOnlyButton'
import ScenXusModal from '../../components/ScenXusModal'
import { useScenXusApi } from '../../stores/scenxusStore'
import { SessionContext } from '@radbse/auth-identity'

const EditUserPage = ({ id }) => {
    useDocumentTitle('ScenXus | EDIT USER')
    const { actions, state } = useContext(AylaContext)
    const { ready } = state
    const [scenxusActions, useApi] = useScenXusApi()
    const [, getAllCsps] = useApi(scenxusActions.getAllCsps)
    const [, updateUser] = useApi(scenxusActions.updateUser)

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [, setAlert] = useAlert()
    const [user, setUser] = useState(null)
    const [csps, setCsps] = useState(null)
    const errorMappings = useMemo(() => [{ name: 'email', errorField: 'email' }], [])
    const [removeOpen, setRemoveOpen] = useState(false)
    const [removing, setRemoving] = useState(false)
    const [resetOpen, setResetOpen] = useState(false)
    const [resetting, setResetting] = useState(false)

    const session = useContext(SessionContext)

    const isAdmin = useMemo(() => session.user.role === 'OEM::Admin', [session.user.role])
    const isCSPAdmin = useMemo(() => session.user.role.includes('CspAdmin'), [session.user.role])
    const isMe = useMemo(() => session.user.email === (user || {}).email, [session, user])
    const isObserver = useMemo(() => session.user.role === 'OEM::Observer' || session.user.role === 'OEM::ProlitecCommercial::CspObserver', [session.user.role])

    const onLoad = useCallback(
        setValues => {
            let companyName, businessRole
            try {
                const { company: _companyName, businessRole: _businessRole, } = JSON.parse(user.company)
                companyName = _companyName
                businessRole = _businessRole
            } catch (error) {
                companyName = user.company
                businessRole = ''
            }

            setValues({
                email: user.email,
                firstname: user.firstname,
                lastname: user.lastname,
                city: user.city,
                state: user.state,
                country: user.country,
                csp: user.csp || '',
                role: user.role,
                company: companyName,
                businessRole: businessRole,
                phone: user.phone,
            })
        },
        [user]
    )

    const onSubmit = useCallback(
        async ({ email, firstname, lastname, city, country, csp, role, state, company, businessRole, phone }) => {
            try {
                setError(null)
                setSaving(true)

                console.log('isCSPAdmin', isCSPAdmin)
                console.log('')
                let newCsp = role === 'OEM::Admin' || role === 'OEM::Observer' ? null : csp;
                console.log('newCsp', newCsp)
                //if I'm only a CSP Admin, use the current csp from user, which will be my csp as well
                if(!csp && isCSPAdmin) 
                {
                    newCsp = user.csp;
                    console.log('newCsp', newCsp)
                }

                await actions.updateUser(email.trim(), firstname, lastname, city, country, newCsp, role, user.id, state, company, businessRole, phone)
                await updateUser(user.id)
                setSaving(false)
                setAlert({ error: true, content: `Successfully updated user`, color: 'green' })
                navigate('/users')
            } catch (_error) {
                setSaving(false)
                
                if (_error.message) {
                    try
                    {
                        setError(JSON.parse(_error.message))
                    }
                    catch {}
                }

                setAlert({ content: 'Failed to save user', error: true, icon: 'exclamation circle' })
            }
        },
        [actions, isCSPAdmin, setAlert, updateUser, user]
    )

    useEffect(() => {
        if (ready) {
            const load = async () => {
                setLoading(true)
                const user = await actions.loadUser(id)
                const result = await getAllCsps()
                setUser(user)
                setCsps(result.response)
                setLoading(false)
            }

            load()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ready, id])

    const onRemove = useCallback(async () => {
        try {
            setRemoving(true)
            await actions.removeUser(id)
            setRemoving(false)
            setRemoveOpen(false)
            setAlert({ error: true, content: `Successfully removed user`, color: 'green' })
            navigate('/users')
        } catch {
            setRemoving(false)
            setRemoveOpen(false)
            setAlert({ content: 'Failed to remove user', error: true, icon: 'exclamation circle' })
        }
    }, [actions, id, setAlert])

    const onReset = useCallback(async () => {
        try {
            setResetting(true)
            await actions.sendPasswordReset(user.email.trim())
            setResetting(false)
            setResetOpen(false)
            setAlert({ error: true, content: `Successfully sent password reset email`, color: 'green' })
            navigate('/users')
        } catch {
            setResetting(false)
            setResetOpen(false)
            setAlert({ content: 'Failed to sent password reset email', error: true, icon: 'exclamation circle' })
        }
    }, [actions, user, setAlert])

    return (
        <div className={styles.secondaryPageContainer}>
            <Header>Edit User</Header>
            <ProlitecErrors error={error} fieldMappings={errorMappings} />
            <LoadingDimmer ready={ready} loading={loading} loadingText={`Loading User - ${id}`} />
            {user && csps && <UserForm edit onSubmit={onSubmit} submitButton={{ iconUrl: '/assets/ic-checkmark.svg', title: 'Save Changes' }} onLoad={onLoad} csps={csps} saving={saving} disabled={isMe || isObserver} />}

            {user && !saving && isAdmin && (
                <div className={styles['action-button-container']}>
                    <ScenXusModal
                        title="Reset Password"
                        trigger={<Button iconUrl={'/assets/ic-key-white.svg'} primary title="Reset Password" />}
                        buttons={[
                            { iconUrl: '/assets/ic-cancel.svg', title: 'Cancel', onClick: () => setResetOpen(false) },
                            { iconUrl: '/assets/ic-checkmark.svg', title: 'Reset Password', primary: true, onClick: onReset, loading: resetting },
                        ]}
                        open={resetOpen}
                        onOpen={() => setResetOpen(true)}
                        onClose={() => setResetOpen(false)}
                    >
                        <p>
                            Are you sure you want to reset the password for{' '}
                            <b>
                                {user.firstname} {user.lastname}
                            </b>{' '}
                            ({user.email})?
                        </p>
                    </ScenXusModal>
                </div>
            )}


            {user && !saving && !isMe && !isObserver && (
                <div className={styles['action-button-container']}>
                    <ScenXusModal
                        title="Remove User"
                        trigger={<TextOnlyButton text="Remove User" />}
                        buttons={[
                            { iconUrl: '/assets/ic-cancel.svg', title: 'Cancel', onClick: () => setRemoveOpen(false) },
                            { iconUrl: '/assets/ic-checkmark.svg', title: 'Remove User', primary: true, onClick: onRemove, loading: removing },
                        ]}
                        open={removeOpen}
                        onOpen={() => setRemoveOpen(true)}
                        onClose={() => setRemoveOpen(false)}
                    >
                        <p>
                            Are you sure you want to remove{' '}
                            <b>
                                {user.firstname} {user.lastname}
                            </b>{' '}
                            ({user.email})?
                        </p>
                        <p>This action cannot be undone.</p>
                    </ScenXusModal>
                </div>
            )}
        </div>
    )
}

export default EditUserPage
