import React, { useState, useCallback, useEffect, useContext } from 'react'
import { SessionContext } from '@radbse/auth-identity'
import { Router, Redirect } from '@reach/router'
import { useDocumentTitle, useAlert } from '@radbse/hooks'
import { Portal, Message, Segment, Icon } from 'semantic-ui-react'
import Navbar from '../components/navbar/Navbar'
import SignInPage from './auth/SignInPage'
import DevicesPage from './devices/DevicesPage'
import SignOutPage from './auth/SignOutPage'
import ProtectedRoute from './routing/ProtectedRoute'
import UsersPage from './users/UsersPage'
import CspsPage from './csps/CspsPage'
import DevicePage from './devices/DevicePage'
import ResetPasswordPage from './auth/ResetPasswordPage'
import SetPasswordPage from './auth/SetPasswordPage'
import AddDevicePage from './devices/AddDevicePage'
import EditDevicePage from './devices/EditDevicePage'
import EditDeviceSchedulePage from './devices/EditDeviceSchedulePage'
import CopyDevicePage from './devices/CopyDevicePage'
import InviteUserPage from './users/InviteUserPage'
import EditUserPage from './users/EditUserPage'
import AddCspPage from './csps/AddCspPage'
import EditCspPage from './csps/EditCspPage'
import AccountPage from './AccountPage'
import AboutPage from './AboutPage'
import HelpPage from './help/HelpPage'
import ConfirmAccountPage from './auth/ConfirmAccountPage'
import ImportPage from './import/ImportPage'
import { useScenXusApi } from '../stores/scenxusStore'
import LoadingDimmer from '../components/LoadingDimmer'

const RootPage = ({ location }) => {
    useDocumentTitle('ScenXus | HOME')
    const [alert, setAlert] = useAlert()
    const [messageTimer, setMessageTimer] = useState(null)
    const session = useContext(SessionContext)

    const [actions, useApi] = useScenXusApi()
    const [loadingFragrances, getFragrances] = useApi(actions.getFragrances)

    useEffect(() => {
        const load = async () => {
            await getFragrances()
        }

        if(session.authenticated) {
            load()
        }
    }, [getFragrances, session.authenticated])

    const handleClose = useCallback(() => {
        setAlert({ content: null, timeout: 0 })
        if (messageTimer) {
            clearTimeout(messageTimer)
            setMessageTimer(null)
        }
    }, [messageTimer, setAlert])

    useEffect(() => {
        if (alert.content && alert.timeout) {
            setMessageTimer(
                setTimeout(() => {
                    setMessageTimer(null)
                    setAlert({ content: null, timeout: 0 })
                }, alert.timeout)
            )
        }
    }, [alert, setAlert])

    if (location.pathname === '/') {
        if (session.authenticated) {
            return <Redirect to="/devices" noThrow />
        } else {
            return <Redirect to="/signin" noThrow />
        }
    }

    return (
        <React.Fragment>
            <Message warning hidden>
                <Icon name='warning' />
                This site will briefly be offline for maintenance starting on Wednesday, November 9 at 11:00 PM EST. <strong>Your devices will continue to function during this time.</strong> We appologize for any inconvenience this causes.
            </Message>
            <LoadingDimmer ready={true} loading={loadingFragrances} loadingText="Loading Fragrances" />
            <Portal closeOnDocumentClick open={alert.content !== null} onClose={handleClose}>
                <div
                    id="test"
                    style={{
                        position: 'fixed',
                        top: '0',
                        zIndex: 1000,
                        display: 'flex',
                        width: '100%',
                        padding: '20px',
                        justifyContent: 'center',
                    }}
                >
                    {alert.content ? <Message {...alert} /> : alert.content}
                </div>
            </Portal>
            {session.authenticated && <Navbar />}

            <Router className="innerRouter">
                <SignInPage path="/" />
                <SignInPage path="signin" />
                <ResetPasswordPage path="password/reset" />
                <SetPasswordPage path="password/set" />
                <ConfirmAccountPage path="account/confirm" />
                <ProtectedRoute component={SignOutPage} path="signout" />
                <ProtectedRoute component={DevicesPage} path="devices" />
                <ProtectedRoute component={AddDevicePage} path="devices/add" roles={['OEM::Admin', 'OEM::ProlitecCommercial::CspTech', 'OEM::ProlitecCommercial::CspAdmin']} />
                <ProtectedRoute component={DevicePage} path="devices/:dsn" />
                <ProtectedRoute component={UsersPage} path="users" roles={['OEM::Admin', 'OEM::ProlitecCommercial::CspAdmin', 'OEM::ProlitecCommercial::CspObserver']} />
                <ProtectedRoute component={CspsPage} path="csps" roles={['OEM::Admin']} />
                <ProtectedRoute component={AddCspPage} path="csps/add" roles={['OEM::Admin']} />
                <ProtectedRoute component={EditCspPage} path="csps/:id" roles={['OEM::Admin']} />
                <ProtectedRoute component={EditDevicePage} path="devices/:dsn/edit" />
                <ProtectedRoute component={EditDeviceSchedulePage} path="devices/:dsn/edit/schedule" />
                <ProtectedRoute component={CopyDevicePage} path="devices/:dsn/copy" />
                <ProtectedRoute component={EditUserPage} path="users/:id" roles={['OEM::Admin', 'OEM::ProlitecCommercial::CspAdmin', 'OEM::ProlitecCommercial::CspObserver']} />
                <ProtectedRoute component={InviteUserPage} path="users/invite" roles={['OEM::Admin', 'OEM::ProlitecCommercial::CspAdmin']} />
                <ProtectedRoute component={AccountPage} path="account" />
                <ProtectedRoute component={AboutPage} path="about" />
                <ProtectedRoute component={HelpPage} path="help" />
                <ProtectedRoute component={ImportPage} path="import" roles={['OEM::Admin']} />
            </Router>
        </React.Fragment>
    )
}

export default RootPage
