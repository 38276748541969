import React, { useState, useContext, useCallback, useEffect } from 'react'
import Prolitec from '../Prolitec'
import { Form, Header, Message } from 'semantic-ui-react'
import { AylaContext } from '../../stores/aylaStore'
import Button from '../../components/buttons/Button'
import { navigate } from '@reach/router'
import { useForm } from '@radbse/hooks'
import ProlitecErrors from '../../components/ProlitecErrors'
import { useDocumentTitle } from '@radbse/hooks'
import PasswordRules from '../../components/users/PasswordRules'

const SetPasswordPage = () => {
    useDocumentTitle('ScenXus | RESET PASSWORD')
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [changed, setChanged] = useState(false)

    const { actions } = useContext(AylaContext)

    const errorMappings = [
        { label: 'New Password', errorField: 'password' },
        { label: 'Confirm New Password', errorField: 'confirm_password' },
        { label: 'Reset Password Token', errorField: 'reset_password_token' },
    ]

    const [fields, form] = useForm({
        fields: [
            { name: 'token', label: 'Token' },
            { name: 'newPassword', label: 'New Password' },
            { name: 'confirmNewPassword', label: 'Confirm New Password' },
        ],
        submit: async ({ token, newPassword, confirmNewPassword }) => {
            try {
                setError(null)
                setLoading(true)
                await actions.resetPassword(token, newPassword, confirmNewPassword)
                setLoading(false)
                setChanged(true)
            } catch (error) {
                setLoading(false)
                setError(JSON.parse(error.message))
            }
        },
    })

    const handleSubmit = useCallback(form.submit, [fields.newPassword, fields.confirmNewPassword])

    useEffect(() => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const token = urlParams.get('token')

        if (token) {
            form.setValue('token', token)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Prolitec>
            {changed && (
                <Form error className={`dark`}>
                    <Header>Set Password</Header>
                    <div>Your password has been changed.</div>
                    <Form.Field>
                        <Button fluid primary onClick={() => navigate('/signin')}>
                            Sign in
                        </Button>
                    </Form.Field>
                </Form>
            )}

            {!changed && (
                <Form error onSubmit={handleSubmit} className={`dark`}>
                    {form.errors ? <Message error list={Object.values(form.errors)} /> : null}
                    <ProlitecErrors error={error} fieldMappings={errorMappings} />

                    <Header>Set Password</Header>
                    <Form.Input {...fields.token} />
                    <Form.Input type="password" {...fields.newPassword} />
                    <Form.Input type="password" {...fields.confirmNewPassword} />
                    <Form.Group>
                        <PasswordRules />
                    </Form.Group>
                    <Form.Field>
                        <Button fluid primary loading={loading}>
                            Set new password
                        </Button>
                    </Form.Field>
                </Form>
            )}
        </Prolitec>
    )
}

export default SetPasswordPage
