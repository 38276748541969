import React from 'react'

const PrintableReport = ({ report }) => {
    return (
        <html>
            <body>
                <div>
                    <h3>Filters</h3>
                    <table>
                        <tbody>
                            {Object.keys(report.filters).map((key, i) => {
                                return (
                                    <tr key={i}>
                                        <td style={{ fontWeight: 'bold' }}>{key}</td>
                                        <td style={{ textTransform: 'capitalize' }}>{report.filters[key]}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div>
                    <h3>Devices</h3>
                    <ol style={{ fontSize: '20px', fontWeight: 'bold' }}>
                        {report.items.map((device, i) => {
                            return (
                                <li style={{ margin: '30px' }} key={i}>
                                    <table>
                                        {Object.keys(device).map((field, ii) => {
                                            return (
                                                <tr key={ii}>
                                                    <td style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>{field}</td>
                                                    <td>{device[field] !== null ? `${device[field]}` : ''}</td>
                                                </tr>
                                            )
                                        })}
                                    </table>
                                </li>
                            )
                        })}
                    </ol>
                </div>
            </body>
        </html>
    )
}

PrintableReport.propTypes = {}

export default PrintableReport
