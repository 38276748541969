import React, { useContext, useCallback, useState, useMemo, useEffect } from 'react'
import { AylaContext } from '../../stores/aylaStore'
import SelectionDevices from '../../components/devices/SelectionDevices'
import Devices from '../../components/devices/Devices'
import styles from './DevicesPage.module.scss'
import { navigate } from '@reach/router'
import PagedEntity from '../PagedEntity'
import { SessionContext } from '@radbse/auth-identity'
import ScenXusModal from '../../components/ScenXusModal'
import { Form } from 'semantic-ui-react'
import { useForm, useAlert } from '@radbse/hooks'
import { useScenXusApi } from '../../stores/scenxusStore'
import { useDocumentTitle } from '@radbse/hooks'
import { useSystemConfigurationOptions, useStateProvinceOptions, useCountryOptions, useFragranceOptions, getStateCountryNames } from '../../hooks/device'

const DevicesPage = ({ location }) => {
    useDocumentTitle('ScenXus | DEVICES')
    const { actions, state } = useContext(AylaContext)
    const { ready, devices } = state
    const { user } = useContext(SessionContext)

    const [selection, setSelection] = useState(false)
    const [selectedDevices, setSelectedDevices] = useState([])
    const [selectedCountry, setSelectedCountry] = useState('')
    const [, setAlert] = useAlert()

    const [scenxusActions, useApi, scenxusState] = useScenXusApi()
    const [, getAllCsps] = useApi(scenxusActions.getAllCsps)

    const [, _generateReport] = useApi(scenxusActions.generateDeviceReport)
    const generateReport = useCallback(async (args) => {
        const result = await _generateReport(args)
        result.response.items.forEach(device => {
            const { stateName, countryName } = getStateCountryNames(device.stateProvince, device.country)
            device.stateProvince = stateName
            device.country = countryName
        })
        return result
    }, [])
    
    const [cspOptions, setCspOptions] = useState(null)

    const canFilterByCSP = useMemo(() => user.role === 'OEM::Admin' || user.role === 'OEM::Observer', [user.role])

    const systemConfigurationOptions = useSystemConfigurationOptions(true)
    const fragranceOptions = useFragranceOptions()
    const stateProvinceOptions = useStateProvinceOptions(selectedCountry, true)
    const countryOptions = useCountryOptions(true)

    useEffect(() => {
        const load = async () => {
            const result = await getAllCsps()
            result.response.unshift({ name: '' })
            setCspOptions(
                result.response.map(csp => {
                    return { key: csp.name, value: csp.name, text: csp.name }
                })
            )
        }

        load()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const loadDevices = useCallback(
        async (page, pageSize, filters, order, orderBy) => {
            actions.loadDevices(page, pageSize, order, orderBy, filters)
        },
        [actions]
    )

    const onSelectionChange = useCallback(
        (device, selected) => {
            if (selected) {
                selectedDevices.push(device)
            } else {
                const unselectedDevice = selectedDevices.find(d => d.key === device.key)
                const indexOfDsn = selectedDevices.indexOf(unselectedDevice)
                selectedDevices.splice(indexOfDsn, 1)
            }

            setSelectedDevices([...selectedDevices])
        },
        [selectedDevices]
    )

    const onFilterChanged = useCallback(
        (field, oldValue, newValue) => {
            if (field === 'country') {
                setSelectedCountry(newValue)
            }
        },
        []
    )

    const _devices = useMemo(() => {
        return (devices.items || []).map(device => {
            const selectedDevice = selectedDevices.find(d => d.key === device.key)
            device.selected = selectedDevices.includes(selectedDevice)
            return device
        })
    }, [devices, selectedDevices])

    const [cspModalOpen, setCspModalOpen] = useState(false)
    const [cspLoading, setCspLoading] = useState(false)

    const setCsp = useCallback(() => {
        setCspModalOpen(true)
    }, [])

    const onSetCspCancel = useCallback(() => {
        setSelectedDevices([])
        setSelection(false)
    }, [])

    const onCspCancel = useCallback(() => {
        onSetCspCancel()
        setCspModalOpen(false)
    }, [onSetCspCancel])

    const actionButtons = useMemo(() => {
        const buttons = []
        if (selection) {
            buttons.push({ iconUrl: '/assets/ic-edit.svg', title: 'Set Details for Selected', onClick: setCsp, disabled: selectedDevices.length === 0 })
            buttons.push({ iconUrl: '/assets/ic-cancel.svg', title: 'Cancel', onClick: onSetCspCancel })
        } else {
            buttons.push({ iconUrl: '/assets/ic-add.svg', title: 'Add Device', onClick: () => navigate('/devices/add'), roles: ['OEM::Admin', 'OEM::ProlitecCommercial::CspTech', 'OEM::ProlitecCommercial::CspAdmin'] })
            buttons.push({ iconUrl: '/assets/ic-edit.svg', title: 'Bulk Edit Details', onClick: () => setSelection(true), roles: ['OEM::Admin'] })
        }
        return buttons
    }, [onSetCspCancel, selectedDevices, selection, setCsp])

    const onSubmit = useCallback(
        async ({ csp }) => {
            setCspLoading(true)
            try {
                for (const device of selectedDevices) {
                    await actions.updateDeviceCsp(device.dsn, csp)
                }
                onCspCancel()
                setAlert({ content: `Successfully updated device details`, color: 'green' })
                actions.loadDevices(1, 5)
            } catch {
                setAlert({ content: 'Failed to update details', error: true, icon: 'exclamation circle' })
            }
            setCspLoading(false)
        },
        [actions, onCspCancel, selectedDevices, setAlert]
    )

    const [fields, form] = useForm({
        fields: [{ name: 'csp', label: 'CSP Name' }],
        submit: onSubmit,
    })

    const filters = useMemo(() => {
        const _filters = []

        if (canFilterByCSP && cspOptions) {
            _filters.push({ field: 'csp', name: 'CSP', options: cspOptions })
        }

        _filters.push({ field: 'dsn', name: 'DSN' })
        _filters.push({ field: 'network', name: 'Network' })
        _filters.push({ field: 'servicearea', name: 'Service Area' })
        _filters.push({ field: 'systemConfiguration', name: 'System Configuration', options: systemConfigurationOptions })
        _filters.push({ field: 'fragrance', name: 'Fragrance', options: fragranceOptions, })
        _filters.push({ field: 'serialnumber', name: 'Serial Number' })
        _filters.push({
            field: 'connectionstatus',
            name: 'Connection Status',
            options: [
                { key: 0, value: null, text: '' },
                { key: 1, value: 'Offline', text: 'Offline' },
                { key: 2, value: 'Online', text: 'Online' },
            ],
        })
        _filters.push({ field: 'customer', name: 'Customer' })
        _filters.push({ field: 'customerId', name: 'Customer ID' })
        _filters.push({ field: 'storeNumber', name: 'Store Number' })
        _filters.push({ field: 'city', name: 'City' })
        _filters.push({ field: 'country', name: 'Country', options: countryOptions })
        _filters.push({ field: 'stateprovince', name: 'State/Province', options: stateProvinceOptions })

        return _filters
    }, [canFilterByCSP, cspOptions, stateProvinceOptions, countryOptions, systemConfigurationOptions, fragranceOptions])

    return (
        <div className={styles.pageContainer}>
            <ScenXusModal
                open={cspModalOpen}
                onClose={() => setCspModalOpen(false)}
                title="Bulk Edit Details"
                buttons={[
                    { iconUrl: '/assets/ic-cancel.svg', title: 'Cancel', onClick: onCspCancel },
                    { iconUrl: '/assets/ic-checkmark.svg', onClick: form.submit, title: 'Save', primary: true, loading: cspLoading },
                ]}
            >
                <Form>
                    <Form.Dropdown {...fields.csp} options={cspOptions} onChange={(_, { value }) => form.setValue('csp', value)} required search scrolling />
                </Form>
            </ScenXusModal>
            {filters && cspOptions && scenxusState.fragrances && (
                <PagedEntity
                    {...devices}
                    items={_devices}
                    listComponent={selection ? SelectionDevices : Devices}
                    ready={ready}
                    name="Devices"
                    onLoad={loadDevices}
                    onItemClick={dsn => navigate(`/devices/${dsn}`)}
                    onSelectionChange={onSelectionChange}
                    inline={selection}
                    filters={filters}
                    actions={actionButtons}
                    location={location}
                    generateReport={generateReport}
                    onFilterChanged={onFilterChanged}
                />
            )}
        </div>
    )
}

export default DevicesPage
