import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Table, Checkbox } from 'semantic-ui-react'
import styles from './Notification.module.css'

const Notification = ({ notification, onRemove, onEdit }) => {
    const _onEdit = useCallback(() => {
        if (onEdit) onEdit(notification)
    }, [notification, onEdit])

    const _onRemove = useCallback(() => {
        if (onRemove) onRemove(notification)
    }, [notification, onRemove])

    return (
        <Table.Row>
            <Table.Cell>{notification.method}</Table.Cell>
            <Table.Cell>{notification.recipient}</Table.Cell>
            <Table.Cell textAlign="center">
                <Checkbox className={styles.centerCheck} checked={notification.connected !== false && notification.connected !== undefined} disabled />
            </Table.Cell>
            <Table.Cell textAlign="center">
                <Checkbox className={styles.centerCheck} checked={notification.disconnected !== false && notification.disconnected !== undefined} disabled />
            </Table.Cell>
            <Table.Cell textAlign="center">
                <Checkbox className={styles.centerCheck} checked={ notification.lowProductLevel !== false && notification.lowProductLevel !== undefined} disabled />
            </Table.Cell>
            <Table.Cell textAlign="center">
                <div className={styles.buttonContainer}>
                    <div className={styles.editIcon} onClick={_onEdit}></div>
                </div>
            </Table.Cell>
            <Table.Cell textAlign="center">
                <div className={styles.buttonContainer}>
                    <div className={styles.removeIcon} onClick={_onRemove}></div>
                </div>
            </Table.Cell>
        </Table.Row>
    )
}

Notification.propTypes = {
    notification: PropTypes.object.isRequired,
    onRemove: PropTypes.func,
    onEdit: PropTypes.func,
}

export default Notification
