import AylaNetworks from 'js-aylasdk'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

export function splitPhoneNumber(phoneNumber) {
    const formatted = formatPhoneNumberIntl(phoneNumber)
    var split = formatted.split(' ')
    const countryCode = split[0].substring(1, split[0].length)
    split.shift()

    return [countryCode, split.join('')]
}

export async function addMetaData(device, key, value) {
    try {
        await device.deleteDatum(key)
    } catch {}

    await device.createDatum(key, value)
}

export async function removeMetaData(device, key) {
    device.datums.forEach(async datum => await device.deleteDatum(datum.key))
}

export async function updateDeviceMetaData(device, serialNumber, cartridge, fragrance, customer, timeZone, serviceArea, latitude, longitude, city, state, country, customerId, storeNumber) {
    await addMetaData(device, 'SerialNumber', serialNumber)
    await addMetaData(device, 'CartName', cartridge)
    await addMetaData(device, 'Fragrance', fragrance)
    await addMetaData(device, 'Customer', customer)
    await addMetaData(device, 'TimeZone', timeZone)
    await addMetaData(device, 'CustomerId', customerId)
    await addMetaData(device, 'StoreNumber', storeNumber)

    await device.updateProductName(serviceArea)
    await updateDeviceLocationMetaData(device, latitude, longitude, city, state, country)
}

export async function updateDeviceLocationMetaData(device, latitude, longitude, city, state, country) {
    if (latitude != null && latitude !== '' && longitude != null && longitude !== '') {
        await device.updateLocation(latitude, longitude)
    }
    await device.updateAddress({
        city,
        state,
        country,
    })
}

export async function updateDeviceCsp(device, csp) {
    let techRoleLabel = device.roleLabels.find(rl => rl.roleName === 'OEM::ProlitecCommercial::CspTech')
    if (techRoleLabel) {
        techRoleLabel.label = { key: 'partner_id', value: csp }
        await AylaNetworks.shared().roleManager.updateRoleLabel(techRoleLabel)
    } else {
        techRoleLabel = { roleName: 'OEM::ProlitecCommercial::CspTech', description: csp, label: { key: 'partner_id', value: csp } }

        await AylaNetworks.shared().roleManager.enableRoleLabelOnDevice(device, techRoleLabel)
        await AylaNetworks.shared().roleManager.associateRoleLabelToDevice(device, techRoleLabel)
    }

    let adminRoleLabel = device.roleLabels.find(rl => rl.roleName === 'OEM::ProlitecCommercial::CspAdmin')
    if (adminRoleLabel) {
        adminRoleLabel.label = { key: 'partner_id', value: csp }
        await AylaNetworks.shared().roleManager.updateRoleLabel(adminRoleLabel)
    } else {
        adminRoleLabel = { roleName: 'OEM::ProlitecCommercial::CspAdmin', description: csp, label: { key: 'partner_id', value: csp } }

        await AylaNetworks.shared().roleManager.enableRoleLabelOnDevice(device, adminRoleLabel)
        await AylaNetworks.shared().roleManager.associateRoleLabelToDevice(device, adminRoleLabel)
    }
}

export async function fetchScenxusData(device, withRoles, withNotifications) {
    if (device.connectionStatus === null) return

    try {
        await device.fetchProperties()
        device.scenxus = {
            ...device.scenxus,
            dsn: device.dsn,
            serviceArea: device.productName,
            applianceLife: (device.propertyMap.get('appliance_life') || {}).value,
            cartridgeLife: (device.propertyMap.get('cartridge_life') || {}).value,
            clock_mode: (device.propertyMap.get('clock_mode_in') || {}).value,
            intensity: (device.propertyMap.get('current_intensity_in') || {}).value,
            currentScheduleRunning: (device.propertyMap.get('current_schedule_running') || {}).value,
            mode: (device.propertyMap.get('current_state_in') || {}).value,
            cycle: (device.propertyMap.get('cycle_in') || {}).value,
            fan: (device.propertyMap.get('fan_in') || {}).value,
            hostMCUVersion: (device.propertyMap.get('host_mcu_version') || {}).value,
            OEMHostVersion: (device.propertyMap.get('oem_host_version') || {}).value,
            pumpLife: (device.propertyMap.get('pump_life') || {}).value,
            scheduleBlob: (device.propertyMap.get('schedule_blob_strin') || {}).value,
            latitude: device.lat,
            longitude: device.lng,
        }

        device.scenxus.schedule = []
        if (device.scenxus.scheduleBlob) {
            const scheduleBlobSplit = device.scenxus.scheduleBlob.split(',')
            const schedulePartLength = 8
            let number = 1
            for (let startIndex = 0; startIndex < scheduleBlobSplit.length; startIndex = startIndex + schedulePartLength) {
                let schedulePart = scheduleBlobSplit.slice(startIndex, startIndex + schedulePartLength)
                device.scenxus.schedule.push({
                    number: number++,
                    stopMinute: parseInt(schedulePart[1]),
                    stopHour: parseInt(schedulePart[2]),
                    intensity: parseInt(schedulePart[3]),
                    startMinute: parseInt(schedulePart[4]),
                    startHour: parseInt(schedulePart[5]),
                    dayOfTheWeek: parseInt(schedulePart[6]),
                    active: parseInt(schedulePart[7]) === 1,
                })
            }
        }

        const startingLength = device.scenxus.schedule.length
        if (startingLength < 21) {
            console.log('filling in missing schedules')
            for (var i = 1; i <= 21 - startingLength; i++) {
                device.scenxus.schedule.push({
                    number: startingLength + i,
                    stopMinute: 0,
                    stopHour: 0,
                    intensity: 1,
                    startMinute: 0,
                    startHour: 0,
                    dayOfTheWeek: 0,
                    active: false,
                })
            }
        }
    } catch (error) {
        console.log(`failed to fetch device properties for ${device.dsn}: ${error}`)
        throw error
    }

    try {
        const datums = await device.fetchDatums()
        datums.forEach(datum => {
            switch (datum.key) {
                case 'CartName':
                    device.scenxus.systemConfiguration = datum.value
                    break
                case 'Customer':
                    device.scenxus.customer = datum.value
                    break
                case 'Fragrance':
                    device.scenxus.fragrance = datum.value
                    break
                case 'Model':
                    device.scenxus.model = datum.value
                    break
                case 'SerialNumber':
                    device.scenxus.serialNumber = datum.value
                    break
                case 'TimeZone':
                    device.scenxus.timeZone = datum.value
                    break
                case 'CustomerId':
                    device.scenxus.customerId = datum.value
                    break
                case 'StoreNumber':
                    device.scenxus.storeNumber = datum.value
                    break
                default:
                    break
            }
        })
    } catch (error) {
        console.log(`failed to fetch device data for ${device.dsn}: ${error}`)
    }

    if (withRoles) {
        try {
            await device.fetchRoleLabels()

            if (device.roleLabels.length > 0) {
                const partnerRole = device.roleLabels.find(role => role.label.key === 'partner_id')
                if (partnerRole) device.scenxus.csp = partnerRole.label.value || ''
            }
        } catch (error) {
            console.log(`failed to fetch device roles for ${device.dsn}: ${error}`)
        }
    }

    if (withNotifications) {
        try {
            const notifications = await device.fetchNotifications()

            console.log('all notifications', notifications)

            for (const notification of notifications) {
                await notification.fetchApplications()
            }

            const notificationsGroupedByType = {
                on_connection_lost: [],
                on_connection_restore: [],
            }

            notifications.forEach(notification => {
                notificationsGroupedByType[notification.notificationType].push(...notification.applications)
            })

            const scenXusNotifications = {}
            for (const notificationType of Object.keys(notificationsGroupedByType)) {
                switch (notificationType) {
                    case 'on_connection_lost':
                        notificationsGroupedByType[notificationType].forEach(app => {
                            const key = `${app.appType}::${app.appType === 'sms' ? `+${app.countryCode} ${app.phoneNumber}` : app.email}`
                            if (!scenXusNotifications[key]) scenXusNotifications[key] = {}
                            scenXusNotifications[key].disconnected = app.id
                        })
                        break
                    case 'on_connection_restore':
                        notificationsGroupedByType[notificationType].forEach(app => {
                            const key = `${app.appType}::${app.appType === 'sms' ? `+${app.countryCode} ${app.phoneNumber}` : app.email}`
                            if (!scenXusNotifications[key]) scenXusNotifications[key] = {}
                            scenXusNotifications[key].connected = app.id
                        })
                        break
                    default:
                        break
                }
            }

            device.scenxus.notifications = Object.keys(scenXusNotifications).map(key => {
                const split = key.split('::')
                return {
                    method: split[0],
                    recipient: split[1],
                    ...scenXusNotifications[key],
                }
            })
        } catch (error) {
            console.log(`failed to fetch device notifications for ${device.dsn}: ${error}`)
        }
    }

    try {
        const address = await device.fetchAddress()
        device.scenxus.street = address.street
        device.scenxus.city = address.city
        device.scenxus.state = address.state
        device.scenxus.country = address.country
        device.scenxus.zip = address.zip
    } catch (error) {
        console.log(`failed to fetch device address for ${device.dsn}: ${error}`)
    }
}

export const systemConfigurations = {
    AQ380: 'AQ300 Series / 500 mL',
    AQ580: 'AQ500 Series / 500 mL',
    AQ880w1250: 'AQ800 Series / 1250 mL',
    AQ880w2500: 'AQ800 Series / 2500 mL',
    AQ1280: 'AQ1200 Series / 1250 mL',
    AQ2500: 'AQ1200 Series / 2500 mL',
}

export const modes = {
    //0: 'None',
    1: 'Off',
    //2: 'Setup',
    3: '24/7',
    4: 'Run',
    //5: 'Set Program',
    //6: 'Options',
}

export const cycles = {
    1: 'Very Short (30 Sec)',
    2: 'Short (2 Min)',
    3: 'Normal (7 Min)',
}

const _intensities = [...Array(51).keys()]
_intensities.splice(0, 1)
export const intensities = [..._intensities]

const _fanSpeeds = [...Array(6).keys()]
export const fanSpeeds = [..._fanSpeeds]

export async function ensureNotificationType(device, type) {
    let notification = device.notifications.find(notification => notification.notificationType === type)
    if (!notification) {
        notification = await device.createNotification(type)
    }
    return notification
}

export async function updateDeviceSchedule(device, scheduleBlobString) {
    await device.updateProperty('schedule_blob_strin', scheduleBlobString)
}

export async function updateDeviceSettings(device, mode, cycle, intensity, fan) {
    await device.updateProperty('current_state_in', mode)
    await device.updateProperty('cycle_in', cycle)
    await device.updateProperty('current_intensity_in', intensity)
    await device.updateProperty('fan_in', fan)
}

export async function updateDeviceNotification(device, method, recipient, connected, disconnected) {
    const RESTORE_TYPE = 'on_connection_restore'
    const LOST_TYPE = 'on_connection_lost'

    debugger;
    if (connected) {
        const connectedNotification = await ensureNotificationType(device, RESTORE_TYPE)
        await connectedNotification.fetchApplications()
        const matchingApplication = connectedNotification.applications.find(
            app => app.id !== connected && app.appType === method.toLowerCase() && ((app.appType === 'sms' && `+${app.countryCode} ${app.phoneNumber}` === recipient) || (app.appType === 'email' && app.email === recipient))
        )

        if (!matchingApplication) {
            if (method === 'sms') {
                console.log(recipient)
                const [countryCode, phoneNumber] = splitPhoneNumber(recipient)
                console.log('countrycode', countryCode)
                console.log('phoneNumber', phoneNumber)
                await connectedNotification.createSMSApplication(countryCode, phoneNumber, `Device ${device.dsn} has connected`)
            } else if (method === 'email') {
                await connectedNotification.createEmailApplication(recipient, `Device ${device.dsn} has connected`)
            }
        }
    } else {
        const matchingNotifications = device.notifications.filter(n => n.notificationType === RESTORE_TYPE)
        for (const matchingNotification of matchingNotifications) {
            await matchingNotification.fetchApplications()
            const matchingApplications = matchingNotification.applications.filter(
                app => app.appType === method.toLowerCase() && ((app.appType === 'sms' && `+${app.countryCode} ${app.phoneNumber}` === recipient) || (app.appType === 'email' && app.email === recipient))
            )

            for (const ma of matchingApplications) {
                await matchingNotification.deleteApplication(ma)
            }
        }
    }

    if (disconnected) {
        const disconnectedNotification = await ensureNotificationType(device, LOST_TYPE)
        await disconnectedNotification.fetchApplications()
        const matchingApplication = disconnectedNotification.applications.find(
            app => app.id !== disconnected && app.appType === method.toLowerCase() && ((app.appType === 'sms' && `+${app.countryCode} ${app.phoneNumber}` === recipient) || (app.appType === 'email' && app.email === recipient))
        )

        if(!matchingApplication) {
            if (method === 'sms') {
                const [countryCode, phoneNumber] = splitPhoneNumber(recipient)
                console.log('countrycode', countryCode)
                console.log('phoneNumber', phoneNumber)
                await disconnectedNotification.createSMSApplication(countryCode, phoneNumber, `Device ${device.dsn} has disconnected`)
            } else if (method === 'email') {
                await disconnectedNotification.createEmailApplication(recipient, `Device ${device.dsn} has disconnected`)
            }
        }
    } else {
        const matchingNotifications = device.notifications.filter(n => n.notificationType === LOST_TYPE)
        for (const matchingNotification of matchingNotifications) {
            await matchingNotification.fetchApplications()
            const matchingApplications = matchingNotification.applications.filter(
                app => app.appType === method.toLowerCase() && ((app.appType === 'sms' && `+${app.countryCode} ${app.phoneNumber}` === recipient) || (app.appType === 'email' && app.email === recipient))
            )

            for (const ma of matchingApplications) {
                await matchingNotification.deleteApplication(ma)
            }
        }
    }
}
