import React, { useContext, useMemo, useState, useEffect } from 'react'
import { SessionContext } from '@radbse/auth-identity'
import { AylaContext } from '../../stores/aylaStore'
import PagedEntity from '../PagedEntity'
import Users from '../../components/users/Users'
import { navigate } from '@reach/router'
import styles from './UsersPage.module.scss'
import { useScenXusApi } from '../../stores/scenxusStore'
import { useDocumentTitle } from '@radbse/hooks'

const UsersPage = ({ location }) => {
    useDocumentTitle('ScenXus | USERS')
    const { actions, state } = useContext(AylaContext)
    const { ready, users } = state
    const session = useContext(SessionContext)

    const isOEMAdmin = useMemo(() => session.user.role === 'OEM::Admin', [session.user.role])
    const canFilterByCSP = useMemo(() => isOEMAdmin || session.user.role === 'OEM::Observer', [isOEMAdmin, session.user.role])

    const canAdd = useMemo(() => session.user.role === 'OEM::Admin' || session.user.role === 'OEM::ProlitecCommercial::CspAdmin', [session.user.role])

    const [scenxusActions, useApi] = useScenXusApi()
    const [, getAllCsps] = useApi(scenxusActions.getAllCsps)
    const [filters, setFilters] = useState(null)
    const [, generateReport] = useApi(scenxusActions.generateUserReport)

    useEffect(() => {
        const load = async () => {
            const _filters = []
            if (canFilterByCSP) {
                const result = await getAllCsps()
                result.response.unshift({ name: '' })
                const cspOptions = result.response.map(csp => {
                    return { key: csp.name, value: csp.name, text: csp.name }
                })
                _filters.push({ field: 'csp', name: 'CSP', options: cspOptions })
            } 

            _filters.push({ field: 'email', name: 'Email' })
            _filters.push({ field: 'firstname', name: 'First Name' })
            _filters.push({ field: 'lastname', name: 'Last Name' })
            _filters.push({ field: 'company', name: 'Company' })
            _filters.push({ field: 'businessRole', name: 'Business Role' })
            _filters.push({ field: 'phone', name: 'Phone' })

            setFilters(_filters)
        }

        load()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={styles.pageContainer}>
            {filters && (
                <PagedEntity
                    {...users}
                    ready={ready}
                    name="Users"
                    onLoad={async (page, pageSize, filters, order, orderBy) => {
                        actions.loadUsers(isOEMAdmin, page, pageSize, filters, order, orderBy)
                    }}
                    listComponent={Users}
                    onItemClick={id => navigate(`/users/${id}`)}
                    filters={filters}
                    actions={canAdd ? [{ iconUrl: '/assets/ic-add.svg', title: 'Invite User', onClick: () => navigate('/users/invite') }] : []}
                    location={location}
                    generateReport={generateReport}
                />
            )}
        </div>
    )
}

export default UsersPage
