/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useMemo } from 'react'
import { useDocumentTitle } from '@radbse/hooks'
import styles from './HelpPage.module.scss'
import Question from './Question'

const HelpPage = props => {
    useDocumentTitle('ScenXus | HELP')

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.section}>
                    <ul className={styles.outsideNoBlobs}>
                        <li>
                            <a href="#help">Help</a>
                        </li>
                        <ul className={styles.noBlobs}>
                            <li>
                                <a href="#getting-started">Getting Started</a>
                            </li>
                            <li>
                                <a href="#connecting-the-device-to-wifi">Connecting the Device to WiFi</a>
                            </li>
                            <li>
                                <a href="#adding-device-to-scenxus-portal">Adding Device to ScenXus Portal</a>
                            </li>
                            <li>
                                <a href="#adjust-settings-and-schedules">Adjusting Settings &amp; Schedules</a>
                            </li>
                        </ul>
                        <li>
                            <a href="#faq">FAQ</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles.innerContainer}>
                <div className={styles.section}>
                    <h1>
                        <a name="help" />
                        Help
                    </h1>
                    <div className={styles.subsection}>
                        <h3>
                            <a name="getting-started" />
                            Getting Started
                        </h3>
                        <ol>
                            <li>Request an account from your admin.</li>
                            <li>
                                Check your email for a confirmation code from admin@scenxus.com. Be sure to check your junk and spam folders.
                                <div className={styles.pic1} />
                            </li>
                            <li>
                                Navigate to <a href="scenxus.com">scenxus.com</a>
                                <div className={styles.pic2} />
                            </li>
                            <li>
                                Click the “confirm account” link and use the code from the email.
                                <div className={styles.pic3} />
                            </li>
                            <li>Reset the temporary password you received from your admin using the “forgot password” link.</li>
                        </ol>
                    </div>
                    <div className={styles.subsection}>
                        <h3>
                            <a name="connecting-the-device-to-wifi" />
                            Connecting the Device to WiFi
                        </h3>
                        <ol>
                            <li>Connect the device to power.  If the appliance is an AQx80, make sure the ScenXus dongle is attached to the appliance.  If the appliance is an AQx90, no additional hardware is required.</li>
                            <li>On the appliance LCD screen, the network icon <div className={styles.pic4} /> will blink.  The access-point icon <div className={styles.pic5} /> will turn solid, indicating the device is in access-point mode, ready for connection.</li>
                            <li>While remaining close to the appliance, navigate on your smartphone to “settings” and “WiFi settings”.</li>
                            <li>Make sure you see both a “ScenXus…” network and the network that will provide internet access to your ScenXus device.</li>
                            <li>
                                <div>Select the network named “ScenXus…”</div>
                                <div className={styles.note}>Note: on Android phones, you may need to then select “Stay connected,” since the ScenXus device doesn’t have internet access yet.</div>
                            </li>
                            <li>
                                <div>Networks available for ScenXus will then be displayed.</div>
                                <div className={styles.note}>Note: This is automatic for most iPhones.  On most Android phones, you may need to select the settings icon and “manage router” or open a web browser and enter the IP address 10.231.227.81 to see the list of networks.</div>
                            </li>
                            <li>Connect to the network that will provide your ScenXus device with internet access.</li>
                            <li>Enter the password for that WiFi network and select “connect”.</li>
                            <li>Wait as your smart phone disconnects from the ScenXus device, the ScenXus device connects to the local WiFi, and the local WiFi connects to the ScenXus cloud via the internet.  When complete, you’ll see the message, “connection complete”.</li>
                            <li>
                                The network icon <div className={styles.pic4} /> should turn solid and the WiFi signal strength indicator <div className={styles.pic6} /> should appear.
                                <div className={styles.imageGroup}>
                                    <div className={styles.pic7} />
                                    <div className={styles.pic8} />
                                    <div className={styles.pic9} />
                                    <div className={styles.pic10} />
                                </div>
                            </li>
                        </ol>
                    </div>
                    <div className={styles.subsection}>
                        <h3>
                            <a name="adding-device-to-scenxus-portal" />
                            Adding Device to ScenXus Portal
                        </h3>
                        <ol>
                            <li>Sign in using your email and password.</li>
                            <li>
                                From the “devices” page, click the “add device” button in the upper right.
                                <div className={styles.pic11} />
                            </li>
                            <li>
                                <div>Enter the appropriate device information.  ScenXus DSN, as well as the appliance model number and serial number, can be found on appliance labels.  Fragrance type can be found on the cartridge label.</div>
                                <div>Enter location and time zone details of the device.</div>
                                <div className={styles.note}>Note: do not use “Detect Location” unless the computer you are using is near the device being added.</div>
                                <div className={styles.pic12} />
                            </li>
                            <li>Click “Add Device” and confirm it was added successfully.</li>
                        </ol>
                    </div>
                    <div className={styles.subsection}>
                        <h3>
                            <a name="adjust-settings-and-schedules" />
                            Adjusting Settings &amp; Schedules
                        </h3>
                        <ol>
                            <li>From the “devices” page, search for the appliance.</li>
                            <li>
                                Click anywhere on the row to bring up the device details page.
                                <div className={styles.pic13} />
                            </li>
                            <li>
                                Adjust the settings and options in the top left box by clicking the edit icon.
                                <div className={styles.pic14} />
                            </li>
                            <li>
                                Add schedules using the “edit schedules” icon.  Edit each desired schedule individually.  Be sure to select “enable” to turn on each schedule.
                                <div className={styles.pic15} />
                            </li>
                            <li>
                                Add connectivity and fragrance alerts using the “add notification” icon.
                                <div className={styles.pic16}></div>
                            </li>
                        </ol>
                    </div>
                </div>

                <div className={styles.section}>
                    <h1>
                        <a name="faq" />
                        FAQ
                    </h1>

                    <Question q="Why can’t I find ScenXus on the app store?" a="ScenXus is a web application, so that it can be used from any device that has internet access: desktop, laptop, tablet, phone, etc." />
                    <Question q="Can I use the ScenXus web application from my smart watch?" a="No." />
                    <Question
                        q="How do I get access to the ScenXus web application?"
                        a="System access is reserved for Prolitec CSPs that purchase ScenXus hardware. Those that do will need to name an internal administrator. This admin account will be able to set up subordinate technician accounts, as well as control and monitor all CSP owned devices."
                    />
                    <Question
                        q="How can I get my account set up?"
                        a={
                            <div>
                                Your company admin can give you technician access. Account setup requires basic information including name, location, role, and most importantly: email address. The email address is your username and should
                                be your business email on the domain of a Prolitec CSP. Upon setup, a confirmation code will be emailed to the new user from admin@scenxux.com. The new user will need to confirm the account by clicking the
                                included hyperlink before they can log in at <a href="scenxus.com">scenxus.com</a>.
                            </div>
                        }
                    />
                    <Question
                        q="What do I see when I sign into the web application?"
                        a="Technician users will be directed to the devices page on sign in. To reduce the load time, only five devices will initially be displayed. You can choose to see up to 50 devices at once or search for devices by ScenXus device serial number (DSN), network SSID, service area, system configuration, Prolitec appliance serial number, connection status, customer, or location."
                    />
                    <Question
                        q="How can I monitor the appliances in my service area?"
                        a="The list of appliances will display all of the searchable appliance identifiers, as well as the connection, fragrance, and current operating statuses. Clicking on an individual appliance will bring up a details page, which shows the scheduled programs and notifications."
                    />
                    <Question
                        q="Can I control the appliances remotely?"
                        a="From the appliance details page, you can edit the identifying information in the top left block, by clicking on the edit icon (pencil image). You can edit the operating parameters: mode, 24/7 intensity, off-cycle or fan speed from the top right block by clicking the edit icon."
                    />
                    <Question
                        q="Will the system tell me if an appliance is low on fragrance or offline?"
                        a="From the appliance details page, click on “add notification” to set alerts by appliance. You can use either an email address or phone number to receive alerts when the appliance goes offline, comes back online, or when the fragrance remaining falls below 10%."
                    />
                    <Question
                        q="When I install a new appliance, how do I add it to the web application?"
                        a="From the main devices page, click the “add device” link in the upper right. You will need to provide the Prolitec appliance serial number and the ScenXus DSN, as well as the, customer, and location within the customer site to enable the appliance search functionality. Entering the fragrance and configuration (appliance/cartridge) correctly will enable the system to gauge the remaining fragrance by actual operating time."
                    />
                    <Question
                        q="Do I have to enter all of this information every time?"
                        a="If you are trying to set up a new appliance with many of the same parameters of a previously installed appliance, go to the details of the exiting install and “copy device details” to the new appliance to save time."
                    />
                    <Question
                        q="Do I have to add each appliance to the portal while at the customer site?"
                        a="No, you can fill in the details later, or have someone else do it remotely, but you do have to be close by to connect the appliance to the internet. Your phone connects to the appliance via access point (AP) mode; meaning that it treats the appliance’s SSID like any other Wi-Fi network. The appliance doesn’t yet know how to get to the Internet, so it simply serves up a web page asking to be taught the credentials. You use your phone as an input device to share the password."
                    />
                    <Question
                        q="Can I use the web application and the appliance controls simultaneously?"
                        a='You can, but it is best to choose one input method, and the web application is easier on the thumbs. If you give the appliance conflicting inputs, the outcome can be unpredictable. It all depends on whether the appliance or web application wins the "race" to report their information to the cloud server.'
                    />
                    <Question
                        q="How do I reset the fragrance level?"
                        a="After you change the cartridge, you simply press and hold the mode (⮕) and select (✔) buttons for about five seconds. The display backlight will blink twice to acknowledge the reset and the fragrance remaining on the web application will show 100%."
                    />
                    <Question q="Can I reset the fragrance level from the web application?" a="No. This is to prevent erroneous resets, because you also can’t change the cartridge remotely." />
                    <Question
                        q="Do I have to set the appliance clock?"
                        a="You do not. And you don’t need to worry about daylight savings time either. If you tell the device where it is in the world, it will set its own clock from the internet time."
                    />
                    <Question
                        q="What Wi-Fi security protocols do ScenXus devices support?"
                        a="ScenXus can be used on Wireless Protected Access Version 2 (WPA2) networks with Advanced Encryption Standard (AES). By using the MAC address in the SSID, you can also use Pre-shared Key (PSK) and you can allow the appliance on networks with MAC address filtering. After connecting to the network, you can hide the SSID without affecting the appliance’s connection."
                    />
                    <Question
                        q="How do I reset the ScenXus device WiFi in order to connect to a different local WiFi network?"
                        a="Press and hold the select button (✔), then press the reset button.  This will make the device forget the current WiFi SSID and key.  The device can then be connected to a different local WiFi network."
                    />
                </div>
            </div>
        </div>
    )
}

export default HelpPage
