import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Header } from 'semantic-ui-react'
import styles from './EditCspPage.module.scss'
import CspForm from '../../components/csps/CspForm'
import { navigate } from '@reach/router'
import LoadingDimmer from '../../components/LoadingDimmer'
import { useScenXusApi } from '../../stores/scenxusStore'
import { useAlert } from '@radbse/hooks'
import ScenXusModal from '../../components/ScenXusModal'
import TextOnlyButton from '../../components/buttons/TextOnlyButton'
import { useDocumentTitle } from '@radbse/hooks'

const EditCspPage = ({ id }) => {
    useDocumentTitle('ScenXus | EDIT CSP')
    const [actions, useApi] = useScenXusApi()
    const [loading, getCsp] = useApi(actions.getCsp)
    const [, updateCsp] = useApi(actions.updateCsp)
    const [, setAlert] = useAlert()
    const [removeOpen, setRemoveOpen] = useState(false)
    const [removing, removeCsp] = useApi(actions.removeCsp)
    const [csp, setCsp] = useState(null)

    useEffect(() => {
        const load = async () => {
            try {
                const result = await getCsp(id)
                setCsp(result.response)
            } catch {
                setAlert({ content: 'Failed to load CSP', error: true, icon: 'exclamation circle' })
            }
        }

        load()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = useCallback(
        async ({ name }) => {
            try {
                name = name.trim()
                await updateCsp({ id, name })
                setAlert({ content: 'Successfully updated CSP', color: 'green' })
                navigate(`/csps`)
            } catch {
                setAlert({ content: 'Failed to update CSP', error: true, icon: 'exclamation circle' })
            }
        },
        [id, setAlert, updateCsp]
    )

    const onRemove = useCallback(async () => {
        try {
            await removeCsp(id)
            setRemoveOpen(false)
            navigate('/csps')
        } catch {
            setRemoveOpen(false)
            setAlert({ content: 'Failed to remove CSP', error: true, icon: 'exclamation circle' })
        }
    }, [removeCsp, id, setAlert])

    return (
        <div className={styles.secondaryPageContainer}>
            <Header>Edit CSP</Header>
            <LoadingDimmer ready={true} loading={loading} loadingText={`Loading CSP`} />
            {csp && (
                <CspForm
                    cancelButton={{ iconUrl: '/assets/ic-cancel.svg', title: 'Cancel', onClick: () => navigate('/csps') }}
                    submitButton={{ iconUrl: '/assets/ic-checkmark.svg', title: 'Save Changes', loading }}
                    onSubmit={onSubmit}
                    csp={csp}
                />
            )}
            {csp && csp.isFree && (
                <ScenXusModal
                    title="Remove CSP"
                    trigger={<TextOnlyButton text="Remove CSP" />}
                    buttons={[
                        { iconUrl: '/assets/ic-cancel.svg', title: 'Cancel', onClick: () => setRemoveOpen(false) },
                        { iconUrl: '/assets/ic-checkmark.svg', title: 'Remove CSP', primary: true, onClick: onRemove, loading: removing },
                    ]}
                    open={removeOpen}
                    onOpen={() => setRemoveOpen(true)}
                    onClose={() => setRemoveOpen(false)}
                >
                    <p>Are you sure you want to remove this CSP?</p>
                    <p>This action cannot be undone.</p>
                </ScenXusModal>
            )}
        </div>
    )
}

EditCspPage.propTypes = {
    id: PropTypes.string.isRequired,
}

export default EditCspPage
