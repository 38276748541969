import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import User from './User'
import SortableTable from '../SortableTable'
import { SessionContext } from '@radbse/auth-identity'

const Users = ({ items, onClick, order, orderBy, onOrderChange }) => {
    const { user } = useContext(SessionContext)
    const isOEM = useMemo(() => user.role === 'OEM::Admin' || user.role === 'OEM::Observer', [user])

    return (
        <SortableTable order={order} orderBy={orderBy || 'lastname' } onOrderChange={onOrderChange} clickable>
            {(sorted, onColumnClick) => (
                <React.Fragment>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={onColumnClick('firstname')} sorted={sorted('firstname')}>
                                First
                            </Table.HeaderCell>
                            <Table.HeaderCell onClick={onColumnClick('lastname')} sorted={sorted('lastname')}>
                                Last
                            </Table.HeaderCell>
                            <Table.HeaderCell onClick={onColumnClick('email')} sorted={sorted('email')}>
                                Email
                            </Table.HeaderCell>
                            <Table.HeaderCell onClick={onColumnClick('company')} sorted={sorted('company')}>
                                Company
                            </Table.HeaderCell>
                            <Table.HeaderCell onClick={onColumnClick('businessRole')} sorted={sorted('businessRole')}>
                                Business Role
                            </Table.HeaderCell>
                            <Table.HeaderCell onClick={onColumnClick('phone')} sorted={sorted('phone')}>
                                Phone
                            </Table.HeaderCell>
                            {isOEM && (
                                <Table.HeaderCell onClick={onColumnClick('csp')} sorted={sorted('csp')}>
                                    CSP Name
                                </Table.HeaderCell>
                            )}
                            <Table.HeaderCell onClick={onColumnClick('role')} sorted={sorted('role')}>
                                Role
                            </Table.HeaderCell>
                            <Table.HeaderCell onClick={onColumnClick('confirmed')} sorted={sorted('confirmed')} textAlign="center">
                                Confirmed
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {items.map((item, i) => {
                            return <User key={i} user={item} onClick={onClick} />
                        })}
                    </Table.Body>
                </React.Fragment>
            )}
        </SortableTable>
    )
}

Users.propTypes = {
    items: PropTypes.array.isRequired,
    onClick: PropTypes.func,
}

export default Users
