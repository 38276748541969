import React from 'react'
import PropTypes from 'prop-types'
import styles from './IconButton.module.scss'

const IconButton = ({ iconUrl, onClick, children }) => {
    return (
        <div className={styles.outerContainer}>
            <div className={styles.container} onClick={onClick}>
                {children}
                <div className={styles.icon} style={{ backgroundImage: `url(${iconUrl})` }}></div>
            </div>
        </div>
    )
}

IconButton.propTypes = {
    iconUrl: PropTypes.string.isRequired,
    onClick: PropTypes.func,
}

export default IconButton
