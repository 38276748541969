import React from 'react';
import PropTypes from 'prop-types';
import { useDocumentTitle } from '@radbse/hooks'

const AboutPage = props => {
    useDocumentTitle('ScenXus | ABOUT')
    return (
        <div>
            About Page
        </div>
    );
};

AboutPage.propTypes = {
    
};

export default AboutPage;