import React, { useContext, useEffect, useMemo, useCallback, useState } from 'react'
import { AylaContext } from '../../stores/aylaStore'
import { Header, Divider } from 'semantic-ui-react'
import styles from './DevicePage.module.scss'
import PropertyList from '../../components/devices/PropertyList'
import PropertyListItem from '../../components/devices/PropertyListItem'
import PropertyListGroup from '../../components/devices/PropertyListGroup'
import ButtonGroup from '../../components/buttons/ButtonGroup'
import { navigate } from '@reach/router'
import { useAlert } from '@radbse/hooks'
import Notifications from '../../components/devices/Notifications'
import LoadingDimmer from '../../components/LoadingDimmer'
import EditDeviceSettingsModal from '../../components/devices/EditDeviceSettingsModal'
import NotificationModal from '../../components/devices/NotificationModal'
import Schedule from '../../components/devices/Schedule'
import Intensity from '../../components/devices/Intensity'
import FragranceRemaining from '../../components/devices/FragranceRemaining'
import { SessionContext } from '@radbse/auth-identity'
import { useDocumentTitle } from '@radbse/hooks'
import { useMode, useCycle, useFragrancePercentRemaining, useStateCountryNames } from '../../hooks/device'
import { systemConfigurations } from '../../utilities/deviceHelpers'
import { formatPhoneNumberIntl } from 'react-phone-number-input'

const DevicePage = ({ dsn }) => {
    useDocumentTitle('ScenXus | DEVICE')
    const [, setAlert] = useAlert()
    const { actions, state } = useContext(AylaContext)
    const { ready } = state
    const { user } = useContext(SessionContext)

    const [notificationLoading, setNotificationLoading] = useState(false)

    const [notifications, setNotifications] = useState([])

    const isOEM = useMemo(() => user.role === 'OEM::Admin' || user.role === 'OEM::Observer', [user])

    const canEdit = useMemo(() => user.role === 'OEM::Admin' || user.role === 'OEM::ProlitecCommercial::CspAdmin' || user.role === 'OEM::ProlitecCommercial::CspTech', [user])

    const [device, setDevice] = useState(null)
    const [loading, setLoading] = useState(false)

    const [settingsOpen, setSettingsOpen] = useState(false)
    const [settingsLoading, setSettingsLoading] = useState(false)

    const [notificationOpen, setNotificationOpen] = useState(false)
    const [notification, setNotification] = useState(null)

    useEffect(() => {
        if (state.ready) {
            async function load() {
                setLoading(true)
                const device = await actions.loadDevice(dsn)
                setDevice(device)
                setLoading(false)
            }

            load()
        }
    }, [state.ready, dsn]) // eslint-disable-line react-hooks/exhaustive-deps

    const getNotifcations = useCallback(async () => {
        const properties = device.getProperties()
        const fragranceRemainingProperty = properties.find(p => p.name === 'fragrance_remaining')
        const allTriggerApps = []
        if (fragranceRemainingProperty) {
            const triggers = await fragranceRemainingProperty.fetchTriggers()
            triggers.forEach(trigger => {
                if (trigger.triggerApps) {
                    trigger.triggerApps.forEach(triggerApp => {
                        allTriggerApps.push(triggerApp)
                    })
                }
            })
        }

        return allTriggerApps.map(triggerApp => {
            return {
                id: triggerApp.key,
                type: 'lowProductLevel',
                method: triggerApp.name,
                recipient: triggerApp.param1 + (triggerApp.param2 ? ` ${triggerApp.param2}` : ''),
            }
        })
    }, [device])

    useEffect(() => {
        if (device) {
            async function loadNotifications() {
                setNotifications(await getNotifcations())
            }

            loadNotifications()
        }
    }, [device, getNotifcations])

    const fragrancePercentRemaining = useFragrancePercentRemaining(device)
    const { stateName, countryName } = useStateCountryNames(device)
    const [mode, modeOn] = useMode(device)
    const cycle = useCycle(device)

    const onSettingsSave = useCallback(
        async ({ mode, cycle, intensity, fan }) => {
            try {
                setSettingsLoading(true)
                await actions.updateDeviceSettings(dsn, mode, cycle, intensity, fan)
                setSettingsLoading(false)
                setSettingsOpen(false)
                setLoading(true)

                setAlert({ error: true, content: `Successfully updated device settings`, color: 'green' })

                const device = await actions.loadDevice(dsn)
                setDevice(device)
                setLoading(false)
            } catch (error) {
                setSettingsOpen(false)
                setSettingsLoading(false)
                setLoading(false)
                setAlert({ error: true, content: `Failed to save device settings. ${error.message}`, icon: 'exclamation circle' })
            }
        },
        [actions, dsn, setAlert]
    )

    const onNotificationSave = useCallback(
        async ({ method, recipient, connected, disconnected, lowProductLevel }) => {
            setNotificationLoading(true)
            try {
                if (notification) {
                    if (notification.connected && connected) connected = notification.connected
                    if (notification.disconnected && disconnected) disconnected = notification.disconnected
                    if (notification.lowProductLevel && lowProductLevel) lowProductLevel = notification.lowProductLevel
                }

                await actions.updateDeviceNotification(dsn, method, recipient, connected, disconnected)

                if (lowProductLevel === true) {
                    let param1 = ''
                    let param2 = null

                    if (method === 'sms') {
                        recipient = formatPhoneNumberIntl(recipient)
                        recipient = recipient.substring(1, recipient.length)

                        const parts = recipient.split(' ')

                        param1 = parts[0].replace('+', '')
                        param2 = ''
                        for (let i = 1; i < parts.length; i++) {
                            param2 += parts[i]
                        }
                    } else {
                        param1 = recipient
                    }

                    const properties = device.getProperties()
                    const fragranceRemainingProperty = properties.find(p => p.name === 'fragrance_remaining')
                    if (fragranceRemainingProperty) {
                        fragranceRemainingProperty.createTriggerApp('compare_absolute', '<', '20', method, 0, param1, param2, `${dsn} Product Level is below 20%`, null)
                    }
                } else if (lowProductLevel === false && notification && notification.lowProductLevel) {
                    const properties = device.getProperties()
                    const fragranceRemainingProperty = properties.find(p => p.name === 'fragrance_remaining')
                    fragranceRemainingProperty.deleteTriggerApp(notification.lowProductLevel)
                }

                setAlert({ error: true, content: `Successfully updated notification`, color: 'green' })

                const _device = await actions.loadDevice(dsn)
                setDevice(_device)
                setNotificationOpen(false)
            } catch (error) {
                setAlert({ content: 'Failed to save notification: ' + error.message, error: true, icon: 'exclamation circle' })
            }
            setNotificationLoading(false)
        },
        [actions, device, dsn, notification, setAlert]
    )

    const onAddNotification = useCallback(() => {
        setNotification(null)
        setNotificationOpen(true)
    }, [])

    const onRemoveNotification = useCallback(
        async notification => {
            try {
                await actions.removeDeviceNotification(dsn, notification)

                if (notification.lowProductLevel) {
                    const properties = device.getProperties()
                    const fragranceRemainingProperty = properties.find(p => p.name === 'fragrance_remaining')
                    fragranceRemainingProperty.deleteTriggerApp(notification.lowProductLevel)
                }

                setAlert({ error: true, content: `Successfully removed notification`, color: 'green' })

                const _device = await actions.loadDevice(dsn)
                setDevice(_device)
            } catch (error) {
                setAlert({ content: 'Failed to remove notification: ' + error.message, error: true, icon: 'exclamation circle' })
            }
        },
        [actions, device, dsn, setAlert]
    )

    const onEditNotification = useCallback(notification => {
        setNotification(notification)
        setNotificationOpen(true)
    }, [])

    const allNotifications = useMemo(() => {
        const all = []

        notifications.forEach(noti => {
            const found = all.find(a => a.method === noti.method && a.recipient === noti.recipient)
            if (found) {
                found[noti.type] = noti.id
            } else {
                all.push({
                    method: noti.method,
                    recipient: `${noti.method === 'sms' ? '+' : ''}${noti.recipient}`,
                    [noti.type]: noti.id,
                })
            }
        })

        if (device && device.scenxus && device.scenxus.notifications) {
            device.scenxus.notifications.forEach(noti => {
                const found = all.find(a => a.method === noti.method && a.recipient === noti.recipient)
                if (found) {
                    found.connected = noti.connected
                    found.disconnected = noti.disconnected
                } else {
                    all.push(noti)
                }
            })
        }

        return all
    }, [device, notifications])

    const resetFragranceLevel = useCallback(async () => {
        try {
            await actions.resetFragranceLevel(dsn)
            setAlert({ content: 'Updated Fragrance Level' })
        } catch (error) {
            console.log(error)
            setAlert({ content: 'Failed to reset fragrance level', error: true, icon: 'exclamation circle' })
        }
    }, [actions, dsn, setAlert])

    const headerButtons = useMemo(
        () =>
            canEdit
                ? [
                      { iconUrl: '/assets/ic-back.svg', onClick: () => navigate('/devices'), title: 'Back' },
                      { iconUrl: '/assets/ic-copy.svg', onClick: () => navigate(`/devices/${dsn}/copy`), title: 'Copy Device Details' },
                      { iconUrl: '/assets/reset.svg', onClick: resetFragranceLevel, title: 'Reset Fragrance Level' },
                  ]
                : [],
        [canEdit, dsn, resetFragranceLevel]
    )

    return (
        <div className={styles.pageContainer}>
            <LoadingDimmer ready={ready} loading={loading} loadingText={`Loading Device - ${dsn}`} />

            {ready && device && device.scenxus && (
                <div>
                    <EditDeviceSettingsModal open={settingsOpen} onClose={() => setSettingsOpen(false)} onSave={onSettingsSave} settings={device.scenxus} loading={settingsLoading} />
                    <NotificationModal open={notificationOpen} onClose={() => setNotificationOpen(false)} onSave={onNotificationSave} notification={notification} loading={notificationLoading} />
                    <div>
                        <div className={styles.pageHeader}>
                            <Header className={styles.pageHeaderTitle}>Device Details</Header>
                            <div className={styles.pageHeaderButtons}>
                                <ButtonGroup buttons={headerButtons} horizontal />
                            </div>
                        </div>
                    </div>
                    <div className={styles.detailsContainer}>
                        <div className={styles.demographics}>
                            <PropertyListGroup onEdit={canEdit ? () => navigate(`/devices/${dsn}/edit`) : null}>
                                <PropertyList title="Name">
                                    {isOEM && <PropertyListItem title="CSP">{device.scenxus.csp}</PropertyListItem>}
                                    <PropertyListItem title="Customer">{device.scenxus.customer}</PropertyListItem>
                                    {device.scenxus.customerId && <PropertyListItem title="Customer ID">{device.scenxus.customerId}</PropertyListItem>}
                                    {device.scenxus.storeNumber && <PropertyListItem title="Store Number">{device.scenxus.storeNumber}</PropertyListItem>}
                                    <PropertyListItem title="Service Area">{device.scenxus.serviceArea}</PropertyListItem>
                                </PropertyList>
                                <PropertyList title="Id">
                                    <PropertyListItem title="Serial #">{device.scenxus.serialNumber}</PropertyListItem>
                                    <PropertyListItem title="DSN">{device.dsn}</PropertyListItem>
                                    <PropertyListItem title="Network">
                                        {device.lanEnabled && <span>LAN</span>}
                                        {!device.lanEnabled && <span>{device.ssid}</span>}
                                    </PropertyListItem>
                                    <PropertyListItem title="System Configuration">{systemConfigurations[device.scenxus.systemConfiguration]}</PropertyListItem>
                                    <PropertyListItem title="Fragrance">{device.scenxus.fragrance}</PropertyListItem>
                                </PropertyList>
                                <PropertyList title="Location">
                                    <PropertyListItem title="Country">{countryName}</PropertyListItem>
                                    <PropertyListItem title="City">{device.scenxus.city}</PropertyListItem>
                                    <PropertyListItem title="State">{stateName}</PropertyListItem>
                                    <PropertyListItem title="Time Zone">{device.scenxus.timeZone}</PropertyListItem>
                                    <PropertyListItem title="Latitude">{device.lat}</PropertyListItem>
                                    <PropertyListItem title="Longitude">{device.lng}</PropertyListItem>
                                </PropertyList>
                            </PropertyListGroup>
                        </div>
                        <div className={styles.product}>
                            <PropertyListGroup onEdit={canEdit ? () => setSettingsOpen(true) : null}>
                                <PropertyList title="Product">
                                    <PropertyListItem title="Mode">
                                        <div className="ui input">
                                            <div className={styles.row}>
                                                <div className={styles[modeOn ? 'on' : 'off']} />
                                                <div>{mode}</div>
                                            </div>
                                        </div>
                                    </PropertyListItem>
                                    <PropertyListItem title="Intensity">
                                        <div className="ui input">
                                            <Intensity device={device} />
                                        </div>
                                    </PropertyListItem>
                                    <PropertyListItem title="Off-Cycle">
                                        <div className="ui input">
                                            <div className={styles.row}>
                                                <div className={styles[`offCycle-${device.scenxus.cycle}`]} />
                                                <div>{cycle}</div>
                                            </div>
                                        </div>
                                    </PropertyListItem>
                                    <PropertyListItem title="Fan Speed">
                                        {device.scenxus && device.scenxus.fan > 0 ? (
                                            <div className="ui input">
                                                <div className={styles.row}>
                                                    <div className={styles[`fan-${device.scenxus.fan}`]} />
                                                    <div>{device.scenxus.fan}</div>
                                                </div>
                                            </div>
                                        ) : device.scenxus ? (
                                            'Off'
                                        ) : null}
                                    </PropertyListItem>
                                    <Divider />
                                    <PropertyListItem title="Status">
                                        <div className="ui input">
                                            <div>{device.connectionStatus === 'Online' ? <div className={styles.on} /> : <div className={styles.off} />}</div>
                                            <div>{device.connectionStatus}</div>
                                        </div>
                                    </PropertyListItem>
                                    <PropertyListItem title="Fragrance Remaining">
                                        <div className="ui input">
                                            <FragranceRemaining fragranceRemaining={fragrancePercentRemaining} />
                                        </div>
                                    </PropertyListItem>
                                </PropertyList>
                            </PropertyListGroup>
                        </div>
                    </div>
                    <div className={styles.schedule}>
                        <Schedule title="Schedule" buttons={canEdit ? [{ iconUrl: '/assets/ic-edit.svg', onClick: () => navigate(`/devices/${dsn}/edit/schedule`), title: 'Edit Schedule' }] : []} schedule={device.scenxus.schedule} />
                    </div>
                    <div className={styles.notifications}>
                        <div className={styles.pageHeader}>
                            <Header as="h4" className={styles.header}>
                                Notifications
                            </Header>
                            <div className={styles.pageHeaderButtons}>
                                <ButtonGroup buttons={[{ iconUrl: '/assets/ic-add.svg', onClick: onAddNotification, title: 'Add Notification' }]} horizontal />
                            </div>
                        </div>
                        <Notifications notifications={allNotifications} onRemove={onRemoveNotification} onEdit={onEditNotification} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default DevicePage
