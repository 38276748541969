import React from 'react'
import PropTypes from 'prop-types'
import styles from './PropertyListGroup.module.css'
import { Segment } from 'semantic-ui-react'

const PropertyListGroup = ({ className, children, onEdit }) => {
    return (
        <Segment placeholder className={`${styles.container} ${className}`}>
            {onEdit && <div className={styles.editIcon} onClick={onEdit} />}
            {children}
        </Segment>
    )
}

PropertyListGroup.propTypes = {
    children: PropTypes.any.isRequired,
    onEdit: PropTypes.func,
}

export default PropertyListGroup
