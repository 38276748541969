import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import CountIconButton from '../buttons/CountIconButton'
import { navigate } from '@reach/router'
import IconButton from '../buttons/IconButton'

const Csp = ({ csp }) => {
    return (
        <Table.Row>
            <Table.Cell>{csp.name}</Table.Cell>
            <Table.Cell>
                <CountIconButton count={csp.deviceCount} iconUrl="/assets/ic-devices-off.svg" onClick={() => navigate(`/devices?csp=${csp.name}`)} />
            </Table.Cell>
            <Table.Cell>
                <CountIconButton count={csp.userCount} iconUrl="/assets/ic-users-off.svg" onClick={() => navigate(`/users?csp=${csp.name}`)} />
            </Table.Cell>
            <Table.Cell>
                {csp.isFree &&
                    <IconButton iconUrl="/assets/ic-edit-dark.svg" onClick={() => navigate(`/csps/${csp.id}`)} />
                }
                
            </Table.Cell>
        </Table.Row>
    )
}

Csp.propTypes = {
    csp: PropTypes.object.isRequired,
}

export default Csp
