import React from 'react';
import PropTypes from 'prop-types';
import styles from './Question.module.scss';

const Question = ({q, a}) => {
    return (
        <div className={styles.container}>
            <div className={styles.q}>{q}</div>
            <div className={styles.a}>{a}</div>
        </div>
    );
};

Question.propTypes = {
    q: PropTypes.string.isRequired,
    a: PropTypes.string.isRequired
};

export default Question;