import React from 'react'
import { ApiProvider, useCustomApi, apiGet, apiPost, apiPut, apiDelete } from '@radbse/auth-identity'

const SCENXUS__GET_CSPS_REQUEST = 'SCENXUS__GET_CSPS_REQUEST'
const SCENXUS__GET_CSPS_SUCCESS = 'SCENXUS__GET_CSPS_SUCCESS'
const SCENXUS__GET_CSPS_FAILURE = 'SCENXUS__GET_CSPS_FAILURE'

const SCENXUS__ADD_CSP_REQUEST = 'SCENXUS__ADD_CSP_REQUEST'
const SCENXUS__ADD_CSP_SUCCESS = 'SCENXUS__ADD_CSP_SUCCESS'
const SCENXUS__ADD_CSP_FAILURE = 'SCENXUS__ADD_CSP_FAILURE'

const SCENXUS__UPDATE_CSP_REQUEST = 'SCENXUS__UPDATE_CSP_REQUEST'
const SCENXUS__UPDATE_CSP_SUCCESS = 'SCENXUS__UPDATE_CSP_SUCCESS'
const SCENXUS__UPDATE_CSP_FAILURE = 'SCENXUS__UPDATE_CSP_FAILURE'

const SCENXUS__GET_CSP_REQUEST = 'SCENXUS__GET_CSP_REQUEST'
const SCENXUS__GET_CSP_SUCCESS = 'SCENXUS__GET_CSP_SUCCESS'
const SCENXUS__GET_CSP_FAILURE = 'SCENXUS__GET_CSP_FAILURE'

const SCENXUS__REMOVE_CSP_REQUEST = 'SCENXUS__REMOVE_CSP_REQUEST'
const SCENXUS__REMOVE_CSP_SUCCESS = 'SCENXUS__REMOVE_CSP_SUCCESS'
const SCENXUS__REMOVE_CSP_FAILURE = 'SCENXUS__REMOVE_CSP_FAILURE'

const SCENXUS__GET_ALL_CSPS_REQUEST = 'SCENXUS__GET_ALL_CSPS_REQUEST'
const SCENXUS__GET_ALL_CSPS_SUCCESS = 'SCENXUS__GET_ALL_CSPS_SUCCESS'
const SCENXUS__GET_ALL_CSPS_FAILURE = 'SCENXUS__GET_ALL_CSPS_FAILURE'

const SCENXUS__GENERATE_DEVICE_REPORT_REQUEST = 'SCENXUS__GENERATE_DEVICE_REPORT_REQUEST'
const SCENXUS__GENERATE_DEVICE_REPORT_SUCCESS = 'SCENXUS__GENERATE_DEVICE_REPORT_SUCCESS'
const SCENXUS__GENERATE_DEVICE_REPORT_FAILURE = 'SCENXUS__GENERATE_DEVICE_REPORT_FAILURE'

const SCENXUS__GENERATE_USER_REPORT_REQUEST = 'SCENXUS__GENERATE_USER_REPORT_REQUEST'
const SCENXUS__GENERATE_USER_REPORT_SUCCESS = 'SCENXUS__GENERATE_USER_REPORT_SUCCESS'
const SCENXUS__GENERATE_USER_REPORT_FAILURE = 'SCENXUS__GENERATE_USER_REPORT_FAILURE'

const SCENXUS__GENERATE_CSP_REPORT_REQUEST = 'SCENXUS__GENERATE_CSP_REPORT_REQUEST'
const SCENXUS__GENERATE_CSP_REPORT_SUCCESS = 'SCENXUS__GENERATE_CSP_REPORT_SUCCESS'
const SCENXUS__GENERATE_CSP_REPORT_FAILURE = 'SCENXUS__GENERATE_CSP_REPORT_FAILURE'

const SCENXUS__UPDATE_DEVICE_REQUEST = 'SCENXUS__UPDATE_DEVICE_REQUEST'
const SCENXUS__UPDATE_DEVICE_SUCCESS = 'SCENXUS__UPDATE_DEVICE_SUCCESS'
const SCENXUS__UPDATE_DEVICE_FAILURE = 'SCENXUS__UPDATE_DEVICE_FAILURE'

const SCENXUS__UPDATE_USER_REQUEST = 'SCENXUS__UPDATE_USER_REQUEST'
const SCENXUS__UPDATE_USER_SUCCESS = 'SCENXUS__UPDATE_USER_SUCCESS'
const SCENXUS__UPDATE_USER_FAILURE = 'SCENXUS__UPDATE_USER_FAILURE'

const SCENXUS__UPDATE_FRAGRANCES_REQUEST = 'SCENXUS__UPDATE_FRAGRANCES_REQUEST'
const SCENXUS__UPDATE_FRAGRANCES_SUCCESS = 'SCENXUS__UPDATE_FRAGRANCES_SUCCESS'
const SCENXUS__UPDATE_FRAGRANCES_FAILURE = 'SCENXUS__UPDATE_FRAGRANCES_FAILURE'

const SCENXUS__EXPORT_FRAGRANCES_REQUEST = 'SCENXUS__EXPORT_FRAGRANCES_REQUEST'
const SCENXUS__EXPORT_FRAGRANCES_SUCCESS = 'SCENXUS__EXPORT_FRAGRANCES_SUCCESS'
const SCENXUS__EXPORT_FRAGRANCES_FAILURE = 'SCENXUS__EXPORT_FRAGRANCES_FAILURE'

const SCENXUS__GET_FRAGRANCES_REQUEST = 'SCENXUS__GET_FRAGRANCES_REQUEST'
const SCENXUS__GET_FRAGRANCES_SUCCESS = 'SCENXUS__GET_FRAGRANCES_SUCCESS'
const SCENXUS__GET_FRAGRANCES_FAILURE = 'SCENXUS__GET_FRAGRANCES_FAILURE'

export const initialState = {
    csp: null,
    csps: {
        items: [],
        total: 0,
    },
    fragrances: []
}

export const actionCreators = {
    getAllCsps: apiGet('/api/csps/all', [SCENXUS__GET_ALL_CSPS_REQUEST, SCENXUS__GET_ALL_CSPS_SUCCESS, SCENXUS__GET_ALL_CSPS_FAILURE]),
    getCsps: apiGet(({ page, pageSize, name, order, orderBy }) => `/api/csps?page=${page}&pageSize=${pageSize}&name=${name || ''}&order=${order || 'asc'}&orderBy=${orderBy || 'name'}`, [
        SCENXUS__GET_CSPS_REQUEST,
        SCENXUS__GET_CSPS_SUCCESS,
        SCENXUS__GET_CSPS_FAILURE,
    ]),
    addCsp: apiPost(`/api/csps`, [SCENXUS__ADD_CSP_REQUEST, SCENXUS__ADD_CSP_SUCCESS, SCENXUS__ADD_CSP_FAILURE]),
    updateCsp: apiPut(data => `/api/csps/${data.id}`, [SCENXUS__UPDATE_CSP_REQUEST, SCENXUS__UPDATE_CSP_SUCCESS, SCENXUS__UPDATE_CSP_FAILURE]),
    getCsp: apiGet(id => `/api/csps/${id}`, [SCENXUS__GET_CSP_REQUEST, SCENXUS__GET_CSP_SUCCESS, SCENXUS__GET_CSP_FAILURE]),
    removeCsp: apiDelete(id => `/api/csps/${id}`, [SCENXUS__REMOVE_CSP_REQUEST, SCENXUS__REMOVE_CSP_SUCCESS, SCENXUS__REMOVE_CSP_FAILURE]),
    generateDeviceReport: apiGet(({ filters }) => `/api/devices/report?${filters}`, [SCENXUS__GENERATE_DEVICE_REPORT_REQUEST, SCENXUS__GENERATE_DEVICE_REPORT_SUCCESS, SCENXUS__GENERATE_DEVICE_REPORT_FAILURE]),
    generateUserReport: apiGet(({ filters}) => `/api/users/report?${filters}`, [SCENXUS__GENERATE_USER_REPORT_REQUEST, SCENXUS__GENERATE_USER_REPORT_SUCCESS, SCENXUS__GENERATE_USER_REPORT_FAILURE]),
    generateCspReport: apiGet(({ filters }) => `/api/csps/report?${filters}`, [SCENXUS__GENERATE_CSP_REPORT_REQUEST, SCENXUS__GENERATE_CSP_REPORT_SUCCESS, SCENXUS__GENERATE_CSP_REPORT_FAILURE]),
    updateUser: apiPost(id => `/api/users/${id}`, [SCENXUS__UPDATE_USER_REQUEST, SCENXUS__UPDATE_USER_SUCCESS, SCENXUS__UPDATE_USER_FAILURE]),
    updateDevice: apiPost(dsn => `/api/devices/dsns/${dsn}`, [SCENXUS__UPDATE_DEVICE_REQUEST, SCENXUS__UPDATE_DEVICE_SUCCESS, SCENXUS__UPDATE_DEVICE_FAILURE]),
    updateFragrances: apiPost(data => '/api/import', [SCENXUS__UPDATE_FRAGRANCES_REQUEST, SCENXUS__UPDATE_FRAGRANCES_SUCCESS, SCENXUS__UPDATE_FRAGRANCES_FAILURE]),
    exportFragrances: apiGet(`/api/import/export`, [SCENXUS__EXPORT_FRAGRANCES_REQUEST, SCENXUS__EXPORT_FRAGRANCES_SUCCESS, SCENXUS__EXPORT_FRAGRANCES_FAILURE]),
    getFragrances: apiGet(`/api/fragrances`, [SCENXUS__GET_FRAGRANCES_REQUEST, SCENXUS__GET_FRAGRANCES_SUCCESS, SCENXUS__GET_FRAGRANCES_FAILURE]),
}

export const reducer = (state, action) => {
    switch (action.type) {
        case SCENXUS__GET_CSPS_REQUEST:
        case SCENXUS__GET_CSPS_FAILURE:
            return { ...state, csps: { ...state.csps, items: [], total: 0 } }
        case SCENXUS__GET_CSPS_SUCCESS:
            return { ...state, csps: { ...state.csps, items: action.response.csps, total: action.response.total } }

        case SCENXUS__GET_CSP_REQUEST:
        case SCENXUS__GET_CSP_FAILURE:
            return { ...state, csp: null }
        case SCENXUS__GET_CSP_SUCCESS:
            return { ...state, csp: action.response }
        case SCENXUS__GET_FRAGRANCES_REQUEST:
            return { ...state, fragrances: [] }
        case SCENXUS__GET_FRAGRANCES_SUCCESS:
            const fragrances = [...action.response]
            fragrances.sort((a, b) => a.fragrance.localeCompare(b.fragrance))
            return { ...state, fragrances }
        default:
            return state
    }
}

export const ScenXusContext = React.createContext(initialState)

export const ScenXusProvider = ({ children }) => {
    return (
        <ApiProvider initialState={initialState} actionCreators={actionCreators} reducer={reducer} context={ScenXusContext}>
            {children}
        </ApiProvider>
    )
}

export const useScenXusApi = () => useCustomApi(ScenXusContext)
