import React from 'react'
import styles from './Times.module.css'

const Times = props => {
    return (
        <div className={styles.container}>
            <div className={`${styles.hours} ${styles.hashes}`}>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div>
                    <div className={styles.lastHash}></div>
                </div>
            </div>

            <div className={`${styles.hours} ${styles.times}`}>
                <div className={styles.big}>12am</div>
                <div />
                <div>2am</div>
                <div />
                <div>4am</div>
                <div />
                <div>6am</div>
                <div />
                <div>8am</div>
                <div />
                <div className={styles.big}>10am</div>
                <div />
                <div className={styles.big}>12pm</div>
                <div />
                <div>2pm</div>
                <div />
                <div>4pm</div>
                <div />
                <div>6pm</div>
                <div />
                <div>8pm</div>
                <div />
                <div className={styles.big}>10pm</div>
                <div>
                    <div className={styles.extended}>12am</div>
                </div>
            </div>
        </div>
    )
}

export default Times
