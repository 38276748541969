import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import ScenXusModal from '../ScenXusModal'
import { useForm } from '@radbse/hooks'
import { Form } from 'semantic-ui-react'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const NotificationModal = ({ open, trigger, onOpen, onClose, notification, loading, onSave }) => {
    const [fields, form] = useForm({
        fields: [
            { name: 'method', label: 'Method' },
            { name: 'connected', label: 'Connected', type: 'boolean' },
            { name: 'disconnected', label: 'Disconnected', type: 'boolean' },
            { name: 'lowProductLevel', label: 'Low Product Level', type: 'boolean' },
            { name: 'recipient', label: 'Recipient' },
        ],
        submit: onSave,
    })

    useEffect(() => {
        form.reset()
        if (notification) {
            form.setValues(notification)
        } else {
            form.setValue('method', 'sms')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification])

    const onChange = useCallback(
        (field, value) => {
            form.setValue(field, value)
        },
        [form]
    )

    return (
        <ScenXusModal
            open={open}
            trigger={trigger}
            onOpen={onOpen}
            onClose={onClose}
            buttons={[
                { iconUrl: '/assets/ic-cancel.svg', onClick: onClose, title: 'Cancel' },
                { iconUrl: '/assets/ic-checkmark.svg', onClick: form.submit, title: 'Save Notification', primary: true, loading },
            ]}
        >
            <Form>
                <Form.Group inline>
                    <Form.Radio name="method" value="sms" onChange={(_, { value }) => onChange('method', value)} label="SMS" checked={fields.method.value === 'sms'} disabled={notification !== null} />
                    <Form.Radio name="method" value="email" onChange={(_, { value }) => onChange('method', value)} label="Email" checked={fields.method.value === 'email'} disabled={notification !== null} />
                </Form.Group>
                {fields.method.value === 'sms' && (
                    <Form.Field>
                        <label>Recipient</label>
                        <PhoneInput international defaultCountry="US" value={fields.recipient.value} onChange={value => onChange('recipient', value)} disabled={notification !== null} />
                    </Form.Field>
                )}

                {fields.method.value === 'email' && <Form.Input {...fields.recipient} disabled={notification !== null} />}

                <Form.Checkbox name={fields.connected.name} label={fields.connected.label} checked={fields.connected.value !== false && fields.connected.value !== undefined} onChange={(_, { checked }) => onChange('connected', checked)} />
                <Form.Checkbox
                    name={fields.disconnected.name}
                    label={fields.disconnected.label}
                    checked={fields.disconnected.value !== false && fields.disconnected.value !== undefined}
                    onChange={(_, { checked }) => onChange('disconnected', checked)}
                />
                <Form.Checkbox
                    name={fields.lowProductLevel.name}
                    label={fields.lowProductLevel.label}
                    checked={fields.lowProductLevel.value !== false && fields.lowProductLevel !== undefined}
                    onChange={(_, { checked }) => onChange('lowProductLevel', checked)}
                />
            </Form>
        </ScenXusModal>
    )
}

NotificationModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    trigger: PropTypes.any,
    notification: PropTypes.object,
    loading: PropTypes.bool,
    onSave: PropTypes.func,
}

export default NotificationModal
