import React from 'react'
import PropTypes from 'prop-types'
import RouterLinkItem from './RouterLinkItem'
import styles from './RouterLinkImageItem.module.css'

const RouterLinkImageItem = ({ className, destination, inactiveImg, activeImg, children, roles }) => {
    return <RouterLinkItem className={className} destination={destination} roles={roles}>
        {active => {
            const image = active ? activeImg : inactiveImg

            return (<div className={styles.container}>
                <div className={styles.image} style={{backgroundImage: `url(${image})`}}></div>
                <div>{children}</div>
            </div>)
        }}
    </RouterLinkItem>
}

RouterLinkImageItem.propTypes = {
    destination: PropTypes.string.isRequired,
    inactiveImg: PropTypes.string.isRequired,
    activeImg: PropTypes.string.isRequired
}

export default RouterLinkImageItem
