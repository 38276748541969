import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Menu } from 'semantic-ui-react'
import { Location, navigate } from '@reach/router'
import { SessionContext } from '@radbse/auth-identity'

const RouterLinkItem = ({ className, destination, children, size, position, roles }) => {
    const session = useContext(SessionContext)
    let authorized = false
    if (session.user && roles) {
        if (Array.isArray(roles)) {
            roles.forEach(r => {
                if (session.user.role === r) authorized = true
            })
        } else {
            if (session.user.role === roles) authorized = true
        }
    }

    if (roles && !authorized) return null

    return (
        <Location>
            {({ location }) => {
                const active = location.pathname.includes(destination)
                return (
                    <Menu.Item className={className} active={active} onClick={() => navigate(destination)} size={size} position={position}>
                        {children(active)}
                    </Menu.Item>
                )
            }}
        </Location>
    )
}

RouterLinkItem.propTypes = {
    destination: PropTypes.string.isRequired,
}

export default RouterLinkItem
