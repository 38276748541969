import React from 'react'
import PropTypes from 'prop-types'
import { useStateCountryNames } from '../../hooks/device'

const DeviceDescription = ({ device }) => {
    const { stateName, countryName } = useStateCountryNames(device)

    return (
        <span>
            {device.scenxus.csp && <span>{device.scenxus.csp}, </span>}
            {device.scenxus.customer && <span>{device.scenxus.customer}, </span>}
            {device.productName}, {device.scenxus.city}, {stateName}, {countryName}
        </span>
    )
}

DeviceDescription.propTypes = {
    device: PropTypes.object.isRequired
}

export default DeviceDescription
