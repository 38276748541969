import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import SelectionDevice from './SelectionDevice'
import TableHeaderImageCell from '../TableHeaderImageCell'

const SelectionDevices = ({ items, onSelectionChange }) => {
    return (
        <Table striped>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell />
                    <TableHeaderImageCell img="/assets/ic-name.svg" title="Name" />
                    <TableHeaderImageCell img="/assets/ic-id.svg" title="ID" />
                    <TableHeaderImageCell img="/assets/ic-status.svg" title="Status" />
                    <TableHeaderImageCell img="/assets/ic-product.svg" title="Fragrance" />
                    <TableHeaderImageCell img="/assets/ic-mode.svg" title="Mode" />
                    <TableHeaderImageCell img="/assets/ic-intensity.svg" title="Intensity" />
                    <TableHeaderImageCell img="/assets/ic-off-cycle.svg" title="Off-Cycle" />
                    <TableHeaderImageCell img="/assets/ic-fan.svg" title="Fan" />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {items.map((device, i) => {
                    return <SelectionDevice key={i} device={device} onSelectionChange={onSelectionChange} />
                })}
            </Table.Body>
        </Table>
    )
}

SelectionDevices.propTypes = {
    items: PropTypes.array.isRequired,
    onSelect: PropTypes.func
}

export default SelectionDevices
