import React from 'react'
import PropTypes from 'prop-types'
import styles from './ButtonGroup.module.scss'
import Button from './Button'

const ButtonGroup = ({ buttons, horizontal }) => {
    return (
        <div className={horizontal ? styles.horizontalContainer : styles.container}>
            {buttons.map((button, i) => {
                return <Button key={i} {...button} />
            })}
        </div>
    )
}

ButtonGroup.propTypes = {
    buttons: PropTypes.array.isRequired,
    horizontal: PropTypes.bool,
}

export default ButtonGroup
