import React from 'react'
import PropTypes from 'prop-types'
import { Table, Popup, Icon } from 'semantic-ui-react'
import Notification from './Notification'
import EmptyTableRow from '../EmptyTableRow'
import styles from './Notifications.module.scss'

const Notifications = ({ notifications, onRemove, onEdit }) => {
    return (
        <Table className={styles.table}>
            <Table.Header>
                <Table.Row className={styles.headerRow}>
                    <Table.HeaderCell>Method</Table.HeaderCell>
                    <Table.HeaderCell>Recipient</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Connected</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Disconnected</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        Low Product Level <Popup trigger={<Icon name="info circle" />} content="Low Product Level notifications will be sent when fragrance remaining falls below 20%." size="tiny" />
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Edit</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Remove</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body className={styles.alternating}>
                {notifications.length === 0 && <EmptyTableRow colSpan={7} message="No Notifications" />}

                {notifications.map((notification, i) => {
                    return <Notification key={i} notification={notification} onRemove={onRemove} onEdit={onEdit} />
                })}
            </Table.Body>
        </Table>
    )
}

Notifications.propTypes = {
    notifications: PropTypes.array.isRequired,
    onRemove: PropTypes.func,
    onEdit: PropTypes.func,
}

export default Notifications
