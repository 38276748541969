import React, { useEffect, useMemo, useState, useCallback } from 'react'
import ScenXusModal from '../ScenXusModal'
import { useForm } from '@radbse/hooks'
import styles from './FrameModal.module.css'
import { Form } from 'semantic-ui-react'
import IntensityFormField from './IntensityFormField'
import TimeFormField from './TimeFormField'
import Moment from 'moment'

const FrameModal = ({ frame, open, trigger, onOpen, onClose, onSave, onValidate, onDisableFrames }) => {
    const validateTime = time => {
        const tryTime = Moment(time, 'LT')
        if (!tryTime.isValid()) return 'Invalid Time'
    }

    const [conflicts, setConflicts] = useState(null)

    const _onSave = useCallback(
        ({ active, dayOfTheWeek, intensity, startTime, endTime }) => {
            if (onSave) {
                const st = Moment(startTime, 'LT')
                const et = Moment(endTime, 'LT')
                if (onSave) onSave({ active, dayOfTheWeek, intensity: parseInt(intensity), startHour: st.hour(), startMinute: st.minute(), stopHour: et.hour(), stopMinute: et.minute() })
            }
        },
        [onSave]
    )

    const [fields, form] = useForm({
        fields: [
            { name: 'active', type: 'boolean' },
            { name: 'dayOfTheWeek', label: 'Day of the Week', type: 'select' },
            { name: 'startTime', label: 'Start Time', validate: validateTime },
            { name: 'endTime', label: 'End Time', validate: validateTime },
            { name: 'intensity', label: 'Intensity', value: '0' },
        ],
        validate: values => {
            const startTime = Moment(values.startTime, 'LT')
            const endTime = Moment(values.endTime, 'LT')

            if (startTime > endTime) {
                return {
                    startTime: 'Start Time must preceed End Time',
                    endTime: 'Start Time must preceed End Time',
                }
            }

            if (onValidate) {
                setConflicts(null)
                const conflicts = onValidate(values) || []
                if (conflicts.length > 0) {
                    setConflicts(conflicts)
                    return {
                        startTime: 'Time Conflicts',
                        endTime: 'Time Conflicts',
                    }
                }
            }
        },
        submit: _onSave,
    })

    const onSaveFrameButDisableOtherFrames = useCallback(() => {
        onDisableFrames(conflicts)
        form.submit()
    }, [conflicts, form, onDisableFrames])

    const onSaveFrameButDoNotEnable = useCallback(() => {
        _onSave({
            active: false,
            dayOfTheWeek: fields.dayOfTheWeek.value,
            intensity: fields.intensity.value,
            startTime: fields.startTime.value,
            endTime: fields.endTime.value,
        })
    }, [_onSave, fields.dayOfTheWeek.value, fields.endTime.value, fields.intensity.value, fields.startTime.value])

    const onSaveWithReset = useCallback(() => {
        setConflicts(null)
        form.submit()
    }, [form])

    const daysOfTheWeek = useMemo(
        () => [
            { key: 0, text: 'All Days', value: 0 },
            { key: 1, text: 'Monday', value: 1 },
            { key: 2, text: 'Tuesday', value: 2 },
            { key: 3, text: 'Wednesday', value: 3 },
            { key: 4, text: 'Thursday', value: 4 },
            { key: 5, text: 'Friday', value: 5 },
            { key: 6, text: 'Saturday', value: 6 },
            { key: 7, text: 'Sunday', value: 7 },
            { key: 8, text: 'Weekend Days', value: 8 },
            { key: 9, text: 'Weekdays', value: 9 },
        ],
        []
    )

    useEffect(() => {
        console.log(frame)
        const startTime = Moment()
            .startOf('day')
            .add(frame.startHour, 'hours')
            .add(frame.startMinute, 'minutes')
            .format('LT')
        const endTime = Moment()
            .startOf('day')
            .add(frame.stopHour, 'hours')
            .add(frame.stopMinute, 'minutes')
            .format('LT')

        form.setValues({ ...frame, intensity: `${frame.intensity}`, startTime, endTime })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [frame])

    return (
        <ScenXusModal
            open={open}
            trigger={trigger}
            onOpen={onOpen}
            onClose={onClose}
            buttons={
                !conflicts
                    ? [
                          { iconUrl: '/assets/ic-cancel.svg', onClick: onClose, title: 'Cancel' },
                          { iconUrl: '/assets/ic-checkmark.svg', onClick: onSaveWithReset, title: 'Confirm', primary: true },
                      ]
                    : []
            }
        >
            <div className={styles.container}>
                <Form>
                    <div className={styles.header}>
                        <div className={styles.number}>{frame.number}</div>
                        <div className={styles.title}>Edit Schedule</div>
                        <div className={styles.enabled}>
                            <label className={styles.enabledLabel}>Enabled</label>
                            <Form.Radio toggle onChange={(_, { checked }) => form.setValue('active', checked)} checked={fields.active.value} disabled={conflicts != null} />
                        </div>
                    </div>

                    {conflicts && (
                        <div>
                            <div className={styles.heading}>There is a conflict with the following schedules:</div>
                            <div>
                                {conflicts.map((c, i) => {
                                    return <div key={i}>Schedule {c}</div>
                                })}
                            </div>
                            <div className={styles.buttonContainer}>
                                <div style={{ color: '#F36B24' }} onClick={onSaveFrameButDisableOtherFrames}>
                                    <div className={styles.heading}>Disable conflicting schedules</div>
                                    <div>and enable this schedule</div>
                                </div>
                                <div style={{ color: '#F79618' }} onClick={onSaveFrameButDoNotEnable}>
                                    <div className={styles.heading}>Save schedule</div>
                                    <div>but do not enable</div>
                                </div>
                                <div style={{ color: '#FFFFFF' }} onClick={() => setConflicts(null)}>
                                    <div className={styles.heading}>Cancel</div>
                                    <div>return to editor</div>
                                </div>
                            </div>
                        </div>
                    )}

                    {!conflicts && (
                        <React.Fragment>
                            <Form.Dropdown {...fields.dayOfTheWeek} options={daysOfTheWeek} onChange={(_, { value }) => form.setValue('dayOfTheWeek', value)} scrolling search />
                            <TimeFormField {...fields.startTime} />
                            <TimeFormField {...fields.endTime} />
                            <IntensityFormField {...fields.intensity} width={8} />
                        </React.Fragment>
                    )}
                </Form>
            </div>
        </ScenXusModal>
    )
}

export default FrameModal
