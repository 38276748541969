import { useEffect } from 'react'
import { useAuthApi } from '@radbse/auth-identity'
import { navigate } from '@reach/router'

const SignOutPage = () => {
    const [actions, useApi] = useAuthApi()
    const [, logout] = useApi(actions.api.logout, false)

    useEffect(() => {
        const load = async () => {
            await logout()
            navigate('/')
        }

        load()
    }, [logout])

    return null
}

export default SignOutPage
