import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import Device from './Device'
import TableHeaderImageCell from '../TableHeaderImageCell'
import SortableTable from '../SortableTable'

const Devices = ({ items, onClick, order, orderBy, onOrderChange }) => {
    return (
        <SortableTable order={order} orderBy={orderBy || 'name'} onOrderChange={onOrderChange} clickable>
            {(sorted, onColumnClick) => (
                <React.Fragment>
                    <Table.Header>
                        <Table.Row>
                            <TableHeaderImageCell img="/assets/ic-name.svg" title="Name" onClick={onColumnClick('name')} sorted={sorted('name')} />
                            <TableHeaderImageCell img="/assets/ic-id.svg" title="ID" />
                            <TableHeaderImageCell img="/assets/ic-status.svg" title="Status" onClick={onColumnClick('status')} sorted={sorted('status')} />
                            <TableHeaderImageCell img="/assets/ic-product.svg" title="Fragrance" onClick={onColumnClick('fragrance')} sorted={sorted('fragrance')} />
                            <TableHeaderImageCell img="/assets/ic-mode.svg" title="Mode" onClick={onColumnClick('mode')} sorted={sorted('mode')} />
                            <TableHeaderImageCell img="/assets/ic-intensity.svg" title="Intensity" />
                            <TableHeaderImageCell img="/assets/ic-off-cycle.svg" title="Off-Cycle" />
                            <TableHeaderImageCell img="/assets/ic-fan.svg" title="Fan" />
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {items.map((device, i) => {
                            return <Device key={i} device={device} onClick={onClick} />
                        })}
                    </Table.Body>
                </React.Fragment>
            )}
        </SortableTable>
    )
}

Devices.propTypes = {
    items: PropTypes.array.isRequired,
    onClick: PropTypes.func,
}

export default Devices
