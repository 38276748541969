import React from 'react'
import PropTypes from 'prop-types'
import { Modal, Header } from 'semantic-ui-react'
import ButtonGroup from './buttons/ButtonGroup'

const ScenXusModal = ({ open, size, onOpen, onClose, content, buttons, title, children, trigger }) => {
    if (!size) size = 'mini'
    return (
        <Modal open={open} size={size} closeOnEscape onClose={onClose} onOpen={onOpen} trigger={trigger}>
            <Modal.Content>
                {title && <Header>{title}</Header>}
                {content && <p>{content}</p>}
                {children}
            </Modal.Content>
            <Modal.Actions>
                <ButtonGroup horizontal buttons={buttons} />
            </Modal.Actions>
        </Modal>
    )
}

ScenXusModal.propTypes = {
    open: PropTypes.bool,
    size: PropTypes.string,
    onClose: PropTypes.func,
    content: PropTypes.any,
    children: PropTypes.any,
    buttons: PropTypes.array.isRequired,
    title: PropTypes.string,
    trigger: PropTypes.any,
}

export default ScenXusModal
