import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'
import styles from './Day.module.scss'
import sortBy from 'lodash.sortby'
import Frame from './Frame'

const Day = ({ day, frames }) => {
    const _sortedFrames = useMemo(() => sortBy(frames, 'start'), [frames])
    const computedframes = useMemo(() => {
        const _frames = []

        for (let i = 0; i < _sortedFrames.length; i++) {
            const prev = i > 0 ? _sortedFrames[i - 1] : { start: 0, end: 0, intensity: null }
            const current = _sortedFrames[i]
            const next = i < _sortedFrames.length - 1 ? _sortedFrames[i + 1] : null

            if (prev.end !== current.start) {
                //push a blank frame between the previous and the current
                _frames.push({ flex: current.start - prev.end, empty: true })
            }

            _frames.push({ flex: current.end - current.start, intensity: current.intensity, number: current.number })

            if (!next && current.end !== 24) {
                //push a blank frame for the end
                _frames.push({ flex: 24 - current.end, empty: true })
            }
        }

        return _frames
    }, [_sortedFrames])

    return (
        <div className={styles.container}>
            <div className={styles.dayLine}>
                <div className={styles.dayOfWeek}>
                    {Moment()
                        .day(day + 1)
                        .format('dddd')
                        .substring(0, 1)}
                </div>
                <div className={styles.dots}></div>
            </div>
            <div className={styles.frames}>
                {computedframes
                    .filter(frame => frame.flex > 0)
                    .map((frame, i) => {
                        return <Frame key={i} {...frame} />
                    })}
            </div>
        </div>
    )
}

Day.propTypes = {
    day: PropTypes.number.isRequired,
    frames: PropTypes.array.isRequired,
}

export default Day
