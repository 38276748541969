import React, { useCallback, useState } from 'react'
import { useDocumentTitle } from '@radbse/hooks'
import { Form } from 'semantic-ui-react'
import * as styles from './SignInPage.module.css'
import Button from '../../components/buttons/Button'
import { useAuthApi } from '@radbse/auth-identity'
import { useForm } from '@radbse/hooks'
import { Link } from '@reach/router'
import Prolitec from '../Prolitec'
import ProlitecErrors from '../../components/ProlitecErrors'

const SignInPage = ({ navigate }) => {
    useDocumentTitle('ScenXus | SIGN IN')

    const [actions, useApi] = useAuthApi()
    const [loginLoading, login] = useApi(actions.api.login)
    const [error, setError] = useState(null)

    const fieldMappings = [{ name: 'email', label: 'Email', errorField: 'email' }, { name: 'password', label: 'Password', errorField: 'password' }]

    const [fields, form] = useForm({
        fields: fieldMappings,
        submit: async ({ email, password }) => {
            try {
                setError(null)
                await login({ email, password })
                const returnUrl = localStorage.getItem('returnUrl')
                navigate(returnUrl || '/')
            } catch (error) {
                setError({ errors: {
                    email: ["Incorrect email or password"]
                }})
            }
        },
    })


    const handleSubmit = useCallback(form.submit, [fields.email, fields.password, login])

    return (
        <Prolitec description={<div><p>Welcome to ScenXus by Prolitec!</p><p>This system allows authorized users to monitor and control Prolitec x80 and x90 appliances from anywhere in the world.  Settings and options of single or multiple appliances can be modified with a few clicks, so access is restricted and maintained by a system administrator for each CSP.</p></div>}>
            <Form error className={`dark`} onSubmit={handleSubmit}>
                <ProlitecErrors error={error} fieldMappings={fieldMappings} />
                <Form.Input {...fields.email} />
                <Form.Input type="password" {...fields.password} />
                <div className={styles.formRow}>
                    <Link className={styles.link} to="/password/reset">
                        Reset Password
                    </Link>
                    <Button fluid primary loading={loginLoading}>
                        Sign in
                    </Button>
                </div>
                <div className={styles.formRow}>
                    <Link className={styles.link} to="/account/confirm">
                        Confirm Account
                    </Link>
                </div>
            </Form>
        </Prolitec>
    )
}

export default SignInPage
