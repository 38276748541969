import React from 'react'
import PropTypes from 'prop-types'
import { Table, Checkbox } from 'semantic-ui-react'
import Moment from 'moment'
import styles from './Device.module.scss'
import Intensity from './Intensity'
import FragranceRemaining from './FragranceRemaining'
import { useMode, useCycle, useFragrancePercentRemaining, useStateCountryNames } from '../../hooks/device'

const SelectionDevice = ({ device, onSelectionChange }) => {
    const fragrancePercentRemaining = useFragrancePercentRemaining(device)
    const [mode, modeOn] = useMode(device)
    const cycle = useCycle(device)

    const { stateName, countryName } = useStateCountryNames(device)

    return (
        <Table.Row>
            <Table.Cell>
                <Checkbox checked={device.selected} onChange={(_, { checked }) => onSelectionChange(device, checked)} />
            </Table.Cell>
            <Table.Cell>
                {device.scenxus && (
                    <div>
                        <div>
                            <b>{device.scenxus.csp}</b>
                        </div>
                        <div>
                            <b>{device.scenxus.customer}</b>
                        </div>
                        <div>
                            Customer ID: {device.scenxus.customerId}
                        </div>
                        <div>
                            Store Number: {device.scenxus.storeNumber}
                        </div>
                    </div>
                )}

                <div>{device.productName}</div>
                {device.scenxus && (
                    <div>
                        {device.scenxus.city}, {stateName}, {countryName}
                    </div>
                )}
            </Table.Cell>
            <Table.Cell>
                {device.scenxus && <div>Serial #: {device.scenxus.serialNumber}</div>}

                <div>DSN: {device.dsn}</div>
                {device.ssid && (
                    <div>
                        Network: {device.lanEnabled && <span>LAN</span>}
                        {!device.lanEnabled && <span>{device.ssid}</span>}
                    </div>
                )}
                {device.scenxus && <div>Model: {device.scenxus.cartName}</div>}
                {device.scenxus && <div>Fragrance: {device.scenxus.fragrance}</div>}
            </Table.Cell>
            <Table.Cell>
                {device.scenxus && (
                    <div>
                        <div className={styles.row}>
                            <div>{device.connectionStatus === 'Online' ? <div className={styles.on} /> : <div className={styles.off} />}</div>
                            <div>{device.connectionStatus}</div>
                        </div>
                        {device.connectedAt && <div>{Moment(device.connectedAt).fromNow()}</div>}
                    </div>
                )}
            </Table.Cell>
            <Table.Cell>
                <FragranceRemaining fragranceRemaining={fragrancePercentRemaining} />
            </Table.Cell>
            <Table.Cell>
                {device.scenxus && (
                    <div className={styles.row}>
                        <div className={styles[modeOn ? 'on' : 'off']} />
                        <div>{mode}</div>
                    </div>
                )}
            </Table.Cell>
            <Table.Cell>{device.scenxus && <Intensity device={device} />}</Table.Cell>
            <Table.Cell>
                {device.scenxus && (
                    <div className={styles.row}>
                        <div className={styles[`offCycle-${device.scenxus.cycle}`]} />
                        <div>{cycle}</div>
                    </div>
                )}
            </Table.Cell>
            <Table.Cell>
                {device.scenxus && device.scenxus.fan > 0 ? (
                    <div className={styles.row}>
                        <div className={styles[`fan-${device.scenxus.fan}`]} />
                        <div>{device.scenxus.fan}</div>
                    </div>
                ) : device.scenxus ? (
                    'N/A'
                ) : null}
            </Table.Cell>
        </Table.Row>
    )
}

SelectionDevice.propTypes = {
    device: PropTypes.object.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
}

export default SelectionDevice
