import React, { useCallback, useState } from 'react'
import styles from './ImportPage.module.scss'
import { Header, Table } from 'semantic-ui-react'
import { useDropzone } from 'react-dropzone'
import Papa from 'papaparse'
import FileSaver from 'file-saver'
import ButtonGroup from '../../components/buttons/ButtonGroup'
import { useScenXusApi } from '../../stores/scenxusStore'
import { useAlert } from '@radbse/hooks'
import LoadingDimmer from '../../components/LoadingDimmer'

const ImportPage = () => {
    const [actions, useApi] = useScenXusApi()
    const [isUploading, updateFragrances] = useApi(actions.updateFragrances)
    const [isDownloading, exportFragrances] = useApi(actions.exportFragrances)

    const [importResult, setImportResult] = useState(null)
    const [fileName, setFileName] = useState(null)

    const [, setAlert] = useAlert()

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles == null || acceptedFiles.length !== 1) {
            alert('Please select ONLY one file')
            return
        }

        var file = acceptedFiles[0]

        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: function(results) {
                const duplicates = []
                const allFragrances = results.data.map((d, i) => d.Fragrance)
                allFragrances.forEach((frag, i) => {
                    const number = allFragrances.filter(frag2 => frag === frag2)
                    if (number.length > 1) {
                        duplicates.push(frag)
                    }
                })

                const uniqueDuplicates = new Set(duplicates)
                var errors = [...uniqueDuplicates].map((dupe, i) => {
                    return {
                        row: null,
                        type: 'Duplicate',
                        code: null,
                        message: `Duplicate Fragrance Found: ${dupe}`,
                    }
                })

                results.errors = results.errors.concat(errors)
                setImportResult(results)
            },
        })
    }, [])

    const onReset = useCallback(() => {
        setImportResult(null)
        setFileName(null)
    }, [])

    const onApprove = useCallback(async () => {
        try {
            await updateFragrances({ fragrances: importResult.data })
            setAlert({ content: `Successfully updated fragrances`, color: 'green' })
            onReset()
        } catch (error) {
            setAlert({ error: true, content: 'Failed to update fragrances', color: 'red', icon: 'exclamation circle' })
        }
    }, [importResult, onReset, setAlert, updateFragrances])


    const onDownload = useCallback(async () => {
        try {
            const result = await exportFragrances()

            var csv = Papa.unparse(result.response.items)
            const blob = new Blob([csv], { type: 'text/csv' })
            FileSaver.saveAs(blob, 'fragrances.csv')

            setAlert({ content: `Successfully downloaded fragrances`, color: 'green' })
        } catch (error) {
            setAlert({ error: true, content: 'Failed to get fragrances', color: 'red', icon: 'exclamation circle' })
        }
    }, [setAlert, exportFragrances])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const loadingMessage = isDownloading ? 'Downloading Fragrances' : 'Updating Fragrances'

    return (
        <div className={styles.secondaryPageContainer}>
            <LoadingDimmer ready={true} loading={isUploading || isDownloading} loadingText={loadingMessage} />

            <div className={styles.pageHeader}>
                <Header as="h1">Instructions</Header>
            </div>

            <div className={styles.instructionsContent}>
                <Header as="h2">Add a New Fragrance</Header>
                <ol>
                    <li>Click the Download button below. This will download the most current list of fragrances and device models being used. The file will be downloaded as fragrances.csv.</li>
                    <li>Once downloaded, open the fragrances.csv file.</li>
                    <li>To add a new fragrance, scroll to the bottom of the current list of fragrances. You will then need to enter the following information:
                        <ol className={styles.alphaList}>
                            <li><b>Fragrance</b>: Enter the fragrance name as you would want it displayed within the ScenXus website</li>
                            <li><b>SKU</b>: Enter the SKU number of the Fragrance</li>
                            <li><b>Density</b>: Enter the density value of the Fragrance</li>
                            <li>In the following columns, enter the flow rate associated to each supported device model</li>
                        </ol>
                    </li>
                    <li>Once all information has been entered, Click Save As on the fragrances.csv file, save as a CSV file and close the file.</li>
                    <li>Open the saved file in a text editor (e.g. Notepad). Delete any blank lines at the end of the document and save.</li>
                    <li>From the ScenXus website, under the Import section, click to select the updated fragrances.csv file from your computer.</li>
                    <li>Once selected and added, click the Approve button on the right hand side of the screen.</li>
                </ol>

                <Header as="h2">Remove a Fragrance</Header>
                <ol>
                    <li>Click the Download button below. This will download the most current list of fragrances and device models being used. The file will be downloaded as fragrances.csv.</li>
                    <li>Once downloaded, open the fragrances.csv file.</li>
                    <li>Select and delete the row which contains the Fragrance you wish to remove.</li>
                    <li>Once the row has been deleted, Click Save As on the fragrances.csv file, save as a CSV file and close the file.</li>
                    <li>From the ScenXus website, under the Import section, click to select the updated fragrances.csv file from your computer.</li>
                    <li>Once selected and added, click the Approve button on the right hand side of the screen.</li>
                </ol>
            </div>

            <hr />

            <div className={styles.pageHeader}>
                <Header as="h1">Export</Header>
            </div>

            <div className={styles.pageHeaderButtons}>
                <ButtonGroup
                    buttons={[
                        { title: 'Download', onClick: onDownload, primary: true, },
                    ]}
                    horizontal
                />
            </div>

            <hr />

            <div className={styles.pageHeader}>
                <Header as="h1">Import{fileName && ` - ${fileName}`}</Header>
            </div>

            <div className={styles.pageHeaderButtons}>
                <ButtonGroup
                    buttons={[
                        { title: 'Reset', disabled: !importResult, onClick: onReset },
                        { title: 'Approve', onClick: onApprove, primary: true, disabled: !importResult || (importResult && importResult.errors.length > 0) },
                    ]}
                    horizontal
                />
            </div>
            {!importResult && (
                <React.Fragment>
                    <div>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {isDragActive ? <div className={styles.dropZone}>Drop the Fragrance CSV file...</div> : <div className={styles.dropZone}>Drag 'n' drop or click to select the Fragrance CSV file...</div>}
                        </div>
                    </div>
                </React.Fragment>
            )}

            {importResult && (
                <React.Fragment>
                    {importResult.errors && importResult.errors.length > 0 && (
                        <React.Fragment>
                            <div className={styles.header}>
                                <Header>Errors</Header>
                            </div>
                            <div>
                                <Table>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Row</Table.HeaderCell>
                                            <Table.HeaderCell>Type</Table.HeaderCell>
                                            <Table.HeaderCell>Code</Table.HeaderCell>
                                            <Table.HeaderCell>Message</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {importResult.errors.map((e, i) => {
                                            return (
                                                <Table.Row>
                                                    <Table.Cell>{e.row}</Table.Cell>
                                                    <Table.Cell>{e.type}</Table.Cell>
                                                    <Table.Cell>{e.code}</Table.Cell>
                                                    <Table.Cell>{e.message}</Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                                    </Table.Body>
                                </Table>
                            </div>
                        </React.Fragment>
                    )}
                    {importResult.data && (
                        <React.Fragment>
                            <div className={styles.header}>
                                <Header>Results</Header>
                            </div>
                            <div>
                                <Table>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Fragrance</Table.HeaderCell>
                                            <Table.HeaderCell>SKU</Table.HeaderCell>
                                            <Table.HeaderCell>Density</Table.HeaderCell>
                                            <Table.HeaderCell>AQ380</Table.HeaderCell>
                                            <Table.HeaderCell>AQ580</Table.HeaderCell>
                                            <Table.HeaderCell>AQ880 w/ 1250ml</Table.HeaderCell>
                                            <Table.HeaderCell>AQ880 w/ 2500ml</Table.HeaderCell>
                                            <Table.HeaderCell>AQ1280 w/ 1250ml</Table.HeaderCell>
                                            <Table.HeaderCell>AQ1280 w/ 2500ml</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {importResult.data.map((d, i) => {
                                            return (
                                                <Table.Row key={i}>
                                                    <Table.Cell>{d.Fragrance}</Table.Cell>
                                                    <Table.Cell>{d.SKU}</Table.Cell>
                                                    <Table.Cell>{d.Density}</Table.Cell>
                                                    <Table.Cell>{d.AQ380}</Table.Cell>
                                                    <Table.Cell>{d.AQ580}</Table.Cell>
                                                    <Table.Cell>{d.AQ880w1250}</Table.Cell>
                                                    <Table.Cell>{d.AQ880w2500}</Table.Cell>
                                                    <Table.Cell>{d.AQ1280w1250}</Table.Cell>
                                                    <Table.Cell>{d.AQ1280w2500}</Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                                    </Table.Body>
                                </Table>
                            </div>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}

            {/* <div className={styles.buttonRow}>button row</div> */}
        </div>
    )
}

export default ImportPage
