import React from 'react'
import PropTypes from 'prop-types'
import styles from './FragranceRemaining.module.scss'
import Numeral from 'numeral'

const FragranceRemaining = ({ fragranceRemaining }) => {
    return fragranceRemaining !== null && fragranceRemaining !== undefined ? (
        <div className={styles.row}>
            {fragranceRemaining === 0 && <div className={styles['remaining-0']} />}
            {fragranceRemaining > 0 && fragranceRemaining < 0.25 && <div className={styles['remaining-25']} />}
            {fragranceRemaining >= 0.25 && fragranceRemaining < 0.5 && <div className={styles['remaining-50']} />}
            {fragranceRemaining >= 0.5 && fragranceRemaining < 0.75 && <div className={styles['remaining-75']} />}
            {fragranceRemaining >= 0.75 && <div className={styles['remaining-100']} />}
            <div>{Numeral(fragranceRemaining).format('0%')}</div>
        </div>
    ) : (
        'N/A'
    )
}

FragranceRemaining.propTypes = {
    fragranceRemaining: PropTypes.number,
}

export default FragranceRemaining
