import React, { useCallback, useMemo } from 'react'
import styles from './CspsPage.module.scss'
import PagedEntity from '../PagedEntity'
import { useScenXusApi } from '../../stores/scenxusStore'
import Csps from '../../components/csps/Csps'
import { navigate } from '@reach/router'
import { useDocumentTitle } from '@radbse/hooks'

const CspsPage = ({ location }) => {
    useDocumentTitle('ScenXus | CSPS')
    const [actions, useApi, state] = useScenXusApi()
    const { csps } = state

    const [loading, getCsps] = useApi(actions.getCsps)
    const [, generateReport] = useApi(actions.generateCspReport)

    const loadCsps = useCallback(
        async (page, pageSize, filters, order, orderBy) => {
            await getCsps({ page, pageSize, ...filters, order, orderBy })
        },
        [getCsps]
    )

    const filters = useMemo(() => {
        return [{ field: 'name', name: 'CSP Name' }]
    }, [])

    return (
        <div className={styles.pageContainer}>
            <PagedEntity
                {...csps}
                loading={loading}
                listComponent={Csps}
                ready={true}
                name="CSPs"
                onLoad={loadCsps}
                filters={filters}
                actions={[{ iconUrl: '/assets/ic-add.svg', title: 'Add CSP', onClick: () => navigate('/csps/add'), roles: ['OEM::Admin'] }]}
                location={location}
                generateReport={generateReport}
            />
        </div>
    )
}

export default CspsPage
