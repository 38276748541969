import React, { useRef, useLayoutEffect, useState } from 'react'
import styles from './Frame.module.scss'
import { useWindowSize } from '../../../hooks/window'

const Frame = ({ empty, flex, number, intensity }) => {
    const MIN_WIDTH = 60
    const [windowWidth] = useWindowSize()
    const frameElement = useRef(null)
    const [width, setWidth] = useState(MIN_WIDTH + 1)

    useLayoutEffect(() => {
        setWidth(frameElement.current.clientWidth)
    }, [flex, windowWidth])

    return (
        <div ref={frameElement} className={!empty ? styles.frame : ''} style={{ flex: flex }}>
            {!empty && width > MIN_WIDTH && (
                <React.Fragment>
                    <div className={styles.number}>{number}</div>
                    <div className={styles.intensity}>{intensity}</div>
                </React.Fragment>
            )}
        </div>
    )
}

export default Frame
