import React, { useMemo } from 'react'
import Calendar from './calendar/Calendar'
import groupBy from 'lodash.groupby'
import { Segment, Header } from 'semantic-ui-react'
import ButtonGroup from '../buttons/ButtonGroup'
import styles from './Schedule.module.scss'

const Schedule = ({ title, buttons, schedule }) => {
    const days = useMemo(() => [...Array(7).keys()], [])
    const week = useMemo(() => {
        const framesByDay = groupBy(schedule, 'dayOfTheWeek')

        for (let i = 0; i < 9; i++) {
            if (!framesByDay[i]) framesByDay[i] = []
        }

        if (framesByDay[0]) {
            //All Days
            for (let i = 1; i < 8; i++) Array.prototype.push.apply(framesByDay[i], framesByDay[0])
        }

        if (framesByDay[8]) {
            //Weekends
            Array.prototype.push.apply(framesByDay[6], framesByDay[8])
            Array.prototype.push.apply(framesByDay[7], framesByDay[8])
        }

        if (framesByDay[9]) {
            //Weekdays
            for (let i = 1; i < 6; i++) Array.prototype.push.apply(framesByDay[i], framesByDay[9])
        }

        return days.map(day => {
            return {
                day,
                frames: framesByDay[day + 1]
                    .filter(s => s.active)
                    .map(({ number, startHour, startMinute, stopHour, stopMinute, intensity, active }) => {
                        return { number, start: startHour + startMinute / 60, end: stopHour + stopMinute / 60, intensity, active }
                    }),
            }
        })
    }, [days, schedule])

    return (
        <Segment placeholder>
            <div className={styles.pageHeader}>
                <div className={styles.headerContainer}>
                    <Header as="h4" className={styles.header}>
                        {title}
                    </Header>
                    <div className={styles.frameContainer}>
                        <div className={styles.number}>Schedule #</div>
                        <div className={styles.intensity}>Intensity</div>
                    </div>
                </div>
                {buttons && (
                    <div className={styles.pageHeaderButons}>
                        <ButtonGroup buttons={buttons} horizontal />
                    </div>
                )}
            </div>
            <Calendar week={week} />
        </Segment>
    )
}

export default Schedule
