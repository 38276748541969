import React from 'react'
import PropTypes from 'prop-types'
import styles from './TextOnlyButton.module.css'

const TextOnlyButton = ({ children, text, onClick }) => {
    return (
        <div className={[styles.link, styles.block].join(' ')} onClick={onClick}>
            {text}
            {children}
        </div>
    )
}

TextOnlyButton.propTypes = {
    children: PropTypes.any,
    text: PropTypes.string,
    onClick: PropTypes.func,
}

export default TextOnlyButton
