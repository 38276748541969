const deviceCapacity = {
    AQ380: 500,
    AQ580: 500,
    AQ1280: 1250,
    AQ2500: 2500,
}

export default function(fragrances, systemConfiguration, onTime, fragrance) {
    
    const fragData = fragrances.find(f => f.fragrance === fragrance)
    console.log("fragrance data", fragData)
    const capacity = deviceCapacity[systemConfiguration]

    const onTimeHours = onTime / 3600

    if (!fragData) {
        console.log('no fragrance data to perform calculation', capacity, onTimeHours, fragData)
        return null
    }

    let output = 0
    switch (systemConfiguration) {
        case 'AQ380':
            output = fragData.aQ380 ?? -1
            break

        case 'AQ580':
            output = fragData.aQ580 ?? -1
            break

        case 'AQ880w1250':
            output = fragData.aQ880w1250 ?? -1
            break

        case 'AQ880w2500':
            output = fragData.aQ880w2500 ?? -1
            break
    
        case 'AQ1280':
            output = fragData.aQ1280w1250 ?? -1
            break

        case 'AQ2500':
            output = fragData.aQ1280w2500 ?? -1
            break

        default:
            return null
    }

    if (output <= 0) return null
    console.log('hours', onTimeHours)
    console.log("output", output)
    const density = fragData.density
    console.log("density", density)
    const usedPerHour = output / density
    console.log("used per hour", usedPerHour)
    const used = onTimeHours * usedPerHour
    console.log("used", used)
    const remaining = Math.max(0, capacity - used)
    console.log("remaining", remaining)
    //console.log('calculating fragrance remaining', systemConfiguration, onTime, fragrance, capacity, used, onTimeHours, fragData, density, output, remaining, remaining / capacity)
    console.log("capacity", capacity)

    console.log("remaining / capacity", remaining / capacity)

    return remaining / capacity
}
