export function doFramesOverlap(frame1, frame2) {
    let dayMatch = false
    if (
        frame1[0] === 0 ||
        frame2[0] === 0 ||
        frame1[0] === frame2[0] ||
        (frame1[0] === 8 && (frame2[0] === 6 || frame2[0] === 7)) ||
        (frame2[0] === 8 && (frame1[0] === 6 || frame1[0] === 7)) ||
        (frame1[0] === 9 && frame2[0] < 5) ||
        (frame2[0] === 9 && frame1[0] < 5)
    ) {
        dayMatch = true
    }

    return dayMatch && (doTimesOverlap(frame1[1], frame1[2], frame2[1]) || doTimesOverlap(frame1[1], frame1[2], frame2[2]) || doTimesOverlap(frame2[1], frame2[2], frame1[1]) || doTimesOverlap(frame2[1], frame2[2], frame1[2]))
}

export function doTimesOverlap(early, late, check) {
    return check >= early && check <= late
}
