import React from 'react'
import PropTypes from 'prop-types'
import { Dimmer, Loader } from 'semantic-ui-react'

const LoadingDimmer = ({ ready, loading, loadingText, inline }) => {
    return (
        <React.Fragment>
            {!ready && (
                <Dimmer active>
                    <Loader inline={inline}>Initializing</Loader>
                </Dimmer>
            )}

            {loading && !inline && (
                <Dimmer active>
                    <Loader>{loadingText}</Loader>
                </Dimmer>
            )}

            {loading && inline && (
                <Loader active inline="centered">
                    {loadingText}
                </Loader>
            )}
        </React.Fragment>
    )
}

LoadingDimmer.propTypes = {
    ready: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingText: PropTypes.string.isRequired,
}

export default LoadingDimmer
