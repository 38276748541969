import React from 'react';
import { TimeInput } from 'semantic-ui-calendar-react'
import { Form } from 'semantic-ui-react'

const TimeFormField = props => {
    return (
        <Form.Input as={TimeInput} {...props} closable onChange={(_, data) => props.onChange({ target: { value: data.value } })} timeFormat="AMPM"/>
    );
};

export default TimeFormField;