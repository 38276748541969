import React, { useState, useMemo, useContext, useCallback, useEffect } from 'react'
import { useAlert } from '@radbse/hooks'
import { Header } from 'semantic-ui-react'
import styles from './InviteUserPage.module.scss'
import { navigate } from '@reach/router'
import { AylaContext } from '../../stores/aylaStore'
import { SessionContext } from '@radbse/auth-identity'
import ProlitecErrors from '../../components/ProlitecErrors'
import UserForm from '../../components/users/UserForm'
import { useScenXusApi } from '../../stores/scenxusStore'
import LoadingDimmer from '../../components/LoadingDimmer'
import { useDocumentTitle } from '@radbse/hooks'

const InviteUserPage = () => {
    useDocumentTitle('ScenXus | INVITE USER')
    const session = useContext(SessionContext)
    const { actions, state } = useContext(AylaContext)
    const { ready } = state

    const [scenxusActions, useApi] = useScenXusApi()
    const [loading, getAllCsps] = useApi(scenxusActions.getAllCsps)
    const [, updateUser] = useApi(scenxusActions.updateUser)

    const [, setAlert] = useAlert()
    const [error, setError] = useState(null)
    const [csps, setCsps] = useState(null)
    const [saving, setSaving] = useState(false)

    const isOEMAdmin = useMemo(() => session.user.role === 'OEM::Admin', [session.user])

    const errorMappings = useMemo(
        () => [
            { name: 'email', label: 'Email', errorField: 'email' },
            { name: 'password', label: 'Password', errorField: 'password' },
        ],
        []
    )

    const onSubmit = useCallback(
        async ({ email, password, firstname, lastname, city, country, csp, role, state, company, businessRole, phone }) => {
            try {
                setError(null)
                setSaving(true)
                const newCsp = isOEMAdmin ? csp : session.user.csp
                const user = await actions.inviteUser(email.trim(), password, firstname, lastname, city, country, newCsp, role, state, company, businessRole, phone)
                await updateUser(user.id)
                setSaving(false)
                setAlert({ error: true, content: `Successfully invited user`, color: 'green' })
                navigate('/users')
            } catch (_error) {
                setSaving(false)
                if (_error.message) {
                    try {
                        setError(JSON.parse(_error.message))
                    }
                    catch {}
                }

                setAlert({ content: 'Please allow up to an hour to receive invitation email.', color: 'orange' })
            }
        },
        [actions, isOEMAdmin, session.user, setAlert, updateUser]
    )

    useEffect(() => {
        const load = async () => {
            const result = await getAllCsps()
            setCsps(result.response)
        }

        load()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={styles.secondaryPageContainer}>
            <Header>Invite User</Header>
            <LoadingDimmer ready={ready} loading={loading} loadingText={`Loading CSPs`} />
            <ProlitecErrors error={error} fieldMappings={errorMappings} />
            {csps && <UserForm includePassword onSubmit={onSubmit} submitButton={{ title: 'Invite User', iconUrl: '/assets/ic-add-white.svg' }} csps={csps} saving={saving} />}
        </div>
    )
}

export default InviteUserPage
