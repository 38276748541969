import React, { useEffect, useState } from 'react'
import { useForm } from '@radbse/hooks'
import PropTypes from 'prop-types'
import styles from './CspForm.module.scss'
import { Form } from 'semantic-ui-react'
import ButtonGroup from '../buttons/ButtonGroup'

const CspForm = ({ cancelButton, submitButton, onSubmit, csp }) => {
    const [saving, setSaving] = useState(false)

    const [fields, form] = useForm({
        fields: [{ name: 'name', label: 'Name' }],
        submit: fields => {
            setSaving(true)
            if (onSubmit) onSubmit(fields)
            setSaving(false)
        },
    })

    useEffect(() => {
        if(csp) {
            form.setValues(csp) 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Form className={styles.rows}>
            <div className={styles.row}>
                <Form.Group>
                    <Form.Input {...fields.name} width={4} required />
                </Form.Group>
            </div>
            <div className={styles.buttonRow}>
                <ButtonGroup horizontal buttons={[cancelButton, { ...submitButton, primary: true, onClick: form.submit, loading: saving }]} />
            </div>
        </Form>
    )
}

CspForm.propTypes = {
    cancelButton: PropTypes.object.isRequired,
    submitButton: PropTypes.object.isRequired,
    onSubmit: PropTypes.func,
    loading: PropTypes.bool,
    csp: PropTypes.object
}

export default CspForm
