import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styles from './Button.module.css'
import { Button as SemanticButton } from 'semantic-ui-react'
import { SessionContext } from '@radbse/auth-identity'

const Button = ({ iconUrl, children, onClick, primary, loading, fluid, title, roles, negative, className, disabled }) => {
    const session = useContext(SessionContext)
    let authorized = false
    if (session.user && roles) {
        if (Array.isArray(roles)) {
            roles.forEach(r => {
                if (session.user.role === r) authorized = true
            })
        } else {
            if (session.user.role === roles) authorized = true
        }
    }

    if (roles && !authorized) return null

    return (
        <SemanticButton className={`${styles.container} ${className}`} onClick={onClick} primary={primary} loading={loading} fluid={fluid} negative={negative} disabled={disabled || loading}>
            {iconUrl && <div className={styles.icon} style={{ backgroundImage: `url(${iconUrl})` }}></div>}
            {title ? <div>{title}</div> : <div>{children}</div>}
        </SemanticButton>
    )
}

Button.propTypes = {
    iconUrl: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func,
    primary: PropTypes.bool,
    loading: PropTypes.bool,
    fluid: PropTypes.bool,
    title: PropTypes.string,
}

export default Button
