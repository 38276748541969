import React from 'react'
import PropTypes from 'prop-types'
import { Message, List } from 'semantic-ui-react'

const ProlitecErrors = ({ error, fieldMappings }) => {
    return error && error.errors ? (
        <Message error>
            <List>
                {Object.keys(error.errors).map((key, i) => {
                    const formField = fieldMappings.find(field => field.errorField === key)
                    const errorsForField = error.errors[key]
                    
                    if(!formField)
                        return null;

                    return (
                        <List.Item key={i}>
                            <List.Content>
                                <List.Header as="a">{(formField ? formField.label : key)}</List.Header>
                                <List.Description>
                                    <List.List as="ul">
                                        {errorsForField.map((error, i) => {
                                            return (
                                                <List.Item key={i} as="li">
                                                    {error}
                                                </List.Item>
                                            )
                                        })}
                                    </List.List>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    )
                })}
            </List>
        </Message>
    ) : null
}

ProlitecErrors.propTypes = {
    error: PropTypes.object,
    fieldMappings: PropTypes.array,
}

export default ProlitecErrors
