import React from 'react'
import PropTypes from 'prop-types'
import styles from './CountIconButton.module.scss'
import IconButton from './IconButton'

const CountIconButton = ({ count, iconUrl, onClick }) => {
    return count > 0 ? (
        <IconButton iconUrl={iconUrl} onClick={onClick}>
            <div className={styles.count}>{count}</div>
        </IconButton>
    ) : null
}

CountIconButton.propTypes = {
    count: PropTypes.number,
    iconUrl: PropTypes.string.isRequired,
    onClick: PropTypes.func,
}

export default CountIconButton
