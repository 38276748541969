import { useMemo } from 'react'
import { modes, cycles, stateProvinces, countries } from '../utilities/deviceHelpers'
import FragranceCalculator from '../utilities/fragranceCalculator'
import { systemConfigurations } from '../utilities/deviceHelpers'
import { useScenXusApi } from '../stores/scenxusStore'
import CountryRegionData from '../utilities/country-region.json'

export function useMode(device) {
    const modeOn = useMemo(() => {
        return device && device.scenxus ? device.scenxus.mode === 3 || device.scenxus.mode === 4 : false
    }, [device])

    const mode = useMemo(() => {
        if (!device || !device.scenxus || device.scenxus.mode == null) return '---'
        return modes[device.scenxus.mode]
    }, [device])

    return [mode, modeOn]
}

export function useCycle(device) {
    return useMemo(() => {
        if (!device || !device.scenxus || !device.scenxus.cycle) return '---'
        return cycles[device.scenxus.cycle]
    }, [device])
}

export function useFragranceOptions() {
    const [, , state] = useScenXusApi()

    return useMemo(() => {
        return state.fragrances
            ? state.fragrances.map((f, i) => {
                return { key: i, text: f.fragrance, value: f.fragrance }
            })
            : null
    }, [state.fragrances])
}

export function useFragrancePercentRemaining(device) {
    const [, , state] = useScenXusApi()

    return useMemo(() => {
        return device && device.scenxus && device.scenxus.systemConfiguration && device.scenxus.fragrance
            ? FragranceCalculator(state.fragrances, device.scenxus.systemConfiguration, device.scenxus.cartridgeLife, device.scenxus.fragrance)
            : null
    }, [device, state.fragrances])
}

export function useSystemConfigurationOptions(includeEmpty) {
    return useMemo(() => {
        const items = Object.keys(systemConfigurations).map(key => {
            return { key: key, text: systemConfigurations[key], value: key }
        })
        if (includeEmpty) items.unshift({ key: -1, text: '', value: '' })
        return items
    }, [includeEmpty])
}

export function useStateProvinceOptions(countryCode, includeEmpty) {
    return useMemo(() => {
        let items = []
        if (countryCode) {
            const code = countryCode.toUpperCase()
            const country = CountryRegionData.find(c => c.countryShortCode.toUpperCase() == code)
            if (country) {
                items = country.regions.map(region => {
                    const text = (code == 'US' || code == 'CA') && region.shortCode
                        ? `${region.shortCode} - ${region.name}`
                        : region.name
                    const key = region.shortCode ?? text
                    return { key, text, value: key, }
                })
            }
        }
        if (includeEmpty) {
            items.unshift({ key: -1, text: '- none -', value: '' })
        }
        return items
    }, [countryCode, includeEmpty])
}

export function useCountryOptions(includeEmpty) {
    return useMemo(() => {
        const items = CountryRegionData.map(country => {
            return { key: country.countryShortCode, text: country.countryName, value: country.countryShortCode, }
        })
        if (includeEmpty) {
            items.unshift({ key: -1, text: '- none -', value: '', })
        }
        return items
    }, [includeEmpty])
}

export function useStateCountryNames(device) {
    return useMemo(() => {
        return getStateCountryNames(device?.scenxus?.state, device?.scenxus?.country)
    }, [device])
}

export function getStateCountryNames(stateValue, countryValue) {
    let stateName = stateValue ?? '', countryName = countryValue ?? ''
    const countryCode = countryValue?.toUpperCase() ?? ''

    if (countryCode)
    {
        const country = CountryRegionData.find(c => c.countryShortCode.toUpperCase() == countryCode)
        if (country) {
            countryName = country.countryName
            const regionCode = stateValue?.toUpperCase()
            const region = country.regions.find(r => r.shortCode?.toUpperCase() == regionCode)

            if (region) {
                stateName = (countryCode == 'US' || countryCode == 'CA')
                    ? region.shortCode?.toUpperCase()
                    : region.name
            }
        }
    }

    return { stateName, countryName }
}
