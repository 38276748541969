import React, { useCallback, useContext, useState, useEffect } from 'react'
import styles from './AddDevicePage.module.scss'
import { Header } from 'semantic-ui-react'
import { navigate } from '@reach/router'
import { AylaContext } from '../../stores/aylaStore'
import DeviceForm from '../../components/devices/DeviceForm'
import { systemConfigurations } from '../../utilities/deviceHelpers'
import { useAlert } from '@radbse/hooks'
import LoadingDimmer from '../../components/LoadingDimmer'
import { useScenXusApi } from '../../stores/scenxusStore'
import { useDocumentTitle } from '@radbse/hooks'

const AddDevicePage = props => {
    useDocumentTitle('ScenXus | ADD DEVICE')
    const { actions } = useContext(AylaContext)
    const [, setAlert] = useAlert()

    const [csps, setCsps] = useState(null)
    const [saving, setSaving] = useState(false)
    const [scenxusActions, useApi] = useScenXusApi()
    const [loading, getAllCsps] = useApi(scenxusActions.getAllCsps)
    const [, updateDevice] = useApi(scenxusActions.updateDevice)

    const onSubmit = useCallback(
        async ({ customer, csp, serviceArea, serialNumber, dsn, systemConfiguration, fragrance, latitude, longitude, timeZone, state, city, country, customerId, storeNumber }) => {
            try {
                setSaving(true)
                await actions.addDevice(dsn, csp, serialNumber, systemConfiguration, fragrance, customer, timeZone, serviceArea, longitude, latitude, city, state, country, customerId, storeNumber)
                await updateDevice(dsn)
                setSaving(false)
                setAlert({ content: 'Successfully added device', color: 'green' })
                navigate(`/devices/${dsn}`)
            } catch (error) {
                setAlert({ error: true, content: `Failed to add device. ${error.message}`, icon: 'exclamation circle' })
            }
        },
        [actions, setAlert, updateDevice]
    )

    const onLoad = useCallback(setValues => {
        setValues({
            systemConfiguration: Object.keys(systemConfigurations)[0],
        })
    }, [])

    useEffect(() => {
        const load = async () => {
            var result = await getAllCsps()
            setCsps(result.response)
        }

        load()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={styles.secondaryPageContainer}>
            <Header>Add Device</Header>
            <LoadingDimmer ready={true} loading={loading} loadingText={`Loading CSPs`} />
            {csps && (
                <DeviceForm
                    cancelButton={{ iconUrl: '/assets/ic-cancel.svg', title: 'Cancel', onClick: () => navigate('/devices') }}
                    submitButton={{ iconUrl: '/assets/ic-add-white.svg', title: 'Add Device' }}
                    onSubmit={onSubmit}
                    onLoad={onLoad}
                    csps={csps}
                    saving={saving}
                />
            )}
        </div>
    )
}

export default AddDevicePage
