import React from 'react'
import { AuthProvider, getInitialAuthState, SessionProvider, authActionCreators, apiPost, apiGet } from '@radbse/auth-identity'
import { initialAlertState, AlertProvider } from '@radbse/hooks'
import { AylaProvider } from '../stores/aylaStore'
import { ScenXusProvider } from '../stores/scenxusStore'

const Provider = ({ children }) => {
    const initialAuthState = getInitialAuthState()

    authActionCreators.setExternalToken = authResponse => ({ type: 'AUTH__LOGIN_SUCCESS', response: authResponse.result })
    authActionCreators.api.updateJwt = apiPost('/api/jwt/update', ['AUTH__LOGIN_REQUEST', 'AUTH__LOGIN_SUCCESS', 'AUTH__LOGIN_FAILURE'])
    authActionCreators.api.ping = apiGet('/api/jwt/ping', ['AUTH__PING_REQUEST', 'AUTH__PING_SUCCESS', 'AUTH__PING_FAILURE'])

    return (
        <AuthProvider initialState={initialAuthState} actionCreators={authActionCreators}>
            <SessionProvider>
                <AlertProvider initialState={initialAlertState}>
                    <ScenXusProvider>
                        <AylaProvider>{children}</AylaProvider>
                    </ScenXusProvider>
                </AlertProvider>
            </SessionProvider>
        </AuthProvider>
    )
}

export default Provider
